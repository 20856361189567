.black_header {
  width: 100%;
  height: 40px;
  background: #222;
  padding-left: 20px;
  margin-bottom: 10px;

  h4 {
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    font-weight: 600;
    font-size: 12px;
  }
}
