.UserReactions {
	display: flex;
	box-sizing: border-box;
	font-size: 14px;
	align-items: center;
	color: #919BA8;
}
.Likes {
	display: flex;
	flex: 0 0 20%;
	align-items: center;
	padding: 0 0.4rem;
	box-sizing: border-box;
	cursor: pointer;
}
.Reply {
	display: flex;
	flex: 0 0 20%;
	align-items: center;
	padding: 0 1.5rem;
	box-sizing: border-box;
	cursor: pointer;
}
.Share {
	display: flex;
	flex: 0 0 20%;
	align-items: center;
	padding: 0 0.9rem;
	box-sizing: border-box;
	cursor: pointer;
}


.Delete{
	cursor: pointer;
}
.Time {
	display: flex;
	flex: 0 0 25%;
	align-items: center;
}
.More {
	display: flex;
	flex: 1;
	align-items: center;
}

/* @media (min-width: 550px) {
	.UserReactions {
		font-size: 0.7rem;
	}
	.Likes {
		flex: 0 0 10%;
	}
	.Time {
		flex: 0 0 15%;
	}
	.Share {
		flex: 0 0 12%;
	}
} */
