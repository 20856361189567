.event-detail {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 20px;
  height: 350px;
  border: 10px;

  & > * {
    margin-left: 20px;
  }

  &__status {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 10px;
  }
  &__title {
    font-weight: 400;
    text-transform: capitalize;

    color: #fff;
    display: inline-block;
    font-size: 25px;
    border-radius: 10px;
    width: 60%;
    margin-bottom: 0px;
  }
  &__discription {
    width: 50%;
    color: #fff;
    margin-bottom: 15px;
  }
  &__metaData {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin-bottom: 20px;
  }

  &__btn--featured {
    color: #fff;
    border: 1px solid #fff;
    background: none;
  }
}
.event-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &__category {
    margin-right: auto;
    font-size: 20px;
    font-weight: bold;
  }
  &__more {
    color: #d42316;
    font-size: 15px;
  }
}
.event-short-desc {
  font-size: 15px;
  font-weight: bold;
}
.alumni-event {
  &__card-body {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  &__title {
    font-size: 15px;
  }
  &__short-desc {
    font-size: 13px;
  }

  &__img {
    height: 150px;
  }

  &--link {
    color: #d42316;
  }

  &__posted {
    font-size: 14px;
  }
}

.event__icon,
.alumni-event__icon {
  margin-right: 10px;
}

.alumni__event-only {
  display: inline-block;
  background-color: #d42316;
  color: white;
  border-top-right-radius: 25px;
  border-radius: 0px 20px 20px 0px;
  padding: 5px 10px;
  font-size: 15px;
}
.add-to-calender {
  font-weight: bold;
  font-size: 12px;
}

.event-detail__metaData {
  display: flex;
  width: 90%;
  justify-content: space-between;
  font-size: 14px;
}

.event-detail--card {
  border: none !important;
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.event-rsvp {
  margin-bottom: 30px;
}
.event-cta {
  font-size: 14px;
  margin-bottom: 10px;
}
.event-detail--visa {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
}

.user__likes {
  display: flex;
  justify-content: space-between;
}

.user__list {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.user__list:not(:last-child) {
  margin-right: -15px;
  border: 1px solid white;
}

.btn-remainder {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.btn-remainder:first-child {
  margin-right: 10px;
}

.add-to-calender {
  font-weight: bold;
}
.event-detail-tag-title {
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 15px;
}
.event__main {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
}
.event-remainder {
  margin-bottom: 20px;
}

.remainder-date {
  font-size: 13px !important;
}
.list-calender-agt {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0 0 30px;
}
.calender-item {
  text-decoration: none;
  display: block;
  color: #e42d2d;
  font-size: 18px;
  text-align: center;
  padding: 6px;
}
.event__contact,
.event__comment,
.comment__section {
  background-color: white;
  padding: 10px;
}
.event__contact {
  margin-bottom: 30px;
}
.event__comment {
  padding: 30px;
}
.event__comment--add-comment {
  font-size: 15px;
  font-weight: bold;
}

.post__comment--btn {
  float: right;
  margin-top: 20px;
}
.post__comment:after {
  content: "";
  clear: both;
  display: table;
}
.event__input {
  background-color: #f0f0f0;
}

.rate__comment {
  display: flex;
  align-items: center;
}

.star__rate {
  font-size: 25px;
}
.rate__title {
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}
.event-tag {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.event-tag--item {
  display: inline-block;
  background-color: #ced4da;
  padding: 8px 10px;
  font-size: 11px;

  border-radius: 7px;
}
.event__detail--message {
  font-size: 14px;
  font-weight: bold;
}
.event-tag--item:not(:last-child) {
  margin-right: 2px;
}
.event-alumni-breadcrumb {
  position: absolute;
  top: 5%;
}
.event--price {
  font-size: 13px;
}

.user__likes {
  font-size: 11px !important;
  align-items: center;
}
.user__like-count {
  font-size: 11px !important;
}

.date-commented {
  margin-right: auto;
  font-size: 14px;
  color: #919ba8;
}
.only-full {
  display: inline-block;
}
.event-rsvp-mobile {
  display: none;
}
.event-cta:hover {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
}
.declined-visa,
.approved-visa {
  color: #fff;
}
.declined-visa {
  background-color: #bec4cb;
  color:black
}
.declined-visa:hover {
  background-color: #bec4cb;
  color:black
}
.approved-visa {
  background-color: 	#1DB954
}
.approved-visa:hover {
  background-color: 	#1DB954;
  color: #fff;
}

@media screen and (max-width: 790px) {
  .alumni-event-imgbox__title,
  .event-detail__title {
    width: 90%;
  }
  .alumni-event-imgbox__discription {
    width: 90%;
  }
  .alumni-event-box-icon-box {
    width: 90%;
  }
  .alumni-event-box-icon-box__meta {
    margin-right: auto;
  }

  .card-text {
    align-self: start;
  }
  .event--price {
    justify-content: flex-start;
  }
  .event-detail {
    height: 450px;
    border-radius: 10px;
  }
  .only-full {
    display: none;
  }
  .event-detail-para {
    margin-bottom: 20px;
  }
  .event-rsvp-full {
    display: none;
  }
  .event-rsvp-mobile {
    display: flex;
  }
  .user__likes {
    display: flex;
    justify-content: flex-start;
  }
  .user-list {
    margin-right: 20px;
  }
  .user__like-count {
    margin-right: auto;
    font-size: 15px !important;
    font-weight: 600;
  }
  .event-cta {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 15px;
  }
  .btn-remainder {
    width: 100%;
  }
  .add-calender-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-to-calender {
    font-weight: bold;
    font-size: 19px;
  }
  .remainder-date {
    font-size: 14px !important;
    font-weight: 600;
  }
  .rate__title {
    margin-right: auto;
  }
  .post__comment--btn {
    /* float: right; */
    margin-top: 20px;
    width: 100%;
  }
  .event-detail-bx {
    margin-left: 10px;
    justify-content: flex-start;
  }
  .event-detail-info {
    font-size: 18px;
  }
}
