.Bookmarked {
    color: var(--tef-red);
}

.ArticlePoster {
    display: flex;
    align-items: center;
}

.ArticleBanner {
    flex: 0 0 30%;
}

.TruncateText {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}