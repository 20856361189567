.Header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  height: 6rem;
  box-shadow: inset 0 -1px 0 0 var(--pale-lilac);
  background-color: var(--white);
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  display: none;
  align-items: center;
  justify-content: space-between;

  &__menuIcon {
    flex: 0.1;
  }

  &__logo {
    flex: 0.7;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .Header {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;

    &__menuIcon {
      display: block;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 425px) {
  .Header {
    padding-right: 5px;
    padding-left: 5px;
  }
}
