.hub-user__card {
  background: #F4F5F6 !important;
  min-height: 250px;
  border: 1px solid rgb(219, 219, 219);
  word-break: break-all;

  .hub-user__image {
    img {
      height: 150px;
      width: 100%;
      object-fit: cover;
    }
    .user-image__alt-wrapper {
      height: 150px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .user-image__alt-details {
        height: 50px;
        width: 50px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
      }
    }
  }

  .hub-user__details {
    text-align: center;
    padding: 20px 10px;

    .user-name {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 3px;
    }

    .user-position {
      color: #5d5d5d;
      font-size: 12px;
      font-weight: 400;
    }

    .user-socials {
      display: flex;
      justify-content: center;
      border-top: 1px solid rgb(219, 219, 219);
      padding-top: 5px;
      margin-top: 5px;

      .social-icon__box {
        height: 30px;
        width: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      .social-icon__box-linkedin {
        background: #1177B5;
        color: #fff;
      }

      .social-icon__box-twitter {
        background: #1DA1F2;
        color: #fff;
      }

      .social-icon__box-chat {
        background: #919BA8;
        color: #fff;
      }
    }
  }
}