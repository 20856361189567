.NewsBanner:hover {
    cursor: pointer;
    filter: grayscale(0.7);
    transform: scale(1.03);
    transition-property: filter transform;
    transition-duration: 0.03s;
    transition-timing-function: ease-in;
}

.Bookmarked {
    color: #da291c;
}