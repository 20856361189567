.q_and_a_dot_options{
  position: absolute;
  right: 5px;
  top:5px;
  z-index: 10;
}

.postion_relative{
  position: relative;
}

.q_and_a_dot_options-moreOptions{
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 150px;
  position: absolute;
  right: 0;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  z-index: 1;
  padding: 5px 0;
  border-radius: 4px;
  border: solid 0.5px #c7c7c7;
  justify-content: space-around;
  & button{
    background:transparent;
    margin: 2px 0;
    border: none;
    padding: 5px 10px;
    transition: all .2;
    display: flex;
    align-items: center;
    // justify-content: space-around;
    color: var(--helper-text-color);

    &:hover{
      background-color: #eef3ff;
    }
   
  }
}

.option-i-con{
  margin-right: 5px;
}
.placeholderField_c{
  // display: flex;
  background-color: white;
  // align-items: center;
  cursor: pointer;
  // flex-wrap: wrap;
  width: inherit;
  margin: inherit;
  padding: 20px 0;
  &__f{
    border: 1px solid var(--new-faded-white);
    width: 100%;
    border-radius: 5px;
    color: var(--helper-text-color); 
    padding: 5px;
    flex: 2;
  }
  & button{
    background: none;
    border-radius: 25px;
    // flex: 1;
    border: none;
    background-color: var(--tef-red);
    height: inherit;
    color: white;
    padding: 5px;
    margin:0 5px;
    // font-size: 15px;
  }
  & input[type=text]{
    width: 100%;
    outline: none;
  }
}
.input-q-and-a{
  border: none;
  border-bottom: 2px solid rgb(253, 243, 243);
  outline: none !important;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 5px;
  transition: all 1s ease-in-out;

  &:focus{
    outline: 0px !important;
    border-bottom: 2px solid var(--pre-green);
  }
  &:hover{
    border-bottom: 2px solid var(--pre-green);
  }
  &::placeholder{
    font-size: 18px;
    font-weight: bolder;
  }
}

.input-q-and-a-tips{
  padding: 10px;
  background-color: rgb(253, 243, 243);
  color: var(--tef-red);
  border-radius: 10px;
  font-weight: bold;
  margin: 15px 0;
  & ul > li{
    list-style-type: none;
  }
}
.input-q-and-a-tips-attch{
  color: var(--helper-text-color);
  cursor: pointer;
}

.q_and_a_search_c{
  &__input{
    border: none;
    border-bottom: 2px solid rgb(253, 243, 243);
    outline: none !important;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 5px;
    transition: all 1s ease-in-out;

    &:focus{
      outline: 0px !important;
      border-bottom: 2px solid var(--pre-green);
    }
    &:hover{
      border-bottom: 2px solid var(--pre-green);
    }
    &::placeholder{
      font-size: 18px;
      font-weight: bolder;
    }
}
}