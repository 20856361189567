.HeaderNav {
  // flex: 0 0 30%;
  align-self: stretch;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  font-family: Poppins;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: var(--h1-black-new);
  text-transform: capitalize;
  margin-left: 5%;
  padding-left: 50px;

  &__links {
    height: 100%;
    cursor: pointer;
    padding: 0 10px;
    display: flex;
    align-items: center;
    // color: var(--h-1-black-new);
    color: var(--tef-red);
    font-weight: bold;

    & span {
      text-transform: uppercase;
    }
  }

  &__linksInActive {
    &:hover {
      transition: all 0.5s ease-in;
      background-color: #eee;
      color: var(--tef-red);
    }
  }

  &__linksActive {
    background-color: #eee;
    color: var(--tef-red);
  }
}

@media screen and (max-width: "768px") {
  .HeaderNav {
    // display: none;
    padding-left: 0;

    margin-left: 0;

    &__links {
      // padding: 0 3.5px;
    }
  }
}

@media screen and (max-width: "400px") {
  .HeaderNav {
    &__links {
      padding: 0 3.5px;
    }
  }
}
