.ToolPoster {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Posted {
    margin-right: 0.5rem;
    color: #919ba8;
    display: flex;
    align-items: center;
    /* flex: 0 0 10%; */
}

.PosterImage {
    margin-right: 0.25rem;
    cursor: pointer;
    /* flex: 0 0 5%; */
}

.PosterName {
    margin-right: 0.75rem;
    color: #111111;
    display: flex;
    cursor: pointer;
    /* flex: 0 0 60%; */
}

.Duration {
    flex: 1;
}