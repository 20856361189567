.Sidebar {
  background-color: #2e3d49;
  width: 17%;
  padding-top: 24px;
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: 6rem;
  left: 0;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__back {
    padding: 20px 0;
    display: flex;
    border-top: 1px solid white;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: white;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    font-family: Poppins;
    text-decoration: none;

    &:hover {
      color: white;
      font-weight: 900;
    }
  }
}

.HideSidebar {
  display: none;
}

@media screen and (max-width: 768px) {
  .Sidebar {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .Sidebar {
    width: 20%;
  }
}
