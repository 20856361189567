.TagContainer {
    border-radius: 15px;
    border: solid 1px var(--helper-text-color);
    margin-right: 0.3rem;
    padding: 0.1rem 0.2rem;
}

.TagTitle {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
}