.SideBar {
  // flex: 0 0 15%;
  width: 15%;
  background-color: var(--white);
  border-right: 1px solid var(--pale-lilac);
  padding-top: 24px;
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: 6rem;
  left: 0;
  padding-bottom: 6rem;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.SideBarHide {
  display: none;
}

@media screen and (max-width: 768px) {
  .SideBar {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .SideBar {
    // flex: 0 0 20%;
    width: 20%;

    &__navTitle {
      padding-left: 12px;
    }
  }
}
