.HeaderNav {
  // flex: 0 0 30%;
  align-self: stretch;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: var(--h-1-black-new);
  text-transform: capitalize;

  &__links {
    height: 100%;
    cursor: pointer;
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: var(--h-1-black-new);

    & span {
      text-transform: uppercase;
    }
  }

  &__linksInActive {
    &:hover {
      transition: all 0.5s ease-in;
      background-color: #eee;
      color: var(--tef-red);
    }
  }

  &__linksActive {
    background-color: #eee;
    color: var(--tef-red);
  }
}

@media screen and (max-width: "768px") {
  .HeaderNav {
    display: none;
  }
}
