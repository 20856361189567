.img-2 {
  margin: 53px 0 49px 0;
}

.reset-password-div {
  display: flex;
  flex-direction: column;

  max-width: 594px;
  min-height: 367px;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(51, 65, 80, 0.03),
    0 2px 2px 0 rgba(51, 65, 80, 0.03);
  border: solid 1.2px var(--new-faded-white);
  background-color: var(--white);

  &__heading {
    margin: 23px 0 13.6px 0;
    display: flex;
    justify-content: center;
    height: 29px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: #334150;
  }

  &__sub-heading {
    margin: 0px 140px 28.4px 140px;
    text-align: center;
    height: 52.8px;
    font-size: 16px;
    line-height: 1.65;
    letter-spacing: normal;
    color: var(--tef-grey);
  }

  &__form {
    text-align: left;
    margin: 0 60px 51px 60px;
    display: flex;
    flex-direction: column;
  }

  &__message {
    max-width: 395px;
    height: 170px;
    margin: 0 50px 37px 50px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.reset-password-button {
  margin-top: 10px;
  height: 41px;
  border-radius: 6px;
  background-color: var(--tef-red);

  font-family: Lato;
  font-size: 16.8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
  border: 0;
  transition: all 0.2s;

  &:active {
    transform: translateY(2px);
  }

  &:focus {
    outline: none;
  }
}

.message-1 {
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: 0.22px;
  color: var(--blue-grey);
  text-align: center;

  &__email {
    color: var(--clear-blue);
  }
}

.message-2 {
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: 0.22px;
  color: var(--blue-grey);
  text-align: center;
}

.message-button {
  height: 36px;
  border-radius: 5px;
  background-color: var(--tef-red);
  font-family: Lato;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .img-2 {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .reset-password-div {
    &__sub-heading {
      margin: 0px 14px 28.4px 14px;
    }

    &__form {
      text-align: left;
      margin: 0;
      padding: 0 10px;
    }
  }
}
