.SubmitDocument {    
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 1em;
    position: absolute;
    bottom: 0px;
}

.NoDocumentsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5em;
    flex-direction: column;
}
  
.NoDocumentsContainer h6{
    line-height: 2em;
}
  
.NoDocuments {
    max-width: 100%;
    height: auto;
}