.request-conn-card{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 20px;
  background-color: #fff;
  flex-wrap: wrap;
  @media only screen and (max-width:350px){
      flex-direction: column;
  }
  &__left{
    flex:50%;
    display: flex;
    align-items: center;
    &--name{
      margin-left: 12px;
      & span{
        color: var(--helper-text-color);
        font-size: 12px;
      }
      & * h4{
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.16px;
        color: #171725;
      }
    }

  
  }
  &__right{
    // flex: 50%;
  }
}
.req-conn-container{
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 150px;

}