.Response{
  display: flex;
  align-items: flex-end;
  margin-left: 2rem;
}
.ResponseBody{
  font-size: 0.7rem;
  margin-bottom: 0;
}
.Avatar{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
}
.OtherDetails{
  display: flex;
  flex-direction: column;
}
.CommentPoster{
  font-weight: 600;
  font-size: 0.6rem;
}