.Content {
    padding: 1em 2em;
}

.DashedContainer {
    border: 2px solid #00000033;
    border-style: dashed;
    width: 100%;
    height: 12em;
    margin-top: 1em;
    margin-bottom: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Central {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5em;
    margin-top: 1em;
    font-size: 0.7rem;
    color: #BDC3CC;
}

.DragAndDrop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    line-height: 2.5rem;
}

.Text {
    color: #BDC3CC;
    font-weight: 100;
}

.Browse {
    color: #DA291C;
    text-decoration: underline;
    cursor: pointer;
}