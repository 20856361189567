.coursemateContainerF{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 75vh;
}

.heading{
  color: var(--helper-text-color);
  font-size: 18px;
  font-weight: 600;
 
}

.coursemateContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: inherit;
  height: 75vh;
  overflow-y: auto;
  // position: relative;
  padding-top: 20px;
 
}

.search{
  outline: none;
  &:focus{
    outline: none !important;
  }
}

.coursemateItemC{
  display: flex;
  border-bottom: 1px solid var(--new-faded-white);
  padding: 10px 5px;
  margin: 2px 0;
  align-items: center;
  border-radius: 5px;
}
.avatar{
  float: left;
  margin-right: 5px;
}

.name{
  float: left;
  margin-top: 2px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  & h5{
    font-weight: 600;
    font-size: 15px;
  }
  .desc{
    margin-top: -7px;
    font-size: 13px;
    background: var(--new-faded-white);
    padding: 2px 5px;
    border-radius: 5px;
    cursor: pointer;
    width: max-content;
    display: flex;
  }
}
.clear{
  clear: both;
}

.coursemateItemC:hover{
  background:rgba(0,0,0,0.9);
  color: white;
  .desc{
    color: black;
  }

}