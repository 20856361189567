.SidebarItem {
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  font-family: Poppins;
  text-decoration: none;
  color: var(--helper-text-color);
  padding: 20px 5px;
  text-transform: capitalize;

  &:hover {
    color: white;
    font-weight: 900;
  }

  &__title {
    margin-left: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  &__count {
    font-family: Poppins;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left: auto;
    margin-right: 7px;
  }

  &__subMenu {
    display: flex;
    flex-direction: column;
    color: var(--helper-text-color);
    list-style: none;
    text-transform: capitalize;
    padding: 10px 0 10px 30px;

    &:hover {
      color: rgb(252, 244, 244);
      font-weight: bolder;
      background-color: #5b778f;
    }
  }

  &__name {
    opacity: 0.8;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.18px;
  }

  &__duration {
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.15px;
  }
}

.SidebarItemActive {
  color: white;
  font-weight: bolder;
}

.SidebarItemSubMenuActive {
  color: rgb(252, 244, 244);
  font-weight: bolder;
  background-color: #5b778f;
}
