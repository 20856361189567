.Label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.Value {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  word-break: break-word;
}

.Description {
  margin: 1.5rem 0;
}
