.section_header {
  color: var(--tef-red);
  text-align: left;
  font: normal normal bold 20px/35px Poppins;
  letter-spacing: 0px;
  margin-bottom: 15px;
}
.course_summary_card {
  padding: 2%;

  .content_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba($color: #fe0000, $alpha: 0.06);
    border-radius: 15px;
    padding: 5.3%;
    min-height: 150px;
    width: 100%;
  }

  .score {
    text-align: center;
    font: normal normal bold 30px/25px Poppins;
    letter-spacing: 0px;
    color: #fe0000;
  }
  .title {
    text-align: center;
    font: normal normal normal 10px/18px Poppins;
    letter-spacing: 0px;
    color: #53565a;
    word-wrap: break-word;
  }
}

.summaryTable {
  border-collapse: collapse;
  width: 100%;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  overflow: hidden;

  & .theader {
    background-color: #152337;
    border-radius: 15px;
    color: #fff;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
  }
  & td,
  th {
    text-align: left;
    padding: 27px 15px;
  }

  & td {
    background: white;
    border-bottom: 1px solid #ddd;
  }

  & tr {
    min-height: 75px;
  }
}

.tcontainer {
  overflow-x: auto;
  box-shadow: 0px 2px 3px #00000029;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.started {
  color: var(--pre-green);
}

.inProgress {
  color: var(--tef-red);
}

.notStarted {
  color: #53565a;
}
