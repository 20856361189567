.video-list-item {
  border-radius: 3.6px;
  box-shadow: 0 2px 4px 0 rgba(145, 155, 168, 0.06);
  background-color: var(--white);
  // margin: 15px 3.5px;
  // width: 324px;
  // max-width: 324px !important;
  // flex:1;
  // display: flex;
  // flex-direction: column;
  min-height: 246px;
  // justify-content: space-between;
  cursor: pointer;

  &__video {
    height: 180px;
    width: 100%;

    & video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  h1 {
    line-height: 1.57;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
    // padding: 4px 0px;
  }

  &__video-views {
    color: var(--helper-text-color);
    font-size: 12px;
    font-weight: 500;
  }
}

.show-more {
  background-color: var(--new-faded-white);
  color: var(--helper-text-color);
  font-weight: bold;
  padding: 0.6rem 0rem;
  border-radius: 3px;
  font-size: 0.8rem;
  cursor: pointer;
  margin: 0 auto;
}
