.success-create-opp {
  background-color: #fff;
  // height: 100vh;
  &__content {
    margin: 30px auto;
    width: 50%;
    padding: 50px;
    &--image-container {
      margin: 0 auto;
      height: 169px;
      width: 171px;
      // padding: 0px 35%;
    }
    &--message {
      margin: 25px auto;
      text-align: center;

      & h1 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 600;
        color: var(--h1-black-new);
      }
      & p {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: 0.2px;
        text-align: center;
        color: var(--tef-grey);
      }
    }
  }
}

.twt-share-c {
  display: flex;
  justify-content: flex-end;
}

.lkIn-share-c {
  display: flex;
  justify-content: flex-start;
}

.create-opp-success-view-opp {
  font-size: 14px;
  letter-spacing: 0.2px;
  text-align: center;
  color: var(--tef-grey);
  margin-top: 30px;
}

@media only screen and (max-width:600px) {
  .success-create-opp {
    &__content {
      width: 75%;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

@media only screen and (max-width:767px) {
  .twt-share-c {
    justify-content: center;
    align-items: center;
    margin: 2px 0px;
  }

  .lkIn-share-c {
    margin: 2px 0px;
    justify-content: center;
    align-items: center;
  }
}
