.InvitedUserRequest {
  padding: 5px;

  &__btn {
    display: flex;
    flex-direction: row-reverse;
    padding: 7px;
    margin-bottom: 10px;
  }

  &__acceptAllBtn {
    border-radius: 4px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    text-transform: capitalize;
    transition: all 0.2s;
    border: 0;
    padding: 10px;
    color: white;
  }

  &__section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
