.aboutMini {
  margin-top: 5%;
  &__bio {
    margin: 0 20px;
    & p {
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.2px;
      color: var(--tef-grey);
    }
  }
  &__pic {
    margin: 5% 0;
  }
}
.aboutMini > h1 {
  height: 19px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  color: var(--h-1-black-new);
  margin: 15px 20px 0 20px;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
}

.p {
  font-size: 13px;
  letter-spacing: 0.23px;
  // color: var(--h1-black-new);
  color: var(--tef-grey);
  font-weight: 600;
}
