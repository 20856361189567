.alumni-list__wrapper {
  height: 100%;
  padding-left: 0;
  padding: 15px;

  .section-title {
    font-weight: 600;
    font-size: 22px;
  }
}

.alumni-member__wrapper {
  display: flex;
  margin-bottom: 20px;

  .alumni-image {
    margin-right: 20px;

    img {
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }
  }

  .alumni-details {
    flex-grow: 1;

    .alumni-name {
      font-size: 16px;
      margin-bottom: 1px;
    }

    .alumni-email {
      font-size: 14px;
      color: #a5a7ac;
    }
  }
}