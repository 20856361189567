.messageList {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 13px;
  }

  &__h4 {
    text-transform: capitalize;
  }

  &__search {
    height: 40px;
    padding: 11px 13px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    transition: all 0.2s;
    outline: none;
    border: 0;
    border-top: 1px solid var(--pale-lilac);
    border-bottom: 1px solid var(--pale-lilac);

    &::placeholder {
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.11px;
      color: var(--helper-text-color);
      text-transform: capitalize;
    }
  }

  &__list {
    overflow-x: hidden;
    overflow-y: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__noChat {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    font-size: 13px;
    font-family: Poppins;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.2px;
    color: var(--helper-text-color);
    text-align: center;
  }
}
