.Mentors-container {
  // width: 100%;
  // text-align: center;
  // margin-left: -15px;
  // margin-right: 21px;
}

.Mentors-show-more {
  width: 163px;
  height: 41px;
  border-radius: 6px;
  background: var(--very-light-blue);
  border: none;
  margin-left: 35%;
  margin-top: 35px;
  margin-bottom: 35px;
  color: black;
}

.connection-list-c {
  display: flex;
  flex-wrap: wrap;
  // justify-content:center;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  // padding: 0;
  // align-items: center;
  @media only screen and (max-width:560px) {
    justify-content: center;
  }
}

.m-filter-option-c {
  background-color: red;
}

.find-a-mentor-heading {
  font-size: 18px;
  font-weight: bold;
  color: #354052;
}
.search-mentorship-input {
  border: hidden;
  outline: none !important;
  &:focus {
    outline: none !important;
  }
}
