.NoDocumentsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5em;
  flex-direction: column;  
}

.NoDocumentsContainer h6{
  line-height: 2em;
}

.NoDocuments {
  max-width: 100%;
  height: auto;
}

.DisableOverlay {
  background-color:#EFEFEF;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0px;
  left: 0px;
  opacity: .5; /* in FireFox */ 
  filter: alpha(opacity=50); /* in IE */
}