.carouselContainer,
.communityContainer,
.eventContainer {
  margin: 0 6%;
}

.carouselContainer {
  // background-color: pink;
  // height: 1000px; //increasee thise later
  background-image: url("../../assets/img/bg/bgmap2.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50% 100%;
  // height: 696px;

  .slides {
    width: 100%;
    // background-color: bisque;
    height: 696px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: relative;

    @media only screen and (max-width: 958px) {
      flex-direction: column-reverse;
    }

    &__left {
      flex: 1;

      & h4 {
        text-align: left;
        font: normal normal 900 60px/75px Poppins;
        letter-spacing: 0px;
        color: #000000;
        text-transform: uppercase;
        word-wrap: break-word;
        @media only screen and (max-width: 768px) {
          margin-top: 10px;
          font-size: 30px;
          line-height: 45px;
        }
      }
      & p {
        text-align: left;
        font: normal normal medium 18px/30px Poppins;
        letter-spacing: 0px;
        color: #6e6e70;
        opacity: 1;
      }
      & a {
        text-align: left;
        font: normal normal 900 16px/26px Avenir;
        letter-spacing: 0px;
        color: var(--tef-red);
        text-transform: uppercase;
        opacity: 1;
      }
    }
    &__right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      // background-size: cover;
      // & img {
      //   object-fit: cover;
      //   object-position: center;
      //   height: 100%;
      // }

      // @media only screen and (max-width: 958px) {
      //   // background-size: 100%;
      //   display: none;
      // }

      // @media only screen and (max-width: 768px) {
      //   // background-size: 100%;
      //   display: flex;
      // }
    }
  }

  @media only screen and (max-width: 768px) {
    background-size: 100%;
  }
}
.communityContainer {
  // height: 696px; //increasee thise later
  background-image: url("../../assets/img/bg/bgmap2.webp");
  background-repeat: no-repeat;
  background-position: top 300px center;
  background-size: 50% 50%;

  @media only screen and (max-width: 768px) {
    background-size: 100%;
  }
}

.resourceContainer {
  min-height: 696px;
  display: flex;
  // flex-wrap: wrap;
  justify-content: space-between;
  &__left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    & h4 {
      text-align: left;
      font: normal normal 600 18px/10px Poppins;
      letter-spacing: 0px;
      color: var(--tef-red);
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    & h3 {
      text-align: left;
      font: normal normal 900 36px/40px Poppins;
      letter-spacing: 0px;
      color: #000000;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
    & p {
      text-align: left;
      font: normal normal medium 15px/25px Poppins;
      letter-spacing: 0px;
      color: #6e6e70;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
  &__right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.tradeContainer {
  height: 696px;
  @media only screen and (max-width: 768px) {
    // width: 100vw;
  }
  &__inner {
    height: 439px;
    background-image: url("../../assets/img/landingPage/landingPagem4.webp");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 100%;
    border-radius: 0 35px 0 35px;
    position: relative;
    background-blend-mode: color-dodge;
    width: 100%;

    &_detailContainer {
      position: absolute;
      display: flex;
      height: 362px;
      background-color: transparent;
      left: 0;
      right: 0;
      bottom: -215px;
      padding: 0 5%;
      color: white;
      display: flex;
      justify-content: space-between;
      // flex-wrap: wrap;
      // background-color: red;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        bottom: -100px;
        // width: 100vw;
      }

      &__left {
        flex: 1;

        & h4 {
          text-align: left;
          font: normal normal 900 36px/30px Poppins;
          letter-spacing: 0px;
          color: #ffffff;
          @media only screen and (max-width: 768px) {
            font-size: 18px;
          }
        }
        & h5 {
          text-align: left;
          font: normal normal 600 18px/20px Poppins;
          letter-spacing: 0px;
          color: var(--tef-red);
          @media only screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
      &__right {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 25px #0000001a;
        border-radius: 0px 35px;
        flex: 1;
        display: flex;
        padding: 25px 3%;
        color: #000;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        height: 63%;
        // margin-bottom: -110px;
        @media only screen and (max-width: 768px) {
          height: 30px !important;
        }

        & h5 {
          text-align: left;
          font: normal normal 900 25px/36px Poppins;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          @media only screen and (max-width: 768px) {
            font-size: 18px;
          }
        }
        & p {
          text-align: left;
          font: normal normal medium 15px/25px Poppins;
          letter-spacing: 0px;
          color: #6e6e70;
          @media only screen and (max-width: 768px) {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.eventContainer {
  height: 696px; //increasee thise later
  background-image: url("../../assets/img/bg/bgmap3.webp");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  &__left {
    min-height: 386px;
    // width: 705px;
    position: relative;
    flex: 1;
    width: 100%;

    background-image: url("../../assets/img/landingPage/landingPagem5.webp");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    border-radius: 10px;
    &_info_container {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 7px 40px #00000029;
      border-radius: 10px;
      padding: 3% 3%;
      position: absolute;
      bottom: -40px;
      // width: 317px;
      left: 9%;

      @media only screen and (max-width: 768px) {
        left: 4.5%;
        right: 4.5%;
      }

      & h4 {
        text-align: left;
        font: normal normal 600 20px/24px Poppins;
        letter-spacing: 0px;
        color: #000000;
      }
      & span {
        text-align: left;
        font: normal normal medium 17px/20px Poppins;
        letter-spacing: 0px;
        color: #6e6e70;
        opacity: 1;
      }
    }
    @media only screen and (max-width: 768px) {
      // font-size: 16px;
      width: 100vw;
    }
  }
  &__right {
    flex: 1;
    padding: 0 5%;

    & h4 {
      text-align: left;
      font: normal normal 600 18px/10px Poppins;
      letter-spacing: 0px;
      color: var(--tef-red);
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    & h3 {
      text-align: left;
      font: normal normal 900 36px/40px Poppins;
      letter-spacing: 0px;
      color: #000000;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
    & p {
      text-align: left;
      font: normal normal medium 15px/25px Poppins;
      letter-spacing: 0px;
      color: #6e6e70;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
    & span {
      text-align: left;
      font: normal normal medium 15px/30px Poppins;
      letter-spacing: 0px;
      color: #000000;

      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 768px) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    background-size: cover;
  }
}

.giveBackContainer {
  height: 452px; //increasee thise later
  background-image: url("../../assets/img/landingPage/landingPagem2.webp");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0 8%;
  padding-left: 8%;
  padding-right: 8%;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    // justify-content: space-evenly;
    padding-top: 20%;
  }
  &__left {
    flex: 1;
    // @media only screen and (max-width: 768px) {
    //   display: flex;
    //   justify-content: center;
    //   // align-items: center;
    //   flex-direction: column;
    // }
    & h4 {
      text-align: left;
      font: normal normal 600 18px/10px Poppins;
      letter-spacing: 0px;
      color: var(--tef-red);
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    & h3 {
      text-align: left;
      font: normal normal 900 36px/40px Poppins;
      letter-spacing: 0px;
      color: #fff;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
  &__right {
    flex: 1;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;

    .l_btn {
      background: var(--tef-red) !important;
    }
    @media only screen and (max-width: 768px) {
      & a {
        width: 100%;
      }
      .l_btn {
        width: 100%;
        // flex: 1;
      }
    }
  }
}

.btn_apply_now {
  background: var(--tef-red) !important;
  border-color: var(--tef-red);
}

.countDownContainer {
  // height: 452px; //increasee thise later
  background-image: url("../../assets/img/landingPage/counter.webp");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  display: flex;
  // justify-content: space-around;
  align-items: center;
  // flex-wrap: wrap;
  // padding-left: 8%;
  // padding-right: 8%;
  padding: 87px 8%;

  & h2 {
    // text-align: center;
    font: normal normal 900 55px/100px Poppins;
    letter-spacing: 0px;
    color: #080808;
  }

  & span {
    // text-align: center;
    font: normal normal medium 25px/30px Poppins;
    letter-spacing: 0px;
    color: #6e6e70;
  }

  & img {
    height: 80px;
    align-self: center;
  }

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
}
.partnerContainer {
  background-color: #ececee;
  display: flex;
  align-items: center;
  padding: 0 6%;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  &__left {
    background-image: url("../../assets/img/landingPage//landingPagem6.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80% 80%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 798px;
    & h4 {
      text-align: left;
      font: normal normal 600 18px/10px Poppins;
      letter-spacing: 0px;
      color: white;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    & h3 {
      text-align: left;
      font: normal normal 900 36px/40px Poppins;
      letter-spacing: 0px;
      color: #000000;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
    & p {
      text-align: left;
      font: normal normal medium 15px/25px Poppins;
      letter-spacing: 0px;
      color: #ffffff;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 768px) {
      background-size: 100%;
    }

    @media only screen and (max-width: 548px) {
      background-size: 100% 80%;
    }
  }
  &__right {
    .note {
      font-family: Poppins;
      font-weight: 400;
      .apply_now {
        font-weight: bold;
        color: var(--tef-red);
        font-size: 15px;
      }
    }
    flex: 1;
    width: 100%;

    @media only screen and (max-width: 768px) {
      margin-bottom: 50px;
    }
  }
}
.newsLetterContainer {
  background: #f2f4f7;
  // height: 300px;
  padding: 60px 6%;
  // display: flex
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  & .t {
    & h4 {
      text-align: left;
      font: normal normal 900 36px/54px Poppins;
      letter-spacing: 0px;
      color: #000000;
    }
    & span {
      text-align: left;
      font: normal normal medium 15px/25px Poppins;
      letter-spacing: 0px;
      color: #6e6e70;
      opacity: 1;
    }
  }
  & form {
    // display: flex;
    // justify-content: space-center;
    // align-items: center;
    // align-content: center;
    flex: 1;
    & button {
      // margin-top: -15px;
      // width: 50%;
      // flex: 1;
    }
  }
  & .contact {
    display: flex;
    align-content: center;
    align-items: center;
    // justify-content: space-evenly;
    flex: 1;
    & .img_c {
      height: auto;
      margin-right: 25px;
      width: 50px;
      // background-color: bisque;
    }

    & h4 {
      text-align: left;
      font: normal normal bold 18px/15px Poppins;
      letter-spacing: 0px;
      color: #000000;
    }
    & span {
      text-align: left;
      font: normal normal medium 15px/15px Poppins;
      letter-spacing: 0px;
      color: #6e6e70;
    }
    // & * > {
    //   flex: 1;
    // }
  }
}

.commC {
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  justify-content: space-between;
  min-height: 367px;
  margin-top: 58px;
  // align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  &__left {
    // background-image: url("../../assets/img/bg/bgmap1.webp");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .img_container {
      max-height: 391px;
      max-width: 391px;
      border-radius: 50%;
      position: relative;
      .img_sm_top {
        position: absolute;
        max-height: 198px;
        max-width: 198px;
        top: -55px;
        right: -50px;
      }
      .img_sm_bt {
        position: absolute;
        max-height: 110px;
        max-width: 110px;
        left: -100px;
        bottom: 8px;
      }
    }
  }
  &__right {
    flex: 1;
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column;
    padding: 20px;
    & h4 {
      text-align: left;
      font: normal normal 600 18px/10px;
      letter-spacing: 0px;
      color: var(--tef-red);
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    & h3 {
      text-align: left;
      font: normal normal 900 36px/40px Poppins;
      letter-spacing: 0px;
      color: #000000;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
    & p {
      text-align: left;
      font: normal normal medium 15px/25px Poppins;
      letter-spacing: 0px;
      color: #6e6e70;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
    @media only screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.orange_dot {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: #f28e1a;
  position: absolute;
  z-index: 999;
}

.comm_orange_dot {
  @extend .orange_dot;
  top: 79px;
  left: 60px;
}
.f_label {
  text-align: left;
  font: normal normal normal 14px/24px Poppins;
  letter-spacing: 0px;
  color: #6e6e70;
}
.f_input {
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 50px;
}

.s_btn {
  @media only screen and (max-width: 548px) {
    width: 100%;
  }
}

.active_indicator {
  background-color: var(--tef-red) !important;
}
.indicatorItem {
  z-index: 1;
  display: flex;
  background-color: #6e6e70;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  margin: 0 20px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.indicatorC {
  float: left;
  // margin: 0 20px;
  // height: 20px;
  // width: 20px;
  // border-radius: 50%;
  display: flex;
  // background-color: #6e6e70;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  // z-index: 999;
}
.indicatorC::before {
  content: "";
  position: absolute;

  width: 100%;

  left: -100px;
  background-color: rgba(169, 206, 229, 0.5);
  height: 2px;
  z-index: 0;
  vertical-align: middle;
  display: inline-block;
  // margin-left: -2%;
}

.indicatorC::after {
  content: "";
  position: absolute;
  width: 100%;
  right: -30px;
  background-color: rgba(169, 206, 229, 0.5);
  height: 2px;
  z-index: 0;
  vertical-align: middle;
  display: inline-block;
}

// .indicatorC:before,
// .indicatorC:after {
//   background-color: rgba(169, 206, 229, 0.5);
//   content: "";
//   display: inline-block;
//   height: 2px;
//   position: relative;
//   vertical-align: middle;
//   width: 1%;
// }

// .indicatorC:before {
//   right: 0.5em;
//   margin-left: -1%;
// }

// .indicatorC:after {
//   left: 0.5em;
//   margin-right: -1%;
// }

.fade {
  // -webkit-animation-name: fade;
  // -webkit-animation-duration: 2s;
  // animation-name: fade;
  // animation-duration: 2s;
  animation: fade 3s ease-in-out;
  transition: all;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  // -webkit-animation-name: fadeOut;
  // -webkit-animation-duration: 1.5s;
  // animation-name: fadeOut;
  // animation-duration: 1.5s;
  animation: fadeOut 1s ease-out;
  transition: all;
}

.indicatorContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  @media only screen and (max-width: 527px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.socialContainer {
  list-style-type: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  color: var(--tef-red);
  &::after {
    content: "";
    height: 2px;
    margin-left: 10px;
    width: 100px;
    background-color: rgba(169, 206, 229, 0.5);
    margin-top: 20px;
    @media only screen and (max-width: 654px) {
      height: 0;
      margin-left: 0;
      width: 0;
    }
  }
  & li {
    align-self: center;
    align-items: center;
    color: #6e6e70;

    &:not(:first-child) {
      margin-left: 30px;
    }
    & a {
      color: var(--tef-red);

      &:hover {
        color: #6e6e70;
      }
    }
  }
}
