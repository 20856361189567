.MessageDisplay {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 13px;
    border-bottom: 1px solid var(--pale-lilac);
  }

  &__name {
    text-transform: capitalize;
    font-family: Poppins;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.38px;
    color: var(--h-1-black-new);
    line-height: normal;
    word-break: break-all;
  }

  &__message {
    padding: 10px 13px;
    border-bottom: 1px solid var(--pale-lilac);
    height: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__editor {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__editorBox {
    height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    margin-bottom: 5px;
    flex: 90%;
    word-break: break-all;

    .draftJsEmojiPlugin__emojiSelectPopover {
      bottom: 50px;
    }
  }

  &__sendBtn {
    flex: 10%;
    color: var(--tef-red);
    margin-bottom: 20px;
    cursor: pointer;
  }

  &__loader {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
