.chat-widget__body {
  height: 85%;
  margin-top: 90px;
}

.current-chat__info {
  height: 10%;
  background: #f5f9ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}

.mobile-align-items-center {
  display: flex;
  align-items: center;
}
.current-chat__image {
  margin-right: 10px;
}
.current-chat-logo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.current-chat__messages {
  height: 80%;
  padding: 15px;
  overflow-y: auto;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}
.current-chat__action {
  display: flex;
  padding: 10px;
  border-top: 1px solid #e2e5ec;
}
.current-chat__action-left {
  display: flex;
}
.current-chat__action-right {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tef-red);
  border-radius: 100%;
  color: #fff;
  cursor: pointer;
}
.current-chat__action-input__box {
  width: 70%;
  padding-bottom: 20px;
}
