.MentorshipTCContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.MentorshipTC {
  width: 60%;
  padding: 3% 7%;
  background: #fff;
  border-radius: 2px;
  margin: 1rem 0;
}

.Top {
  display: flex;
  justify-content: space-between;
}

.AgreeButton {
  padding: 0.8rem 2rem;
  border-radius: 4px;
  background-color: var(--tef-red);
  color: var(--white);
  font-family: Poppins;
  font-size: 14px;
  /* font-weight: 600; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  cursor: pointer;
}

.DisagreeButton {
  padding: 0.8rem 2rem;
  border-radius: 4px;
  background-color: var(--tef-grey);
  color: var(--white);
  font-family: Poppins;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  cursor: pointer;
}

.Body {
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
  height: 80vh;
}
