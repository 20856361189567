.SummaryCard {
  display: flex;
  justify-content: space-around;
}
.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  background-color: #f7f7f7;
  border-radius: 0.625rem;
}
.Icon {
  width: 70%;
}
.OtherDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.8rem;
  font-family: Poppins;
}
.Info {
  font-weight: 600;
  color: var(--dark-grey);
  margin-bottom: 0px;
}
.Details {
  color: #8f92a1;
}

@media (min-width: 768px) {
  .SummaryCard {
    display: flex;
    justify-content: flex-start;
  }
  .OtherDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.1rem;
    font-family: Poppins;
  }
  .IconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.6rem;
    width: 3rem;
    height: 3rem;
    background-color: #f7f7f7;
    border-radius: 0.625rem;
  }
  .Info {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--dark-grey);
    margin-bottom: 0px;
  }
  .Details {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #8f92a1;
  }
}
