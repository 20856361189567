.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-top: 5px;

  &__name {
    text-align: left;
    font: normal normal 600 14px/16px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-left: 15px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  &__ddp {
    margin-top: -7px;
    // margin-left: 36px;
    color: #6e6e70;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}
