.tab-heading {
  font-size: 0.87rem;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.discover-menu {
  color: var(--helper-text-color);
  background-color: var(--white);
  padding: 1rem 0.8rem 1rem 1rem;
  cursor: pointer;
  border-radius: 4px;

  .active {
    color: var(--tef-red);
    border-bottom: 2px solid var(--tef-red);
    border-radius: 0px 1px;
  }
}

.mainContainer {
  &__title {
    font-family: Poppins;
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #171725;
  }

  &__desc {
    font-family: Poppins;
    text-transform: capitalize;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #888;
  }
}

.container-box {
  padding-top: 30px;

  &__regular-box {
    height: fit-content;
    background-color: white;
    border-radius: 3px;
    padding: 10px;
    margin: 5px;
    margin-bottom: 15px;
  }

  &__content-box {
    color: #444;
    padding-top: 5px;
    padding-bottom: 5px;

    h6 {
      font-family: Poppins;
      text-transform: capitalize;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-size: 11px;
      letter-spacing: 0.4px;
      color: #171725;
      font-size: 600;
    }
  }

  &__header-box {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #f0f0f0;
    padding-bottom: 3px;

    h6 {
      font-family: Poppins;
      text-transform: capitalize;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-size: 11px;
      letter-spacing: 0.4px;
      color: #666;
    }
  }
}

.sort-dropdown {
  color: #919ba8;
  background-color: var(--white);
  padding: 1rem 0.8rem 1rem 1rem;
  border-radius: 4px;
}

.select-dropdown {
  outline: none;
  border: none;
}
