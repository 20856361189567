.MainContent {
    width: 97%;
    margin-top: 1.5rem;
}

.PageHeader {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
    font-size: 1.5rem;
}

.TopRow {
    border-radius: 4px;
    background-color: var(--white);
    height: 134px;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    margin-bottom: 1rem;
}

.TopRowRight {
    flex: 0 0 65%;
}

.TopRowLEft {
    flex: 1;
}

.TellUsMore {
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--h-1-black-new);
    margin-bottom: 0.5rem;
}

.FocusOnFeatures {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--helper-text-color);
}

.BottomRow {
    box-sizing: border-box;
    display: flex;
}

.BottomRight {
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
    margin-left: 0.5rem;
    border-radius: 4px;
    background-color: var(--white);
}

.BottomRightTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
}

.BottomLeft {
    padding: 2rem 3rem;
    border-radius: 4px;
    background-color: var(--white);
    margin-right: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    flex: 0 0 60%;
}

.RightSideCard {
    border-radius: 6px;
    border: solid 1px var(--new-faded-white);
    background-color: rgba(247, 248, 251, 0.3);
}

.CardTitle {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
    text-align: center;
    margin-bottom: 0.5rem;
}

.CardLabel {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.2px;
    color: var(--tef-grey);
    text-align: center;
}

@media (max-width: 480px) {
    .BottomRow {
        flex-direction: column;
    }
}