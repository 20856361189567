.hub-users__wrapper {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;

  .users-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 40px;
  }
}