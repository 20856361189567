.main-card {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: solid 1px var(--new-faded-white);
  background-color: var(--white);
  margin: 0 0 10px 0;
  padding: 0 15px;
  box-shadow: 5px 19px 38px 0 rgba(0, 0, 0, 0.04);
}

.my-board-heading {
  height: 19px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #354052;
  margin-left: 24px;
}

.main-card-container {
  margin: 24px 0 10px 0;
}

.top-row-icon {
  color: var(--helper-text-color);
  margin-top: -3px;
}

.top-row-text {
  margin-left: 8px;
  padding-top: 12px;
  position: relative;
  top: 2px;
}

.goal-button {
  margin-top: -7px;
}

.mentor-search::placeholder {
  width: 122px;
  height: 14px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--helper-text-color);
}

.mentor-search {
  top: -5px;
  position: relative;
  width: 192px;
  height: 32px;
  border-radius: 4px;
  border: solid 1px var(--pale-lilac);
  background-color: var(--white);
}

.select-container {
  position: relative;
}

.goal-list-container {
  position: absolute;
  border: solid 1px var(--new-faded-white);
  border-radius: 4px;
  padding: 10px;
  z-index: 1;
  width: 100%;
  max-height: 200px;
  max-width: 500px;
  background-color: var(--white);
  overflow-y: auto;
  // display: none;
}

.goal-list-item {
  cursor: pointer;
}

.calendar_page {
  width: 100%;
}

.j-center {
  justify-content: center;
}
.a-center {
  align-items: center;
}
