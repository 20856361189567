.opp-banner-wrapper {
  height: 371px;
  position: relative;
  background-color: white;
}

.opp-carousel-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.lastest-opp-containter {
  width: 100%;
  display: flex;
  height: 100%;
  &__info {
    background-color: #152337;
    color: white;
    font-family: Poppins;
    letter-spacing: 0.2px;
    // width: 100%;
    padding: 20px 38px 20px 30px;
    flex: 1.42;
    height: 371px;
    display: flex;
  }
  &__logo {
    // background-color: pink;
    // width: 100%;
    height: 100%;
    flex: 1.62;
    & img {
      // height: inherit;
      height: 371px;
      width: 100%;
      object-fit: cover;
    }
  }
}

.opp-banner-latest {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &__btn-latest {
    border-radius: 15px;
    border: solid 1px var(--helper-text-color);
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.17px;
    text-align: center;
    color: var(--new-faded-white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    width: 72px;
    cursor: pointer;
  }
  &__heading {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.11;
    letter-spacing: 0.2px;
    color: var(--white);
    // flex: 2;
    margin: 8px 0px 20px 0px;
  }
  &__desc {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.2px;
    color: var(--white);
    margin-bottom: 30px;
    // flex: 1;
  }
  &__btn-apply {
    border-radius: 4px;
    background-color: var(--tef-red);
    padding: 5px 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 163px;
    cursor: pointer;
    // flex: 1;
    z-index: 30;
  }
}

.opp-carousel-custom-cont {
  position: absolute;
  z-index: 10;
  display: flex;
  // margin: 0 auto;
  justify-content: center;
  // justify-content: center;
  // flex-direction: column;
  left: 0;
  right: 0;
  bottom: 30px;
  // top:0;
  &__inner-container {
    display: flex;
  }
  &__left-arrow,
  &__right-arrow {
    padding: 15px 17px;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  }
  &__left-arrow {
    background-color: white;
    // margin-right: 20px;
    color: #132236;
    border-radius: 2.4px 0px 0px 2.4px;
  }
  &__right-arrow {
    background-color: #132236;
    color: white;
    // margin-left: 20px;
    border-radius: 0px 2.4px 2.4px 0px;
  }
}

.create-opportuniy-banner {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
  padding: 32px 64px 26px 37px;
  &__info {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 100px;
    & h1 {
      font-size: 28px;
      font-weight: 600;
      color: var(--tef-red);
    }
    & p {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.2px;
      color: var(--tef-grey);
    }
  }
  &__logo {
    flex: 1;
    height: 245px;
    width: 200px;
    & img {
      width: 100%;
      // height: 100%;
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: 1041px) {
  .lastest-opp-containter {
    &__info {
      padding: 15px;
    }
  }
}

@media only screen and (max-width: 645px) {
  .lastest-opp-containter {
    &__info {
      padding: 10px;
    }
    &__logo {
      display: none;
    }
  }
  .opp-carousel-custom-cont {
    bottom: -70px;
  }
}

@media only screen and (max-width: 600px) {
  .create-opportuniy-banner {
    &__info {
      padding-right: 0px;
    }
    &__logo {
      display: none;
    }
  }
}
