.Content {
  width: 1000px;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);

  &__breadcrumbs {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.2px;
    color: #1a1a1a;
    margin-bottom: 27px;
  }

  &__bread {
    letter-spacing: normal;
    color: #656565;
  }

  &__main {
    border-radius: 4px;
    background-color: var(--white);
    padding: 20px 20px 40px 20px;
    word-break: break-all;
  }

  &__instruction {
    text-align: center;
    margin-bottom: 30px;
  }

  &__btnContainer {
    margin-top: 30px;
    height: 36px;
    display: flex;
    align-items: center;
  }

  &__btn {
    width: 179px;
    padding: 10px;
    border-radius: 4px;
    background-color: var(--tef-red);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    text-transform: capitalize;

    &:focus {
      outline: none;
    }

    &:hover {
      text-decoration: none;
      background-color: white;
      color: red;
      border: 1px solid red;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  &__completed {
    margin-left: 20px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    text-align: center;
    color: var(--tef-grey);
    text-transform: capitalize;
  }
}

@media screen and (max-width: 768px) {
  .Content {
    width: 100%;

    &__breadcrumbs {
      margin-left: 9px;
      margin-right: 9px;
    }

    &__main {
      padding: 10px;
    }

    &__btn {
      width: 150px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .Content {
    width: 100%;
  }
}
