.fileupload-wrapper {
    display: flex;
    flex-direction: column;
}
.fileupload-box {
    background-color: #F0F0F0;
    align-items: center;
}
.fileupload-subtext {
   display: flex;
   flex-direction: column;
    align-content: center;
   margin-top: 4%;
   margin-right: auto
}
.fileupload-title{
    font-size: 19px;
    color: #000;
    margin-bottom: 10px;
}

.fileupload-btn {
background-color: #fff;
border:none;
border-radius: 10px;
padding: 10px 15px;
color: red;

}