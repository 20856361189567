.book-card-container {
  background: #f0f0f0;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 10px;
}

.img-container {
  width: 120px;
  height: 140px;
  max-height: 160px;
  border-radius: 10px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.2);
  margin-top: -30px;
  background-size: 100% 100%;
  align-content: flex-end;
  text-align: right;

  button {
    margin-right: 5px;
    margin-top: 10px;
    background: rgba(0, 0, 0, 0.5);
    padding: 0px !important;
  }
}

.__only {
  height: fit-content !important;
  margin-top: 30px;
  padding-bottom: 10px;
}

.content-container {
  width: 100%;
  padding-top: 5px;
  padding-left: 10px;

  &__title {
    height: 50px;
    max-height: 80px;

    h4 {
      font-family: Poppins;
      text-transform: capitalize;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.4px;
      color: #333;
      margin-bottom: 0px;
    }

    span {
      font-family: Poppins;
      text-transform: capitalize;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-size: 10px;
      font-weight: normal;
      letter-spacing: 0.4px;
      color: #777;
    }
  }

  &__description {
    h6 {
      font-family: Poppins;
      text-transform: capitalize;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-size: 9px;
      font-weight: normal;
      letter-spacing: 0.4px;
      color: #777;
    }
  }

  &__btn {
    color: var(--tef-red);
    font-size: 10px;
    font-family: Poppins;
    text-transform: capitalize;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    font-weight: 500;
    letter-spacing: 0.4px;
    padding: 0px;
    margin: 0px;
    outline: 0px solid transparent;
  }
}
