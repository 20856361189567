.article-card {
  max-width: 270px;
  // min-height: 607px;
  border-radius: 4px;
  border: solid 1px var(--new-faded-white);
  background-color: var(--white);
  margin: 14px 5px 14px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 25px;
  padding-left: 20px;
  cursor: pointer;
}

.article-card-heading {
  margin-top: 0.8rem;
  text-transform: uppercase;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--helper-text-color);
  text-align: center;
}

.see-more {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: var(--helper-text-color);
}
