.contact_us_container {
  @media only screen and (max-width:768px) {
    flex-wrap: wrap-reverse;
  }
}
.map_container {
  // background-color: chartreuse;
  // height: 100%;
  width: 100%;
  flex: 1;
}

.formSection {
  padding-right: 6%;
  margin-bottom: 76px;
  padding-left: 1%;

  @media only screen and (max-width:768px) {
    padding: 40px;
    // width: 100%;
  }

  & h4 {
    text-align: left;
    font: normal normal bold 36px/45px Poppins;
    letter-spacing: 0px;
    color: #31303a;
    opacity: 1;
  }
  & .msg {
    text-align: left;
    font: normal normal bold 17px/26px Poppins;
    letter-spacing: 0px;
    color: #6e6e70;
    margin-bottom: 40px;
  }
}

.f_label {
  text-align: left;
  font: normal normal medium 14px/24px Poppins;
  letter-spacing: 0px;
  color: #6e6e70;
}

.f_input {
  background-color: white;
  border: none;
  outline: none !important;
  height: 50px;
  &:focus {
    outline: none !important;
  }
}

.f_input_area {
  @extend .f_input;
  height: 158px !important;
}

.sub_btn {
  height: 48px;
  width: 204px;
}

.formContainer {
  margin-bottom: 84px;
}

.q_support_container {
  & h5 {
    text-align: left;
    font: normal normal bold 15px/23px Poppins;
    letter-spacing: 0px;
    color: var(--tef-red);
    opacity: 1;
    @media only screen and (max-width:768px) {
      // line-height: 0px;
      margin-left: 10px;
    }
  }
  & h6 {
    text-align: left;
    font: normal normal bold 15px/23px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    word-break: break-all;
    word-wrap: break-word;
    @media only screen and (max-width:768px) {
      line-height: 0;
      margin-bottom: 20px;
      margin-left: 10px;
    }
  }
}

.q_support_info {
  align-items: flex-start;
  justify-content: flex-start;
  @media only screen and (max-width:768px) {
    display: flex;
  }
}
