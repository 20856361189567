.community-search-div {
  margin: 0 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  & > * {
    flex: 1;
  }

  h2 {
    font-family: Poppins;
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #171725;
  }

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-community,
    .create-new-community,
    .search-community-icon {
      border: 0;
    }

    .search-community,
    .search-community-icon {
      height: 38px;
      background-color: var(--white);
      color: var(--blue-grey);

      &:focus {
        outline: none;
      }
    }

    .search-container {
      flex: 0 0 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search-community {
      width: 90%;
      border-radius: 6px;
      padding: 11px 13px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      transition: all 0.2s;
      margin-right: -2.25rem;

      &:focus {
        width: 100%;
      }

      &::placeholder {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.11px;
        color: var(--helper-text-color);
      }
    }

    .search-community-icon {
      background-color: white;
      // align-self: flex-end;
      z-index: 200;
      flex: 1;
      &:active {
        transform: translateY(2px);
      }
    }

    .search-community-icon[disabled] {
      background-image: none;
    }

    .create-new-community {
      width: 147px;
      height: 38px;
      border-radius: 30px;
      background-color: var(--tef-red);
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      color: #fafafb;
      text-transform: capitalize;
      transition: all 0.2s;

      display: flex;
      justify-content: center;
      align-items: center;

      &:focus {
        outline: none;
      }

      &:active {
        transform: translateY(2px);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .community-search-div {
    flex-direction: column;
  }
}
