.nav-card {
  // height: 47px;
  border-radius: 4px;
  border: solid 1px var(--new-faded-white);
  background-color: var(--white);
  margin: 18px 0 16px 0;
  padding: 5px;
  text-align: center;
}

.tab-links {
  // height: 40px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  margin-top: 5px;
  padding-left: 0px;
  padding-right: 0px;
  color: var(--helper-text-color);
}

.nav-link.active {
  color: var(--tef-red);
  border-bottom: 2px solid var(--tef-red);
}

.tab-c {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  background-color: white;
  margin: 10px 0px 10px 0px;
  border: 1px solid var(--new-faded-white);
  border-radius: 4px;
  padding-left: 30px;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
}

.tab-ind {
  margin-right: 40px;
  cursor: pointer;
  padding: 10px;
  align-self: center;
  overflow: hidden;
  &:hover {
    color: var(--helper-text-color);
  }
  // transition: 0.2s ease-in-out;
  height: 100%;
}

.active-tab-item {
  border-bottom: 2px solid var(--tef-red);
  color: var(--tef-red);
  &:hover {
    color: var(--tef-red);
  }
}

@media only screen and (max-width:500px) {
  .tab-ind {
    display: block;
    margin: 5px 0px;
  }

  .tab-c {
    display: block;
  }
}
