.application-personal-info-c{
  width: 100%;
  margin: 80px 0px 145px 0px;
  padding: 0px 50px;
  // height: 78px;
  &__heading{
    margin-bottom: 43px;
    & h1{
      font-family: SFProText;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: normal;
      color: #26292d;
    }
    & p{
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: normal;
      color: #63666a;
      & span{
        color: #26292d;
        font-weight: bold;
      }
    }
  }

  &__form{
    background-color: white;
    padding: 41px;
    // border-top: 4px solid #da291c;
    border-radius: 6px;
  }
}

.progress-b-c{
  background-color: #f6f8fc;
  width: 100%;
  height: 4px;
  border-radius: 6px 6px 0 0;
}

.progress-b-item{
  background-color: #da291c;
  height: 100%;
  border-radius: 6px 6px 0 0;

}

.p-info-form-heading{
  margin-bottom: 30px;
  margin-left: 12px;
  & h5{
    font-family: SFProText;
    font-size: 20px;
    font-weight: 300;
    color: #26292d;}
    & h4{
      font-family: SFProText;
      font-size: 14px;
      color: #63666a;
    }
}

.bold{
  font-weight: bold;
}

.little-badge{
  border-radius: 2px;
  background-color: #d8e9e4;
  font-family: SFProText;
  font-size: 6px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #0db884;
  padding: 4px;
  align-self: center;
}

.select-box-c{
  width: 100%;
  font-family: SFProText;
  font-size: 13px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #26292d;
  text-align: center;
  padding: 8px 17px;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21), inset 0 0 0 1px rgba(102, 113, 123, 0.25);
  // border: solid 1px #c4cdd5;
  background-color: #ffffff;
  cursor: pointer;
  // display: flex;
  min-height: 38px;
  margin-right:3px;
  flex: 1;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  &:hover{
    background-color: #da291c;
    color: white;
    // border: 1px solid #da291c;
  }
}

.selected-box{
  background-color: #da291c;
  color: white;
  border: 1px solid #da291c;
}

.info{
  font-family: SFProText;
  font-size: 10px;
  color: #919ba8;
}

.next-of-kin-box{
  margin-top: 35px;
}

.submit-box{
  margin-top: 35px;
  width: 100%;
  // background-color: #da291c;
}
.submit-btn{
  margin: 0px 10px;
  // background-color: #da291c;
  // background-color: #da291c;
  color:white;
  padding: 8px 0px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.1), inset 0 1px 0 1px rgba(255, 255, 255, 0.06);
  border: solid 1px #da291c;
  background-image: linear-gradient(to bottom, #da291c, #cf1d10);
  &:hover{
    opacity: .5;
  }
  text-align: center;
  font-family: SFProText;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.back-btn-somewhere{
  margin-left: 50px;
}

@media only screen and (max-width:1141px){
  .back-btn-somewhere{
    margin-left: 20px;
  }

  .application-personal-info-c{
    // padding: 0px 5px;
    &__form{
      padding-left:10px;
      padding-right: 10px;
    }
  }
}

@media only screen and (max-width:992px){
  .application-personal-info-c{
    padding: 0px 5px;
    &__form{
      padding-left:10px;
      padding-right: 10px;
    }
  }
}

@media only screen and (max-width:767px){
  .submit-btn{
    margin: 0px;
  }
}

.f1{
  flex:1
}
.dob{
  // margin-left: 20px;
  flex-grow: 1;
}


.gutter-v{
  margin-top: 0px;
}

@media only screen and (max-width:575px){
 
.gutter-v{
    &:not(:first-child){
      margin-top: 3px;
    }
  }
}

.next-prev-c{
  display: flex;
  justify-content:center;
  align-items: center;
  // margin: 0 auto;
  margin-top: 30px;
  color: white;
}

.next-btn{
  background-color: #26292d;
  padding:5px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  z-index: 1;
}
.save-continue-btn{
  background-color:transparent;
  // background-color: green;
  border: none;
  padding:5px;
  // text-align: center;
  cursor: pointer;
  z-index: 1;
  width: 100%;

  font-family: SFProText;
  font-size: 14px;
  line-height: 1;
  letter-spacing: normal;
  color: #63666a;
  opacity: 0.54;

  
}

.id-upload{
  height: 150px;
  width: 150px;
  margin-top: 10px;
  border: 1px solid var(--new-faded-white);
  border-radius: 4px;
  padding: 2px;
}

.id-file-upload{
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
  & + label{
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
  }
   
}

.id-upload-btn{
  background-color: var(--tef-red);
  color: white;
  padding: 10px 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0px 10px 0px 0px;
  cursor: pointer;
  font-size: 12px;
  font-family: SFProText;
  border-radius: 5px;
}

.back-2-somewhere{
  padding: 5px 10px;
  border-radius: 4px;
  background-color: var(--tef-red);
  margin-right: 5px;
  text-align: center;
  // margin-bottom: 20px;
  color: white;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
}