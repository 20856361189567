.alumni-event-imgbox {
  // display: flex;

  // align-items:flex-start;
  // justify-content: flex-end;
  // flex-direction: column;
  // margin-bottom: 20px;
  position: relative;
  border-radius: 20px;
  // background-image: liear-gradient(
  //          to right bottom,
  //         rgba(0,0,0,0.3),
  //         red
  //      );
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  // background: transparent linear-gradient(180deg, #26292D00 0%, #26292D 100%) 0% 0% no-repeat padding-box;

  &__status {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 10px;
    margin-left: 12px;
  }
  &__title {
    padding-top: 10px;
    font-weight: 400;
    text-transform: capitalize;

    color: #fff;
    display: inline-block;
    font-size: 25px;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 0px;
    &--para {
      font-size: 29px;
      line-height: 29px;
    }
  }
  &__discription {
    width: 100%;
    color: #fff;

    font-size: 14px;
  }
  &__metaData {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__btn--featured {
    color: #fff;
    border: 1px solid #fff;
    background: none;
    align-self: flex-start;
  }
}
.event-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;

  &__category {
    margin-right: auto;
    font-size: 20px;
    font-weight: bold;
  }
  &__more {
    color: #d42316;
    font-size: 15px;
  }
}
#event-search {
  width: 30%;
}
#event-search-boxes {
  width: 100%;
}
.event-page {
  line-height: 1.2px;
}

.alumni-event__card-body {
  align-items: center;
}
.resource__title {
  cursor: pointer;
}
.alumni-event {
  border: none;

  position: relative;
  &__card-body {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__title {
    font-size: 18px;
  }
  &__short-desc {
    font-size: 12px;
    color: #919ba8;
  }

  &__slider--box {
    position: absolute;
    left: 3%;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    width: 40%;
    z-index: 900;
  }

  &__img {
    height: 150px;
  }

  &--cta-slide {
    font-size: 12px;
  }

  &--link {
    color: #d42316;
    font-size: 12px;
    font-weight: bold;
  }

  &__time {
    font-size: 12px;
    color: black;
    margin-right: auto;
  }
}
.alumni-event-list {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  // justify-content: space-between;
}
.alumni-event-list-item {
  width: 22.5%;
}

.alumni-event-list-item {
  margin-right: 3.333%;
}
.alumni-event-list-item:nth-child(4n + 0) {
  margin-right: 0;
}
.event__icon,
.alumni-event__icon {
  margin-right: 10px;
}
.view-opportunity {
  padding: 10px 40px;
}

.event__only {
  display: inline-block;
  background-color: #004b7d;
  color: white;
  border-top-right-radius: 25px;
  border-radius: 0px 20px 20px 0px;
  padding: 5px 8px;
  font-size: 13px;
  position: absolute;
  top: 25%;
}
.event-short {
  font-size: 12px;
}
.event-detail__metaData {
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.event-detail--card {
  border: none !important;
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.alumni-event-imgbox__btn--featured {
  border-radius: 5px;
  font-size: 12px;
}

.event-rsvp {
  margin-bottom: 30px;
}
.event-cta {
  font-size: 14px;
  margin-bottom: 10px;
}
.event-detail--visa {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
}

.user__likes {
  display: flex;
  justify-content: space-between;
}

.user__list {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.user__list:not(:last-child) {
  margin-right: -15px;
  border: 1px solid white;
}

.btn-remainder {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.btn-remainder:first-child {
  margin-right: 10px;
}

.event__main {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
}
.event-remainder {
  margin-bottom: 20px;
}

.event__contact,
.event__comment,
.comment__section {
  background-color: white;
  padding: 10px;
}
.event__contact {
  margin-bottom: 30px;
}
.event__comment {
  padding: 30px;
}

.post__comment--btn {
  float: right;
  margin-top: 20px;
}
.post__comment:after {
  content: "";
  clear: both;
  display: table;
}
.event__input {
  background-color: #ced4da;
  border: none !important;
}

.rate__comment {
  display: flex;
  align-items: center;
}

.star__rate {
  font-size: 40px;
}
.rate__title {
  font-size: 20px;
  text-align: center;
}
.event-tag {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.alumni-event-imgbox__icon {
  margin-right: 10px;
}
.event-tag--item {
  display: inline-block;
  background-color: #ced4da;
  padding: 8px 10px;

  border-radius: 10px;
}
.event--date-val {
  font-size: 10px !important;
}
.event-tag--item:not(:last-child) {
  margin-right: 2px;
}

.create-event-header {
  display: flex;
  align-items: center;
}
.create-header-title {
  margin-right: auto;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
}
.custom-indicator {
  margin-right: 10%;
}
.carousel-item,
.carousel-item.active {
  align-items: center;
}

.create-header-cancel {
  padding: 14px 20px;
  background: #dddddd 0% 0% no-repeat padding-box;
  border-radius: 7px;
  color: #63666a;
  font-size: 12px;
}
.event-date,
.event-locate {
  font-size: 12px;
}
.event-date {
  margin-bottom: 3px;
}
.create-event-label {
  font-size: 13px;
  font-weight: bold;
}
.required-tag {
  font-style: italic;
  font-size: 13px;
  color: #919ba8;
}
.event-tag-group {
  display: flex;
  flex-wrap: wrap;
}
.cancel-send {
  background: none;
  border: none;
  background: #eaecef 0% 0% no-repeat padding-box;
  color: #63666a;
}
.align-create-btn {
  display: flex;
  align-items: flex-end;
}
.event-tag-group > span {
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  color: black;
}
.create-event-tag {
  display: flex;
  align-items: center;
}
.event-where {
  font-size: 14px;
  font-weight: bold;
}
.alumni-event__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.event-date {
  display: flex;
  //  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 12px;
}
.alumni-event__icon {
  margin-right: 10px;
  padding: 3px;
  background-color: #e2e5ec;
  border-radius: 5px;
}
// .width-23 {
//   width: 23%;
// }
.event-upload-wrapper {
  display: flex;
}
.event-upload-wrapper .opp-create-form__image-c {
  width: 50%;
  margin-right: 15px;
}
.event-photo-icon-box {
  position: absolute;
  top: 30%;
  left: 5%;
}
.event-upload-image-label {
  font-size: 15px !important;
  font-weight: bold;
}
#event-date-pick .react-datepicker-component .react-datepicker-input input {
  background-color: #f0f0f0;
}
@media screen and (max-width: 790px) {
  // .alumni-event-imgbox__title {
  //     width: 90%;
  // }
  .event__only {
    display: none;
  }
  .alumni-event-imgbox__discription {
    width: 90%;
  }
  .alumni-event-box-icon-box {
    width: 90%;
  }
  .alumni-event-box-icon-box__meta {
    margin-right: auto;
  }
  // .alumni-event-imgbox__metaData {
  //     width: 90%;
  // }
  .card-text {
    align-self: start;
  }
  .alumni-event--link {
    align-self: center;
  }
  .event-page-header-section {
    display: flex;
  }
  .custom-indicator {
    top: 0%;
  }

  .carousel-inner img {
    object-fit: cover;
    height: 450px;
  }
  .alumni-event__slider--box {
    position: absolute;
    left: 3%;
    bottom: 15%;
    width: 90%;
    flex-direction: column;
  }
  .alumni-event-list {
    display: flex;
    flex-wrap: wrap;
    align-self: start;
    width: 100%;
    justify-content: space-between;
  }
  .alumni-event-list-item {
    display: flex;
    flex: 0 0 48%;

    margin-bottom: 10px;
  }
  .alumni-event-list-item {
    margin-right: 0
  }
  .alumni-event-list-item:nth-child(4n + 0) {
    margin-right: 0;
  }
  .alumni-event-imgbox__metaData {
    flex-direction: column;
    align-items: flex-start;
  }
  .alumni-event--cta-slide {
    font-size: 12px;
    width: 100%;
  }
  .mobile-event-desc {
    display: none;
  }
  .event-date {
    font-size: 13px;
    margin-bottom: 12px;
    justify-content: flex-start;
  }
  .event-date:last-child {
    margin-bottom: 17px;
  }
  .alumni-event-imgbox__btn--featured {
    margin-bottom: 70px;
  }
  .alumni-event__posted {
    margin-right: auto;
    color: rgb(0, 0, 0);
    display: flex
  }
  .event-locate {
    font-size: 15px;
  }
  .event--date-val {
    font-size: 13px !important;
  }
  .event-upload-wrapper {
    flex-direction: column;
  }
  .event-upload-wrapper .opp-create-form__image-c {
    width: 100%;
  }
  .event-photo-icon-box {
    left: 2%;
  }
  .event-upload-image-label {
    margin-left: 30px !important;
  }
  .upload-image-btn {
    margin-left: 30px !important;
  }
}
