.data-board {
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;

  &__curve-box {
    background-color: white;
    border-radius: 8px;
    //  height: 110px;
    padding: 10px;
    margin-top: 5px;

    h5 {
      font-family: Poppins;
      text-transform: capitalize;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 0.4px;
      color: #444;
    }

    h6 {
      font-family: Poppins;
      text-transform: capitalize;
      font-size: 10px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-weight: 600;
      letter-spacing: 0.4px;
      color: var(--tef-red);
    }

    h3 {
      font-family: Poppins;
      text-transform: capitalize;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-weight: 600;
      letter-spacing: 0.4px;
      color: #171725;
      //  text-align: right;
    }
  }

  $breakpoint-tablet: 768px;
  @media (max-width: $breakpoint-tablet) {
    &__curve-box {
      width: 50% !important;
      flex-basis: -1 !important;
      margin: 10px;
      text-align: center;
    }
  }
}
