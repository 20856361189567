.prevMain{
  width: 50%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
    @media only screen and (max-width:992px){
      width: 80%;
    }

    @media only screen and (max-width:500px){
      width: 90%;
    }
}
.notF{
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 100px;
  width: 100%;
}

.clickHere{
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline double;
  font-weight: bolder;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // background: white;
  // color: #000;
  // padding: 5px 10px;
  // width: max-content;
  // align-self: flex-start;
}
.loader{
  color: inherit;
  display: flex;
  align-items: center;
  background: rgb(93, 130, 155);
  background: rgba(93, 130, 155, 0.4);
  width: fit-content;
  padding: 5px 10px;
  border-radius: 15px;
}
.gbC{
  background-color: var(--tef-red);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 20%;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.08);

   @media only screen and (max-width:500px){
     width: 35%;
   }
   border-radius: 0 25px 25px 0;
}

.langSelectPrompt{
  background: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.3));
  padding: 5px 10px;
  border-radius: 25px;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.alertClass{
  background: rgb(100, 101, 115);
  color: #fff;
  box-shadow: 5px 19px 38px 0 rgba(0, 0, 0, 0.05);
}