.DownloadRow,
.DownloadIcon {
  display: flex;
}

.DownloadIcon {
  align-items: center;
  justify-content: flex-start;
  margin-left: 1rem;
}

.AttachmentRow {
  color: var(--tef-red);
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}

.Files {
  margin-bottom: 1rem;
}
