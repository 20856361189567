/* Tour or Onboarding page */
.tour-menu-title {
    font-size: 2em;
    line-height: 1.6;
    text-align: center;
    font-weight: bold;
}
.tourBottom{
    /*position: relative; */
    text-align: center;
    background-color: #31354c;
    color:white;
    padding: 2.5rem 0;
    text-align: center;
    line-height: 1.6;
    font-size:1.0em;    
}


.tourBottom .back .backLink{
    cursor: pointer;
    color: white;
    opacity: 0.6;
    // font: outline.9em;
    transition: color ease-in-out 200ms;
}
.tourBottom .skip .skipLink {
    cursor: pointer;
    /*position: relative;*/
    color: white;
    opacity: 0.6;
    // font: outline.9em;
}
.tourBottom .skip .skipLink:hover{
    text-decoration: none;
}
.tourBottom .bottomFooter {
    margin-top: 3rem;
}
.bottomFooter .back .backLink:hover, .bottomFooter .skip .skipLink:hover{
    cursor: pointer;
    opacity: 1;
}

.bottomFooter .startApp, .bottomFooter .startApp:hover {
    color: #212529;
    text-decoration: none;
}

.tourImg {
    width: 100%;
}
