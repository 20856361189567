.accordion {    
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button:focus {
    outline: none;
    border: none;
}

.accordion__button {    
    color: #444;
    cursor: pointer;
    padding-top: 1.5em;
    padding-bottom: 0.5em;
    width: 100%;
    text-align: left;
    border-bottom: 0.1px solid #E8EAED;
    font-weight: 700;
    font-size: 0.9rem;
}

.accordion__button:after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    position: absolute;
    right: 10px;
    margin-top: 4px;
    color: #DA291C;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(90deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 10px;
    animation: fadein 0.35s ease-in;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
