.RequestCard {
  width: 464px;
  height: 89px;
  border-radius: 4px;
  background-color: var(--white);
  margin: 5px 0;
  display: flex;
  justify-content: space-between;

  &__image {
    flex: 0.2;
    border-radius: 4px;
    background-color: blueviolet;
  }

  &__image1 {
    object-position: 50% 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__details {
    flex: 0.8;
    // width: 79%;
    // max-width: 357px;
    border-radius: 4px;
    border: solid 1px #f1f1f5;
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    word-break: break-all;
    flex: 0.8;
  }

  &__btn {
    width: 97px;
    height: 38px;
    border-radius: 4px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    text-transform: capitalize;
    transition: all 0.2s;
    border: 0;
  }

  &__buttons {
    flex: 0.2;
  }

  &__acceptBtn {
    margin-right: 5px;
    color: white;
  }

  &__rejectBtn {
    background-color: white;
    color: var(--tef-red);
    border: 1px solid red;
  }
}
