.goal-details-card {
  margin: 0 0 0 0;
  min-height: 508px;
  border-radius: 4px;
  background-color: var(--white);
  padding: 32px;
}
// .goal-detail-item:{

// }

.goal-details-heading {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.2px;
  color: var(--dark);
}
.goal-completion-box {
  // height: 32px;
  border-radius: 4px;
  border: solid 1px #e7e7e7;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 10px 5px;
}
.goal-complete-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: var(--tef-grey);
}

.goal-details-sub {
  // height: 14px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: var(--helper-text-color);
}

.goal-date-due {
  // height: 26px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.1px;
  color: #44444f;
}
.goal-description {
  // height: 16px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: #171725;
}
.goal-paragraph {
  // height: 168px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.2px;
  color: var(--tef-grey);
}
.goal-details-status {
  width: 100px;
  // height: 18px;
  border-radius: 3.5px;
  padding: 5px;
  color: white;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
}
.goal-details-breadcrumb {
  margin: 25px 0;

  &__heading {
    height: 20px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1a1a1a;
  }
}

.status-color-in-progress {
  background-color: #a880e3;
}

.status-color-in-review {
  background-color: #f5a623;
}
.status-color-completed {
  background-color: #0db884;
}

.due {
  background-color: #e6daf7;
  padding: 5px;
  // opacity: 0.1;
  border-radius: 4px;
  max-width: 110px;
  & span {
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #a880e3;
  }
}

.change-action-button {
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0.2px;
  color: var(--helper-text-color);
  cursor: pointer;
}

.line-through {
  text-decoration: line-through;
}

.assigned-to-container {
  display: flex;
  justify-content: flex-start;
  & span {
    margin-left: 10px;
  }
}

.show-full-desc-text {
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: var(--tef-red);
  text-decoration: underline;
  margin-left: 30px;
  margin-top: -50px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .goal-details-sub {
    margin: 10px 0;
  }
}
