.container{
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-around;
  flex: 1;
  @media only screen and (max-width:768px) {
    margin-top: 10px;
    justify-content: space-between;
  }
}

.icon{
  align-self: stretch;
  // position: relative;
  color: #6E6E70;
  &:not(:first-child){
    margin-left:20px;
  }
  &:hover{
    color: var(--tef-red);
    // background-color: #eee;
    fill: var(--tef-red);

    transition: all 0.5s ease-in;
  }

  & a{
    &:hover{
      color: var(--tef-red);
    }
  }

  @media only screen and (max-width:768px) {
    display: none;
  }
}

.activeIcon{
  fill: var(--tef-red);
}
.bell{
  position: relative;
}

.acount{
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
}

.iconContainer{
  display: block;
}

@media only screen and (max-width:768px) {
  .iconContainer{
    display: none;
  }
}


.mobile_menu_item{
  display: none;
  & img{
    // width: 100%;
    height: 30px;
  }
  @media only screen and (max-width:768px) {
   display: block;
  }
}
