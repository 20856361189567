.GroupBanner:hover {
    cursor: pointer;
    filter: grayscale(0.7);
    transform: scale(0.98);
    transition-property: filter transform;
    transition-duration: 0.03s;
    transition-timing-function: ease-in;
}

.GroupCard {
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 0.5rem;
}