.suggested-group-card {
  width: 227px;
  max-width: 90%;
  height: 252px;
  border-radius: 4px;
  border: solid 1px #f1f1f5;
  background-color: var(--white);
  margin-bottom: 13px;

  &__image {
    height: 44%;
    background-color: #eaecef;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    & .group-image-1 {
      height: 100%;
      img {
        object-position: center;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__details {
    height: 56%;
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;

    & .suggested-group-details-h1 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      color: #171725;
      text-transform: capitalize;
    }

    & .suggested-group-details-h3 {
      margin-top: -20px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      color: var(--blue-grey);
      text-transform: capitalize;

      & span {
        width: 4px;
        height: 4px;
      }
    }

    & .suggested-group-details-button {
      width: 100%;
      height: 38px;
      border-radius: 4px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: center;
      text-transform: capitalize;
      transition: all 0.2s;

      &:focus {
        outline: none;
      }

      &:active {
        transform: translateY(2px);
      }
    }

    & .suggested-group-button-active {
      background-color: var(--tef-red);
      border: 0;
      color: #fff;

      &:hover {
        border: 1px solid var(--tef-red);
        color: var(--tef-red);
        background-color: #fafafb;
      }
    }

    & .suggested-group-button-inactive {
      background-color: #fff;
      border: 1px solid var(--tef-red);
      color: var(--tef-red);

      &:hover {
        background-color: var(--tef-red);
        color: #fafafb;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .suggested-group-card {
    margin: 8px auto;
    // width: 90%;
  }
}

@media screen and (min-width: 476px) and (max-width: 1439px) {
  .suggested-group-card {
    margin: 8px 0;
    // width: 90%;
  }
}
