.tab-heading {
  font-size: 0.87rem;
  padding-top: 20px;
}

.content-holder {
  width: 100%;
  // padding-left: 10px;
  // padding-right: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  margin-top: 10px;
  padding-top: 20px;
}

.mainContainer {
  &__title {
    font-family: Poppins;
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #171725;
  }

  &__desc {
    font-family: Poppins;
    text-transform: capitalize;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #888;
  }
}
