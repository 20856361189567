.Header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  height: 6rem;
  box-shadow: inset 0 -1px 0 0 var(--pale-lilac);
  background-color: var(--white);
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);

  display: flex;
  justify-content: space-around;
  align-items: center;

  &__menuIcon {
    display: none;
  }

  &__logo {
    height: 30px;
  }

  &__search {
    flex: 0 0 20%;
  }
}

@media screen and (max-width: "425px") {
  .Header {
    padding-right: 0;
    padding-left: 0;

    &__menuIcon {
      margin-right: 20px;
    }

    &__logo {
      display: none;
    }
  }
}

@media screen and (max-width: "768px") {
  .Header {
    padding-left: 0;
    padding-right: 0;

    &__menuIcon {
      display: block;
      align-self: stretch;
      flex: 0 0 5%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__search {
      display: none;
    }
  }
}
