.NotEligible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__h3 {
    font-family: Poppins;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.2px;
    color: var(--helper-text-color);
    text-align: center;
  }

  &__btn {
    width: 100px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4.2px;
    border: 0;
    box-shadow: 0 6px 13px 0 rgba(82, 223, 169, 0.13);
    background-image: linear-gradient(to bottom, var(--tef-red), #cf1d10);
    font-family: SFProText;
    font-size: 14.7px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
    text-transform: capitalize;
    transition: all 0.2s;

    &:active {
      transform: translateY(2px);
    }

    &:focus {
      outline: none;
    }
  }
}
