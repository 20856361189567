.hub-card {
  background: #F4F5F6 !important;
  min-height: 1px;
  // border: 1px solid rgb(219, 219, 219);
  word-break: break-all;

  .hub-user__image {
    img {
      height: 150px;
      width: 100%;
      object-fit: cover;
    }
  }

  .hub-card__details {
    text-align: center;
    padding: 20px 10px 0;
    border-radius: 0;

    .hub-name {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 3px;
    }

    .hub-country,
    .hub-members__count {
      color: #5d5d5d;
      font-size: 12px;
      font-weight: 400;
    }
  }
}