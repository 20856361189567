.edit-photo-body{
  height: 300px;
  background-color: black;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.img-container{
  border-radius: 50%;
  height: 156px;
  width: 156px;
  & img{
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
  }
}

.photo-file{
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
}