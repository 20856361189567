.people-list {
  border-radius: 3.6px;
  border: 1px solid var(--new-faded-white);
  .user-avartar {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
  h6 {
    font-size: 0.65rem;
    color: var(--helper-text-color);
  }
  .summary {
    font-size: 0.7rem;
    color: var(--h-1-black-new);
  }

  .people-interests {
    flex-wrap: wrap;
    padding: 1rem 0.8rem 0;

    span {
      font-size: 0.85rem;
      font-weight: 600;
      color: var(--helper-text-color);
    }
  }
  .interests {
    border-radius: 1.2rem;
    border: solid 1px var(--helper-text-color);
    font-size: 0.7rem;
    padding: 4px 10px;
    margin: 0 0.6rem 0.7rem 0;
    line-height: 1.43;
    letter-spacing: 0.2px;
    color: var(--h1-black-new);
    justify-content: center;
    align-items: center;
  }

  .chat-icon {
    border: 1px solid var(--helper-text-color);
    color: var(--helper-text-color);
    border-radius: 3px;
    padding: 0 0.4rem;
  }

  .connected {
    background-color: var(--tef-red);
    color: var(--white);
    font-weight: bold;
    padding: 0.2rem 0;
    border-radius: 3px;
    font-size: 0.8rem;
    cursor: pointer;
    margin: 0 auto;
  }

  .pending {
    background-color: var(--helper-text-color);
    color: var(--white);
    font-weight: bold;
    padding: 0.2rem 0;
    border-radius: 3px;
    font-size: 0.8rem;
    cursor: pointer;
    margin: 0 auto;
  }

  .connect {
    background-color: var(--white);
    border: 1px solid var(--tef-red);
    color: var(--tef-red);
    font-weight: bold;
    padding: 0.2rem 0;
    border-radius: 3px;
    font-size: 0.8rem;
    margin: 0 auto;
  }
}
