.CurrentStage {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.CurrentStageContainers {
    display: flex;
    justify-content: flex-end;
}