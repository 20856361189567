.group-list {
  border-radius: 3.6px;
  background-color: var(--white);
  border: 1px solid var(--new-faded-white);

  &__image {
    height: 140px;
    width: 40%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2px;
    }
  }

  &__group-category {
    color: var(--helper-text-color);
    font-size: 12px;
    font-weight: 500;
  }

  h1 {
    line-height: 1.57;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
  }
}


  .mutual-count {
    color: var(--helper-text-color);
    border: 1px solid var(--new-faded-white);
    border-radius: 50%;
    font-size: 8px;
    width: 14px;
    height: 14px;
  }

  .joined {
    background-color: var(--tef-red);
    color: var(--white);
    font-weight: bold;
    padding: 0.6rem 0rem;
    border-radius: 3px;
    font-size: 0.8rem;
    cursor: pointer;
    margin: 0 auto;
  }

  .invite-sent {
    background-color: var(--helper-text-color);
    color: var(--white);
    font-weight: bold;
    padding: 0.6rem 0rem;
    border-radius: 3px;
    font-size: 0.8rem;
    cursor: pointer;
    margin: 0 auto;
  }

.user-avartar {
  width: 16px;
  height: 16px;
  object-fit: cover;
  border-radius: 50%;
}

.group-connections {
  color: var(--helper-text-color);
  font-size: 10px;
}
