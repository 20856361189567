.container {
  padding: 0px 5% 24px 5%;
  // border-bottom: 1px solid #d7d7d9;
}
.statusCard {
  background: #f9fbfd 0% 0% no-repeat padding-box;
  border: 1px solid #919ba833;
  border-radius: 15px;
  opacity: 1;
  padding: 14px 17px;
  & p {
    text-align: left;
    font: normal normal normal 15px/22px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  & a {
    text-align: left;
    font: normal normal bold 15px/35px Poppins;
    letter-spacing: 0px;
    color: var(--tef-red);
    opacity: 1;
    cursor: pointer;
  }
}
