.bankdetailsinput::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bdc3cc;
  font-family: "Poppins";
  font-size: 12px;
}
.bankdetailsinput::-moz-placeholder {
  /* Firefox 19+ */
  color: #bdc3cc;
  font-family: "Poppins";
  font-size: 12px;
}
.bankdetailsinput:-ms-input-placeholder {
  /* IE 10+ */
  color: #bdc3cc;
  font-family: "Poppins";
  font-size: 12px;
}
.bankdetailsinput:-moz-placeholder {
  /* Firefox 18- */
  color: #bdc3cc;
  font-family: "Poppins";
  font-size: 12px;
}

.bankdetailsinput {
  font-family: "Poppins";
}

.formgroupcontainer p {
  font-size: 12px;
  font-weight: 600;
}
