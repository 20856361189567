.reset-password-div {
  border: none;
  border-radius: unset;
  box-shadow: unset;
}

.message-0 {
  font-size: 18px;
  text-transform: capitalize;
}
.c_e_c {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width:768px) {
    justify-content: center;
    padding: 0 12px;
  }
}
