.alumniHub {
  display: flex;

  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;

  & > * {
    margin-left: 20px;
  }

  &__title {
    padding: 10px 10px 0px 10px;
    font-weight: bold;
    text-transform: uppercase;

    color: #fff;
    display: inline-block;
    font-size: 40px;
    line-height: 70px;
    border-radius: 10px;
    width: 30%;
    text-align: right;
    text-transform: capitalize;
    text-align: right;
    margin-right: 10px;

    & p {
      font-weight: bold;
    }
  }

  &__meta-box {
    color: #fff;
    display: flex;
    padding: 15px;
    justify-content: flex-end;
    width: 40%;
  }

  &__meta-box:last-child {
    margin-right: 10px;
  }

  &__icon {
    margin-right: 10px;
  }

  .alumniHub__main-content {
    position: relative;
  }

  &__left-side {
    position: absolute;
    width: 30%;
  }

  &__profile {
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -90px;
    margin-left: 5px;

    border-radius: 10px;
    padding: 10px;
  }

  &__profile-wrapper {
  }

  &__image-box {
    position: absolute;
    top: -30px;
  }

  &__detail-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 50px;
  }

  &__user-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
}

.tef-alumni-count {
  color: #919ba8;
}

.user--name {
  font-size: 20px;
  font-weight: bold;
}

.user--position {
  font-size: 15px;
}

.user--year {
  font-size: 13px;
  color: #b8bfc7;
}

.hub-name {
  color: #b8bfc7;
}

.profile--link {
  color: #d42316;
  font-size: 13px;
  margin-bottom: 10px;
}

.alumniHub__total {
  align-self: start;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.alumni-count {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alumniHub__total--first {
  border-right: 1.5px solid rgb(239, 239, 239);
  margin-right: auto;
  padding-right: 7px;
}

.hub-figure {
  color: #d42316;
  font-weight: bold;
}

.hub-name {
  font-size: 13px;
}

.alumniHub__imageCollection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.img-hub {
  width: 100%;
  object-fit: cover;
  height: 80px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.alumnHub__main-welcome {
  display: flex;
  margin-bottom: 20px;
}

.hub-photo-title {
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
}

.alumniHub__welcome {
  width: 45%;
  margin-right: 20px;
  padding: 15px;
  background-color: #da291c;
  border-radius: 10px;

  &--title {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 15px;
    color: #fff;
  }

  &--para {
    color: #fff;
    font-size: 15px;
    font-weight: 200;
  }
}

.alumniHub__user--item {
  width: 65%;
  border-radius: 15px;
}

.alumniHub__user--item {
  background-color: #fff;
  padding: 10px;
  // align-self: flex-start;
}

.hub-photo-radius {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.alumniHub--list {
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
}

.hub__more {
  font-size: 15px !important;
  line-height: 18px;
}

.hub-box__title {
  font-size: 14px !important;
  color: #26292d;
  line-height: 16px;
  text-transform: capitalize;
  font-weight: medium;
}

.hub-box__time {
  font-size: 12px !important;
  line-height: 14px;
  color: #919ba8;
}

.hub__user-items {
  display: flex;
  flex-wrap: wrap;
}

.user-item--list {
  width: 50%;
}

.event-box__title {
  font-size: 13px;
}

.event-box__posted,
.event-box__time {
  font-size: 13px;
  color: #919ba8;
  margin-bottom: 0px;
}

.event-box__time {
  font-size: 13px;
  color: #919ba8;
  margin-top: 5px;
}

.alumniHub-event {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
}

.hubEvent__upcoming-event {
  display: flex;
  flex-wrap: wrap;
}

.event-box-upcoming-list {
  width: 48%;
  background-color: #F4F5F6;
  margin-left: 10px;
  align-items: center;
}

.event-box-upcoming-list img {
  width: 100%;
}

.alumni-box {
  border-bottom: 1px solid rgb(244, 245, 246);
}

@media screen and (max-width: 790px) {
  .alumni {
    border-radius: none;
  }

  .alumni__title {
    width: 100%;
    background: none;
  }

  .alumni__discription {
    width: 100%;
  }

  .alumni > * {
    margin: 10px;
  }

  .alumni__main-content {
    margin-left: 0px;
    margin-right: 0px;
  }

}

.btn-grey {
  background: #e2e6ea linear-gradient( 180deg, #e6eaed, #e2e6ea) repeat-x;
}
