.StoryBanner:hover {
    cursor: pointer;
    filter: grayscale(0.7);
    transform: scale(1.03);
    transition-property: filter transform;
    transition-duration: 0.03s;
    transition-timing-function: ease-in;
}

.Shade {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    z-index: 50;
    top: 2px;
    right: 2px;
    left: 2px;
    bottom: 2px;
    border-radius: 0.5rem;
    display: none;
    margin: 0 1px;
    cursor: pointer
}

.Shade:hover {
    display: block;
}

.Bookmarked {
    color: #da291c;
}