.NotificationNav {
  flex: 0 0 30%;
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > * {
    margin: 0 5px;
  }
  &__icon {
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #eee;
      transition: all 0.5s ease-in;
    }
  }
  &__account {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    color: var(--h-1-black-new);
    text-transform: capitalize;
    & span {
      margin-right: 4px;
    }
  }
  & a {
    text-decoration: none;
    color: var(--h-1-black-new);
    &:hover {
      color: var(--tef-red);
    }
  }
  &__iconActive {
    fill: var(--tef-red);
  }
  &__iconInActive {
    fill: var(--h-1-black-new);
    &:hover {
      fill: var(--tef-red);
    }
  }
  &__iconHide {
    @media screen and (max-width: "768px") {
      display: none;
    }
  }
}

.accountIcon {
  font-family: Poppins;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  color: var(--h-1-black-new);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    background-color: #eee;
    color: var(--tef-red);
  }
}

a,
a:hover {
  text-decoration: none;
}

.NotificationContainer {
  height: 70vh;
  overflow-y: scroll;
}

// .NotificationHeading {
//     border-bottom: 1px solid #000 !important;
//     font-weight: 600 !important;
//     padding: 1rem !important;
// }
@media screen and (max-width: "425px") {
  .NotificationNav {
    // display: none;
  }
}

@media screen and (max-width: "600px") {
  .NotificationNav {
    flex: 0 0 13%;
    &__accountProfileText {
      display: none;
    }
  }
}

@media screen and (min-width: "601px") {
  .NotificationNav {
    &__accountProfile {
      display: none;
    }
  }
}

.iconCon {
  display: flex;
}

@media screen and (min-width: "768px") {
  .gt1 {
    display: block;
  }
}
