.dashboard-card {
  // max-width: 100%;
  border-radius: 4px;
  border: solid 1px #f1f1f5;
  box-shadow: 0 2px 1px 1px rgba(#000, 0.15);
  transition: transform 0.3s;
  background: white;
  margin: 10px 5.5px;
  height: 400px;
  position: relative;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  &:hover {
    transform: translateY(-3px);
  }
}

.dashboard-card-image {
  height: 40%;

  & > .dashboard-card-img {
    height: 100%;

    img {
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .dashboard-card {
    margin: 20px auto;
  }
}

.dashboard-card-details {
  border-radius: 3px;
  padding: 10px 10px 10px 20px;
  text-transform: capitalize;
  height: 40%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  h5 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #171725;
  }

  h6,
  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: grey;
  }
}

.dashboard-card-actions {
  height: 20%;
  border-top: 1px solid #bebec8;
  display: flex;
  padding: 10px 15px;
  flex-direction: column;
  justify-content: space-between;

  button {
    flex: 1;
    border-radius: 4px;
    background-color: var(--new-faded-white);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: var(--tef-grey);
    text-transform: uppercase;
    border: 0;
    padding: 10px;

    &:hover {
      background-color: var(--tef-red);
      color: white;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  .dashboard-card-like-share {
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
  }
}

.dashboard-icons {
  cursor: pointer;
  color: white;
}

.dashboard-icons:hover,
.dashboard-icons:focus {
  color: red;
}

.programme-share-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  transition: all;
  z-index: 1;

  &__share-icon {
    height: 40px;
    width: 40px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    &:hover {
      cursor: pointer;
    }
    &:not(:first-child) {
      margin: 5px 0px;
    }
  }

  .actual-share-icon {
    background: white;
    transition: ease-out 1s all;
  }
}
