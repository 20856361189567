.form-instruction {
  padding: 9px 55px 12px 31px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__image {
    width: 96px;
    height: 94px;
    object-fit: contain;
    margin-right: 19px;
  }

  &__instruction {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.4px;
    color: var(--h-1-black-new);
  }
}

.form-div {
  max-width: 90%;
  margin: 15px auto;

  & .community-form-div {
    display: flex;
    flex-direction: column;
    margin-bottom: 21px;

    &__label {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.2px;
      color: var(--h-1-black-new);

      & span {
        font-weight: normal;
        color: var(--helper-text-color);
      }
    }

    &__input-div {
      flex: 0 0 100%;
      display: flex;
      align-items: center;

      & .community-form-input {
        width: 100%;
        height: 36px;
        border-radius: 3px;
        box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21),
          inset 0 0 0 1px rgba(102, 113, 123, 0.25);
        background-color: var(--white);
        border: 0;
        padding-left: 10px;

        &::placeholder {
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.11px;
          color: var(--helper-text-color);
        }
      }

      & .community-form-label {
        font-family: Poppins;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.17px;
        text-align: right;
        color: var(--tef-grey);
        height: 36px;
        margin-bottom: 0;
        margin-left: -40px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
      }

      & .community-form-textarea {
        padding-left: 10px;
        padding-top: 8px;
        width: 100%;
        height: 96px;
        border-radius: 4px;
        box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21),
          inset 0 0 0 1px rgba(102, 113, 123, 0.25);
        border: 0;
        background-color: var(--white);

        &::-webkit-input-placeholder {
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: 0.2px;
          color: var(--helper-text-color);
        }
      }

      & .community-form-input:focus,
      & .community-form-textarea:focus {
        outline: 0;
      }
    }
  }
}

.community-buttons {
  height: 74px;
  border-top: 1px solid grey;
  padding-left: 5%;
  padding-right: 5%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & .community-button {
    width: 113px;
    height: 38px;
    border: 0;
    text-transform: capitalize;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1),
      inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      outline: none;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  & .community-cancel-button {
    background-image: linear-gradient(to top, #f6f7f9, var(--white));
    color: #3e3f42;
  }

  & .community-create-button {
    background-image: linear-gradient(to bottom, var(--tef-red), #cf1d10);
    color: var(--white);
  }
}

@media screen and (max-width: 600px) {
  .form-instruction {
    flex-direction: column;
  }
}

.s_group_preview_img_c {
  height: 144px;
  width: 144px;
  // margin: auto;
}

.s_group_preview_img {
  width: 100%;
  // aspect-ratio: 100%;
  object-fit: cover;
  object-position: top;
}
