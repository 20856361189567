.publish-btn {
  width: 30%;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.1),
    inset 0 1px 0 1px rgba(255, 255, 255, 0.06);
  border: solid 1px var(--tef-red);
  background-image: linear-gradient(to bottom, var(--tef-red), #cf1d10);
  padding-top: 7px;
  padding-bottom: 9px;
  margin-top: 0.5rem;
  font-family: SFProText;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: var(--white);
  transition: all 0.2s;
}
