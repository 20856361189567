.all-members-display-pic {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.member-display-area {
  height: 200px;
  overflow-y: auto;

  -ms-overflow-style: none;
  // scrollbar-width: none;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.all-members-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;

  &:hover {
    background-color: var(--very-light-blue);
  }
  .all-members-list-name {
    margin-left: 10px;
    font-family: Poppins;
    text-transform: capitalize;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #171725;
  }
}

.all-members-list-name-admin {
  font-family: Poppins;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.2px;
  color: var(--helper-text-color);
  text-align: center;
}

.members-toggler-C {
  display: flex;
  flex-wrap: wrap;
  & * {
    margin-right: 10px !important;
  }
}
.membeers-toggler-C__active {
  color: var(--tef-red) !important;
  border-bottom: 1px solid var(--tef-red);
}
