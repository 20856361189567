.title-container {
  margin-top: 40px;

  &__greetings-text {
    font-weight: 400;
    color: #888;
  }

  &__header-text {
   font-weight: 400;
   color: #555;
   font-size: 15px;
 }
}

.body-container {
  padding-left: 10px;
  margin-top: 30px;

  p {
    font-size: 11px;
    color: #777;
  }
}