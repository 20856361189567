.container {
  margin: 10px 0;
  display: flex;
  // align-items:  center;
  cursor: pointer;
  // background-color: red;
}
.inputContainer {
  margin-left: 10px;
  display: flex;
  border: 1px solid #919ba833;
  border-radius: 25px;
  padding: 5px 10px;
  justify-content: center;
}
.input {
  flex: 1;
}
.inputOption {
  align-self: flex-end !important;
}
.shareCommentBtn {
  width: 110px;
  border-radius: 25px;
  background-color: var((--tef-red));
  float: right;
}

.addCommentTextBtn {
  text-align: left;
  font: normal normal medium 14px/22px Poppins;
  letter-spacing: 0px;
  color: #6e6e70;
  opacity: 0.8;
  cursor: pointer;
  // margin-top: auto !important;
  // display: flex;
  // margin-bottom: 5px  !important;
}
