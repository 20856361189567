.NoDocumentsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5em;
    flex-direction: column;
}
  
.NoDocumentsContainer h6{
    line-height: 2em;
}
  
.NoDocuments {
    max-width: 100%;
    height: auto;
}