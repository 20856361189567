.banner-wrapper {
  // height: 35.2rem;
  // object-fit: contain;
  //   mix-blend-mode: darken;
  background-color: #fff;
  // margin-top: 27px;
  border-radius: 4px;
  margin: 27px 4px 16px 17px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // min-height: 100vh;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  align-items: center;
  display: flex;
  background: #152337;
  color: white;
  justify-content: center;
  // align-content: center;

  &--text-container {
    padding: 27px 30px 27px 39px;
  }

  ul > li {
    // display: inline-block;
    &:not(:last-child) {
      margin-right: 17px;
    }
    // color: var(--helper-text-color);
    font-size: 16px;
  }
}

.dot {
  background-color: var(--tef-grey);
  height: 5px;
  width: 5px;
  display: inline;
}

.section-rating {
  color: var(--helper-text-color);
  font-size: 13px;
  font-family: Poppins;
  display: flex;
  justify-content: first baseline;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    &:first-child {
      min-width: 25%;
    }
    justify-content: flex-start;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &--figure {
    // color: #ffb319;
  }
  .value {
    color: #fff;
  }
}

.section-paragrapgh {
  font-size: 14px;
  // color: var(--tef-grey);
  margin-top: 8px;
  //   min-width: 50.5rem;
}

.section-banner-header {
  // margin-top: 26px;
  &--text {
    font-size: 28px;
    font-stretch: narrower;
    // line-height: 1.57;
    letter-spacing: 0.2px;
    font-family: Poppins;
    font-weight: bold;
    &__red {
      color: var(--tef-red);
    }
  }

  & p {
    text-align: left;
    font: normal normal normal 15px/23px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
  }
}

.section-btn {
  margin-top: 20px;
  display: flex;
  align-items: center;
  align-content: space-between;
  &:first-child {
    margin-right: 5px;
  }
}

.section-button-info {
  margin-top: 21px;
  // margin-bottom: 4.8rem;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  // color: var(--tef-grey);
  &--bold {
    font-weight: bold;
  }
}

.section-banner-image {
  // width: 25rem;
  // height: 25rem;
  padding: 10px 0px;
  & img {
    // align-self: center;
    object-fit: cover;
    object-position: top;
    border-radius: 5px;
  }
}
.banner-image {
  max-width: 100%;
  // height: 35.2rem;
}

.training-course-rating {
  // top: 7px;
  // color: #ffb319;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rr {
  outline: none !important;
}

.rrc {
  background-color: var(--new-faded-white);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  // color: ;
  text-align: center;
}

.rr-button {
  display: flex;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  border: 1px solid var(--helper-text-color);
  cursor: pointer;
  text-align: center;
  // color:var(--new-faded-white);
}

.section-rating--figure {
  margin-left: 5px;
}

.course-rate-container {
  display: flex;
  flex: 1;
  width: 100%;

  & > * {
    &:first-child {
      margin-right: 5px;
    }
  }
}
