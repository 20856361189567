.mentorship-h1 {
  height: 26px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: 0.2px;
  color: var(--h1-black-new);
  padding: 21px 0 18px 31px;
}

.score-card {
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--new-faded-white);
  margin: 16px 0;
}
