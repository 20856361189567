.shade {
  // height: 10rem;
  // width: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 50;
  top: 2px;
  right: 2px;
  left: 2px;
  bottom: 2px;
  border-radius: 0.5rem;
  display: none;
  margin: 0 1px;
  // height: 500px;

  // display: none;
}
.LMSCourseCard {
  /* display: flex; */
  /* flex-direction: column; */
  /* box-shadow: 0 2px 4px 0 rgba(145, 155, 168, 0.06); */
  /* box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04); */
  background-color: var(--white);
  width: 15rem;
  /* height: 16rem; */
  // margin-top: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in;
  border: 1px solid var(--new-faded-white);
  border-radius: 0.5rem;
  margin: 0.2rem;
  // height: max-content;

  &:hover {
    .shade {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.LMSCourseCard:hover {
  transform: scale(1.05);
}

.CardWithoutImage {
  padding: 0.5rem;
}

.Logo {
  //   flex: 0 0 50%;
  width: 100%;
  height: 9.8rem;
  object-fit: scale-down;
  object-position: 50% 50%;
  border: 0.5px solid var(--new-faded-white);
  // padding: 2px;
  border-radius: 0.5rem;
  background-size: cover;
}

.logoContainer {
  height: 10rem;
  width: 100%;
  position: relative;
  padding: 2px;
}

.Title {
  // color: var(--tef-red);
  margin-top: -7px;
  font-weight: 600;
  font-size: 1rem;
  word-wrap: break-word;
  text-transform: capitalize;
  // margin-bottom: 1rem;
}

.percentageCompletedTextC {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
}
.percentageCompletedText {
  /* color: #2a3136; */
  font-size: 1.2rem;
  // margin-top: 5px;
  margin-bottom: 10px;
  // color: var(--helper-text-color);
  // font-weight: 300;
  color: #73726c;
  line-height: 1;
}

@media (max-width: 576px) {
  /* .LMSCourseCard {
        width: 50%;
    } */
}

.LMSList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 150px;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  // height: 100vh;
  // min-height: 50rem;

  @media only screen and (max-width: 529px) {
    justify-content: center;
  }
}

.card_content_below {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  /* flex:1; */
}
// @media only screen and (max-width:500px){

// }

.pBar {
  height: 3px;
}

.pBTN {
  background-image: linear-gradient(
    to right,
    #02aab0 0%,
    #00cdac 51%,
    #02aab0 100%
  );
}

.pBTNGrad {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.pBTNGrad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
