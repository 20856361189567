.mentor-availabilty-container {
  padding: 10px;
  width: 100%;
  background-color: white;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);

  &__heading {
    font-family: Poppins;
    margin-left: 10px;
    margin-top: 20px;
    & h1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.08;
      letter-spacing: 0.2px;
      color: var(--h1-black-new);
    }
    & span {
      font-size: 12px;
      color: #637381;
    }
  }
}

.available-days-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.days-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  color: #637381;
}

.available-day-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #637381;
  padding: 5px 10px;
  border-radius: 18px;
  margin: 5px 5px;
  &:hover {
    cursor: pointer;
    // background-color: var(--tef-red);
    // color: #ffffff !important;
    // border-color: var(--tef-red) ;
  }
  & span {
    font-family: Poppins;
    font-size: 12px;
  }
}
.selected-day {
  background-color: var(--tef-red);
  color: #ffffff !important;
  border-color: var(--tef-red);
}
.submit-box {
  margin-top: 35px;
  width: 100%;
  // background-color: #da291c;
}
.submit-btn {
  margin: 0px 10px;
  // background-color: #da291c;
  // background-color: #da291c;
  color: white;
  padding: 8px 0px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.1),
    inset 0 1px 0 1px rgba(255, 255, 255, 0.06);
  border: solid 1px #da291c;
  background-image: linear-gradient(to bottom, #da291c, #cf1d10);
  &:hover {
    opacity: 0.5;
  }
  text-align: center;
  font-family: SFProText;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
