// @media only screen and (min-width: 992px) {
.discussions-input {
  margin: 39px 0 0 11px;
  background-color: white;
}
.chat-name {
  height: 16px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--h1-black-new);
  margin: 5px 0 0 5px;
}

.chat-body {
  height: 100px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: var(--h1-black-new);
  margin: 0 0 20px 5px;
  padding: 5px;
}

// }

.chat-container {
  display: block;
  // padding:11px;
  // margin-left: 11px;
  // margin: 5px 0px;
  width: 100%;
  &__chat-item {
    display: flex;
    padding: 5px;
    background-color: white;
  }
}

.image-container {
  // flex:1;
  // border-radius: ;
  margin-right: 20px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.content-container {
  // flex-grow: 1;
  display: flex;
  flex-direction: column;
  & h1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: var(--h1-black-new);
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    // flex:1
    margin-top: 5px;
    & span {
      border-radius: 8px;
      background-color: #eaf5f1;
      font-family: Poppins;
      font-size: 10px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.2px;
      text-align: center;
      color: var(--pre-green);
      padding: 3px 10px;
    }
  }
  & p {
    // flex: 1;
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.2px;
    color: var(--h1-black-new);
  }
}

.like-reply-container {
  display: flex;
  // justify-content: space-between;
  font-family: Poppins;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.2px;
  color: var(--h1-black-new);
  flex-wrap: wrap;
}

.like-reply-icon {
  color: var(--helper-text-color);
  margin-top: -2px;
  cursor: pointer;
}
.like,
.reply {
  // flex:1
  margin-right: 36px;
}
.discussion-date {
  color: var(--helper-text-color);
}
.reply-container {
  // background-color: orange;
  display: block;
  margin: 20px 0px;
  width: 100%;
  border-top: 1px solid #f2f2f3;
  padding: 11px;
  &__input {
    & h6 {
      font-family: Poppins;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.2px;
      color: var(--h1-black-new);
    }
  }
  // background-color: var(--white);
  &__replies {
    margin: 15px;
    max-height: 500px;
    overflow-y: auto;
  }
}

.placeholder-image {
  // height: 100%;
  // width: 100%;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: hotpink;
  background: hotpink;
  color: var(--white);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  flex: 1;
}

.chat-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
