.training-widget-container {
  //   margin: auto 0;
  //   display: flex;
  //   justify-content: center;
  //   align-content: center;
  //   flex-direction: column;
  //   align-items: center;
  // flex: 1;
  //   padding: 20rem;
}

.custom-dropdown {
  font-family: SFProText;
  font-size: 12px;
  line-height: 0.86;
  color: var(--tef-grey);
  box-shadow: 0 0 2px 0 rgba(51, 65, 80, 0.03),
    0 2px 2px 0 rgba(51, 65, 80, 0.03);
  background-color: var(--white);
  height: 36px;
  // max-width: 200px;
}

.section-helpful-links {
  color: var(--helper-text-color);
  font-size: 13px;
  font-family: Poppins;
  opacity: 0.7;
  margin-top: 22px;

  & > * {
    display: inline-block;
    margin-top: 14px;
    &:not(:last-child) {
      margin-right: 14px;
    }
    // &:not(:nth-child(2)) {
    //     padding-top: .2rem;
    //   }
    &::nth-child(2) {
      margin-top: -5px;
    }
  }
  &--figure {
    color: #ffb319;
  }
}
