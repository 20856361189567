.threadsMain {
  max-height: 50vh;
  border-radius: 4px;
  padding: 5px 10px 10px 10px;
  background-color: var(--white);
  margin-bottom: 35px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
}

.table {
  margin-top: 10px;
  text-align: left;
  position: relative;

  & tr:not(:last-child) {
    border-bottom: 1px solid var(--very-light-blue);
  }

  & thead {
    & tr {
      border-bottom: 1px solid var(--very-light-blue);
    }

    tr > th {
      cursor: pointer;

      &:not(:first-child) {
        text-align: center;
      }

      &:last-child {
        width: 10%;
        text-align: center;
      }
    }
  }

  & tbody {
    tr > td {
      padding: 15px 0 15px 10px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-transform: capitalize;
      text-align: left;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      color: #545454;
      cursor: pointer;

      &:last-child {
        width: 10%;
        text-align: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.tableCategory {
  margin-top: 10px;
  font-style: italic;
  color: #545454;
  font-size: 10px;
  cursor: pointer;
  width: max-content;
}

.threadsMainLoader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftArrow {
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  bottom: 50%;
  top: 50%;
  position: sticky;
  color: white;
  cursor: pointer;
  float: left;
}

.rightArrow {
  background: rgba(0, 0, 0, 0.5);
  right: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  bottom: 50%;
  top: 50%;
  position: sticky;
  color: white;
  cursor: pointer;
  float: right;
}
.loadingMore {
  position: absolute;
  margin-bottom: 0;
  left: 50%;
  right: 50%;
  background: rgba(0, 0, 0, 0.5);
  height: 50px;
  width: 50px;
}
