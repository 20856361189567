.step-three-page-container {
  &__banner {
    height: max-content;
    min-height: 282px;
    background-image: url("../../../assets/img/onboading-banner-image.png");
    background-position: top;
    background-size: cover;
    width: 100%;
    padding: 100px 139px 65px 139px;
    color: var(--white);

    // display: flex;
    // flex-direction: column;
    & h1 {
      font-family: Poppins;
      font-size: 48px;
      font-weight: bold;
      letter-spacing: 0.2px;
    }
  }

  &__section {
    background: var(--white);
    justify-content: center;
    padding-bottom: 100px;
    // display: flex;
    &--content {
      padding-left: 139px;
      padding-right: 300px;
      // margin-left: 139px;
      // margin-right: 300px;
      position: relative;
      display: block;

      // display: flex;
      //  flex-wrap: wrap;

      // background-color: blue;
      &__search-form {
        position: absolute;
        // padding-left: 139px;
        // padding-right: 300px;
        // background-color: green;
        left: 139px;
        right: 300px;
        // height: 100px;
        // left:0px;
        // right: 0px;
        top: -77px;
        // top:-130px;
        z-index: 1;
        // margin-right: 195px;
        height: 100px;
        display: block;
        margin: 0 auto;
        // border-radius: 6px;
        // border: solid 2px var(--new-faded-white);
        // background-color: var(--white);
      }
      &__main {
        padding-top: 100px;

        & h1 {
          font-family: Poppins;
          font-size: 28px;
          font-weight: 500;
          color: var(--h1-black-new);
          margin-bottom: 18px;
          margin-left: 10px;
        }
        &--items {
          display: flex;
          flex-wrap: wrap;
          margin-left: 10px;
          // margin-top: 20px;
          justify-content: stretch;
          align-content: center;
          &-item {
            margin-top: 20px;
            margin-right: 15px;
            display: flex;
            flex-direction: column;
            border: solid 1px var(--new-faded-white);
            background-color: var(--white);
            width: 226px;
            // height: 241px;
            border-radius: 6px;
            justify-content: space-between;
            & h1 {
              font-family: Poppins;
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0.26px;
              text-align: center;
              color: var(--h1-black-new);
              margin-top: 10px;
            }
            & span {
              font-family: Poppins;
              font-size: 14px;
              letter-spacing: 0.1px;
              text-align: center;
              color: var(--tef-grey);
            }
            // padding: 0px 46px;
          }
        }
      }
    }
  }
}

.item-add {
  padding: 22px 67px;
  &__outter {
    width: 92px;
    height: 92px;
    opacity: 0.6;
    border: solid 0.7px var(--helper-text-color);
    border-radius: 100%;
    padding: 8px;
    &--inner {
      width: 74px;
      height: 74px;
      background-color: #ced2d8;
      border-radius: 100%;
    }
  }
}
.select-btn {
  align-self: center;
  width: 134px;
  height: 36px;
  margin: 20px 0px;
}

.form-height {
  height: 100px;
  font-size: 30px;
}

@media only screen and (max-width: 1420px) {
  .step-three-page-container {
    &__banner {
    }
    &__section {
      &--content {
        &__search-form {
        }
        &__main {
          &--items {
            align-content: space-between;
            justify-content: space-between;
            &-item {
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1181px) {
  .step-three-page-container {
    &__banner {
    }
    &__section {
      &--content {
        padding-left: 10px;
        padding-right: 10px;
        &__search-form {
          left: 10px;
          right: 10px;
        }
        &__main {
          &--items {
            align-content: space-between;
            justify-content: space-between;
            &-item {
              width: 215px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .step-three-page-container {
    &__banner {
    }
    &__section {
      &--content {
        &__search-form {
        }
        &__main {
          &--items {
            align-content: space-between;
            justify-content: space-between;
            &-item {
              width: 215px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .step-three-page-container {
    &__banner {
      padding: 100px 10px 65px 30px;
    }
    &__section {
      &--content {
        padding-left: 10px;
        padding-right: 10px;
        // display:block;
        // margin:0 auto;
        &__search-form {
          left: 10px;
          right: 10px;
        }
        &__main {
          &--items {
            align-content: space-between;
            justify-content: space-between;
            &-item {
              width: 215px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 725px) {
  .step-three-page-container {
    &__banner {
      padding: 100px 10px 65px 30px;
    }
    &__section {
      &--content {
        padding-left: 10px;
        padding-right: 10px;
        // display:block;
        // margin:0 auto;
        &__search-form {
          left: 10px;
          right: 10px;
        }
        &__main {
          &--items {
            align-content: space-between;
            justify-content: stretch;
            &-item {
              width: 200px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .step-three-page-container {
    &__section {
      &--content {
        &__search-form {
          top: -47px;
          // top: -75px;
        }
        &__main {
          padding-top: 50px;
          & h1 {
            font-size: 15px;
          }
          &--items {
            // align-items:
            align-content: space-between;
            justify-content: center;
            &-item {
              margin-right: 0px;
              width: 226px;
            }
          }
        }
      }
    }
  }

  .form-height {
    height: 50px;
    font-size: 12px;
    & .form-type {
      // &::placeholder{
      //   font-size: 12px;
      // }
      font-size: 12px;
    }
  }

  .pad-inner-center {
    // margin: 0 auto;
    padding: 0px 2px 0px 25px;
  }
}

.item-bg {
  border-radius: 8px 8px 0px 0px;
  background-image: radial-gradient(circle at 50% 0, #435774, #293a51 69%);
  padding: 22px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;
}

.input {
  position: relative;
}
.suggestion-box {
  // margin-top:-8px;
  position: absolute;
  // width: 100%;
  left: 0;
  right: 0;
  top: 70px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(54, 57, 60, 0.27);
  border: solid 1px #c4cdd5;
  background-color: var(--white);
  min-height: 36px;
  // margin: 0px 50px;
  z-index: 1;
  display: flex;
  padding: 8px 12px;
}
.added-topics-items {
  display: flex;
  flex-wrap: wrap;
  &__item {
    margin: 2px;
    border-radius: 30px;
    border: solid 0.5px var(--helper-text-color);
    padding: 4px 12px;
    display: flex;
    justify-content: space-around;
    align-content: space-between;
    font-size: 10px;
    font-family: Poppins;
  }
}
.backSkipButtons {
  font-family: SFProText;
  line-height: 1.43;
  letter-spacing: normal;
  // color: #637381;
  color: rgb(252, 244, 244);
  cursor: pointer;
  font-weight: bold;
  // margin-top: 50px;
}
