.CommentContainer {
    display: flex;
    margin: 1.2rem 0;
}

.CommentBody {
    flex: 0 0 95%;
}

.CommentFlag {
    flex: 1;
}