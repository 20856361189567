.event-list {
  border-radius: 3.6px;
  border: 1px solid var(--new-faded-white);

  .event-month {
    font-size: 0.7rem;
    color: var(--helper-text-color);
    line-height: 1.57;
    text-transform: uppercase;
    font-weight: bold;
  }

  .event-date {
    font-size: 0.65rem;
    color: var(--helper-text-color);
    font-weight: 500;
  }

  .user-avartar,
  .mutual-count {
    width: 22px;
    height: 22px;
    object-fit: cover;
    border-radius: 50%;
  }
}
