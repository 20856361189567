.SideDrawer {
  background-color: var(--white);
  width: 280px;
  max-width: 70%;
  height: 100%;
  z-index: 2000;
  top: 0;
  left: 0;
  position: fixed;
  padding: 20px 0;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;

  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__search {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: -16%;
  }

  &__navTitle {
    margin-top: 20px;
    padding-left: 22px;
    padding-top: 22px;
    padding-bottom: 22px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.93px;
    color: var(--blue-grey);
  }
}

.SideDrawerOpen {
  transform: translateX(0);
}

.SideDrawerHide {
  transform: translateX(-100%);
}

@media screen and (min-width: "769px") {
  .SideDrawer {
    display: none;
  }
}
.bContainer {
  display: flex;
  // min-height:50vh;
  flex-direction: column;
  background: #eeeeef 0% 0% no-repeat padding-box;
  justify-content: flex-end;
  padding: 5px;
  margin-top: auto;
  margin-bottom: 0;
  padding-bottom: 50px;

  .nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;

    // position: absolute;
    // bottom: 0;
    // height: 100%;
    // flex:1;

    & > * {
      margin: 0 5px;
    }
  }
}

.mainLinkText {
  margin-right: 15px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.93px;
  color: var(--blue-grey);

  &:hover {
    color: var(--tef-red);
  }
}
