.RightSideCardContainer {
    width: 300px;
    height: 131px;
    border-radius: 6px;
    border: solid 1px var(--new-faded-white);
    background-color: rgba(247, 248, 251, 0.3);
    margin-top: 1rem;
    padding: 1.5rem 1rem;
}

.CardTitle {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
    margin-bottom: 1rem;
}

.CardContent {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.2px;
    color: var(--tef-grey);
}

@media(max-width: 480px) {
    .RightSideCardContainer {
        width: 100%;
        margin-top: 1rem;
    }
}