.comment-form-div {
  width: 100%;
  display: flex;
  margin-right: 13px;

  & img {
    width: 36px;
    height: 36px;
    margin-right: 22px;
  }

  & form {
    width: 100%;
    height: 40px;
  }
}

.comment-input {
  width: 60%;
  height: inherit;
  outline: none;
  padding: 5px;
  margin-left: 13px;
  border-radius: 4px;
  border: 1px solid grey;

  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
}

.comment-button {
  width: 20%;
  height: inherit;
  margin-left: 5%;
  border-radius: 4.2px;
  box-shadow: 0 6px 13px 0 rgba(82, 223, 169, 0.13);
  background-image: linear-gradient(to bottom, var(--tef-red), #cf1d10);
  font-family: SFProText;
  font-size: 14.7px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
  text-transform: capitalize;
  border: 0;
  transition: all 0.2s;

  &:active {
    transform: translateY(2px);
  }

  &:focus {
    outline: none;
  }
}

// tablet
@media (min-width: 768px) {
  .comment-input {
    width: 65%;
  }
  .comment-button {
    width: 20%;
  }
}

// desktop
@media (min-width: 1024px) {
  .comment-input {
    width: 80%;
  }

  .comment-button {
    width: 10%;
  }
}
