.PageHeader {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
    font-size: 1.5rem;
}

.TopRow {
    background-color: var(--white);
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
}

.TopRowLeft {
    flex: 0 0 40%;
}

.TopRowRight {
    flex: 1;
    padding-left: 1.5rem;
}

.HeaderText {
    font-size: 1.8rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
    margin-bottom: 2rem;
}

.HeaderTextRed {
    color: var(--tef-red);
    font-size: 1.8rem;
}

.GeneralButton {
    border-radius: 0.25rem;
    text-align: center;
    padding: 0.5rem;
    font-size: 0.8rem;
    margin-right: 0.5rem;
    cursor: pointer;
}

.RedButton {
    background-color: var(--tef-red);
    color: var(--white);
}

.OutlineButton {
    border: solid 1px var(--helper-text-color);
    background-color: var(--white);
}

.CtaButtons {
    display: flex;
}

.BottomRow {
    margin-top: 1rem;
    box-sizing: border-box;
    display: flex;
}

.CategoriesListColumn {
    border-radius: 4px;
    background-color: var(--white);
    padding: 2rem 0.5rem;
    box-sizing: border-box;
    flex: 0 0 20%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    /* height: 70rem; */
}

.Category {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.56;
    letter-spacing: normal;
    color: var(--tef-grey);
    cursor: pointer;
    list-style-type: none;
    /* display: block;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.Category:hover,
.Category:active {
    color: var(--tef-red);
}

.SelectedCategory {
    color: var(--tef-red);
    font-weight: 600;
}

.ToolTableTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Label {
    background-color: white;
    padding: 0.5rem;
}

.NoRightPad {
    padding-right: 0;
}

.NoOutline {
    outline: none;
    border: none;
}

.ToolCards {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.BigScreen {
    display: none;
}

.SmallScreen {
    display: inherit;
}

@media (max-width: 480px) {
    .MainContent {
        margin: 0.5rem 1rem;
    }
    .TopRow {
        display: flex;
        flex-direction: column;
    }
    .TopRowLeft {
        margin-bottom: 1rem;
    }
    .TopRowRight {
        display: none;
    }
    .CategoriesListColumn {
        display: none;
    }
    .BigScreen {
        display: inherit;
    }
    .SmallScreen {
        display: none;
    }
    .ToolTableTop {
        max-width: 100%;
    }
    .Label {
        width: 35%;
        font-size: 12px;
    }
    .Label>select {
        width: 100%;
    }
    .ToolsInCategory {
        font-size: 12px;
    }
}