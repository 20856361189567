.mobile-chat-list {
    display: none;
} 
.desktop-chat-list {
    display: block;
}
.msg_box {
    display:flex;
    align-items: center;
}
.msg_badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 8px;
    padding: 8px;
    margin-left: 5px
}

@media screen and (max-width: 790px) {
    .chat-wrapper {
        max-height: 100vh;
        border: none;
        background: #fff;
        overflow: auto !important;
    
    }
  .mobile-chat-body {
    display: none;
  }
  .ChatList_chat-tab__content {
      height: 100%;
  }

  .mobile-chat-list {
      display: block;
  }
  .desktop-chat-list {
    display: none;
}

}
