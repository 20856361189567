.delete-a-btn{
  background-color:rgb(252, 244, 244);
  padding: 5px 10px;
  border-radius: 15px;
  margin:0 3px;
  color: rgb(250, 115, 115) !important;
  cursor: pointer;
}

.delete-a-btn-r{
  background-color:var(--new-faded-white);
  padding: 5px 10px;
  border-radius: 15px;
  margin:0 3px;
  color: rgb(250, 115, 115) !important;
  cursor: pointer;
}

.a-d-confirm-btn{
  font-size: 13px;
  color: var(--helper-text-color);
}

.t{
  @media only screen and (max-width:500px){
    flex-direction: column !important;
    align-items: center;
    // & * > {
    //   margin-left: 0;
    // }
  }
}