.section-nav-container .nav-item {
  margin-right: 1.5em;
  cursor: pointer;
}

.section-nav-container .nav-link {
  padding: 0px;
  padding-bottom: 0.5rem;
  font-weight: 700;
}
