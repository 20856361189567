.InputComment {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    border: solid 1px var(--new-faded-white);
    background-color: var(--white);
    padding: .5rem;
    margin-bottom: 2rem;
    width: 78%;
}

.UserImage {
    border-radius: 50%;
    flex: 0 0 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.UserImage img {
    max-width: 80%;
    max-height: 80%;
}

.UserInput {
    flex: 0 0 72%;
}

.commentForm::placeholder {
    font-size: 0.7rem;
}

.UserInput textarea {
    width: 90%;
    border: none;
    outline: none;
}

.UserInput textarea:focus:active {
    border: none;
    outline: none;
}

.ShareButton input {
    max-width: 80%;
}

.Disabled {
    pointer-events: none;
    /* for "disabled" effect */
    opacity: 0.5;
    /* background: #CCC; */
}