.contactCard {
  display: flex;
  height: 70px;
  padding: 8px 10px;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--pale-lilac);
  }

  &:hover {
    background-color: #f4f4f5f2;
  }

  &__dp {
    width: 35px;
    height: 35px;
    object-fit: contain;
    border-radius: 50%;
  }

  &__details {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  &__nameInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__h3,
  &__message {
    text-transform: capitalize;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #000;
  }

  &__h3 {
    word-break: break-all;
  }

  &__message {
    font-size: xx-small;
    font-weight: normal;
  }
}

.active {
  border-left: 3px solid var(--tef-red);
  background-color: #f4f4f5f2;
}
