.ViewComment {
    display: flex;
    flex-direction: column;
}

.UserInfo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.UserImage {
    flex: 0 0 8%;
}

.UserImage img {
    border-radius: 50%;
    max-width: 80%;
    max-height: 80%;
}

.UserName {
    /* flex: 0 0 50%; */
    margin-right: 1.5rem;
    font-weight: 600;
}

.UserType {
    /* flex: 1; */
    color: var(--pre-green);
    border-radius: 0.5rem;
    background-color: #eaf5f1;
    padding: .1rem;
}

.Comment {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    flex: 0 0 62%
}

.CommentBody {
    margin-bottom: .65rem;
}

.UserDetails {
    display: flex;
    justify-content: flex-start;
}

.CommentFlag {
    flex: 0 0 8%;
    cursor: pointer;
}

@media(min-width: 768px) {
    .Comment {
        display: flex;
        flex-direction: column;
    }
}