.more-options {
  width: 200px;
  border-radius: 4px;
  border: solid 1px #c7c7c7;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  padding: 5px 0;

  position: absolute;
  z-index: 100;
  right: 20px;

  &__btn {
    padding-left: 15px;
    width: 100%;
    height: 40px;
    border: 0;
    background-color: inherit;
    transition: all .2;

    &:first-child{
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    display: flex;
    align-items: center;

    &:hover {
      background-color: #eef3ff;
    }

    &:active {
      transform: translateY(2px);
    }

    &:focus {
      outline: none;
    }

    &--span {
      margin-left: 8px;
    }
  }
}

.hide-options {
  display: none;
}
