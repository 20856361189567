.declaration-content {
  max-width: 700px;
  margin: 20px auto;
  font-size: 14px;
  min-height: 100vh;
}

.declaration-h6 {
  display: flex;
  align-items: center;
  font-family: SFProText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #637381;
}

.declaration-h1 {
  font-family: SFProText;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #26292d;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.declaration-h3 {
  font-family: SFProText;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #63666a;
  padding-bottom: 43px;
}

.declaration-main-content {
  border-radius: 6px;
  background-color: #ffffff;
  padding: 15px 58px 0px 29px;

  &__instruction {
    font-weight: bold;
    margin-bottom: 30px;
  }
}

.radio-toolbar {
  margin: 3px;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
}

.radio-toolbar span {
  display: block;
  background-color: #fff;
  padding: 5px 15px;
  font-size: 16px;
  border: 1px solid #da291c;
  border-radius: 4px;
}

.radio-toolbar span:hover {
background-color: #da291c;
opacity: .8;
color: #fff;

}

.radio-toolbar input[type="radio"]:checked + span {
  background-color: #da291c;
  color: #fff;
}

.declaration-button-content {
  padding: 0;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 85%;
    height: 46.8px;
    border-radius: 3.9px;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.1), inset 0 1px 0 1px rgba(255, 255, 255, 0.06);
    border: solid 1.3px #da291c;
    background-image: linear-gradient(to bottom, #da291c, #cf1d10);
    font-family: SFProText;
    font-size: 18.2px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: uppercase;
    transition: all .2s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: none;
    }
    &:active {
      transform: translateY(2px);
    }
  }

  button:hover {
    opacity: 0.42;
  }
}

.btn-font{
  font-size: 12px;
}

.submit-declaration-btn{
  padding: 5.5px 10px;
  color:white;
  margin-left: 15px;
  border-radius: 5px;
  background-color: var(--tef-red) !important;
}

.declaration-btn-holder{
  background-color: white;
  padding: 27px 29px;
  margin: 0px 4px;
}

@media only screen and (max-width:466px){
  .submit-declaration-btn{
    margin-left: 0;
    margin-top: 10px;
  }
}