.SideDrawer {
  background-color: var(--white);
  width: 280px;
  max-width: 70%;
  height: 100%;
  z-index: 2000;
  top: 0;
  left: 0;
  position: fixed;
  padding-top: 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;

  display: flex;
  flex-direction: column;

  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__search {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__number {
    align-self: start;
    width: 27.8px;
    height: 27.8px;
    border: solid 3.2px #feefef;
    background-image: linear-gradient(to bottom, #ef533c, #da291c);
    border-radius: 50%;
    font-family: Oxygen;
    font-size: 12.8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__countdownTimer {
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(45, 51, 58, 0.16);
    background-color: #da291c;
    display: flex;
    justify-content: center;
    align-items: center;

    & h3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60px;
      font-family: SFProText;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      & span {
        font-family: SFProText;
        font-size: 6px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
      }
    }
  }

  &__nav {
    flex: 1;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & ul {
      margin-left: 15px;
      padding-left: 0;
    }

    & ul > li {
      list-style: none;
    }
  }

  &__sideHeading {
    font-family: SFProText;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #26292d;
    text-transform: uppercase;
  }

  &__topicName {
    margin-left: 15px;
    font-family: Poppins;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #da291c;
  }

  &__sideActive {
    color: #da291c;
    font-weight: 600;
    font-size: 16px;
  }

  &__subTopics {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  &__LogoutContainer {
    text-align: center;
    margin-bottom: 20px;
  }

  &__logoutLink {
    color: var(--tef-red);
    font-size: 20px !important;
    letter-spacing: 2px !important;
    text-transform: uppercase;
  }
}

.SideDrawerOpen {
  transform: translateX(0);
}

.SideDrawerHide {
  transform: translateX(-100%);
}

@media screen and (min-width: "769px") {
  .SideDrawer {
    display: none;
  }
}
