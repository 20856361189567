.ViewComment{
  display: flex;
  flex-direction: column;
}
.UserInfo{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.UserImage{
  flex: 0 0 5%;
}
.UserImage img{
  border-radius: 50%;
  max-width: 80%;
  max-height: 80%;
}

.CommentBody {
  font-size: 16px;
  color: rgba(0,0,0,0.7);
}
.UserName{
  /* flex: 0 0 50%; */
  margin-right: 1.5rem;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 14px;
}
.UserNameRate{
  /* flex: 0 0 50%; */
  margin-right: 1.5rem;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 14px;
  margin-right: auto;
  align-self: flex-start;
}
.UserType{
  /* flex: 1; */
  color: var(--pre-green);
  border-radius: 0.5rem;
  background-color: #eaf5f1;
  padding: .1rem;
}
.Comment{
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  flex: 1
}
.CommentBody{
  margin-bottom: .65rem;
}
.UserDetails{
  display: flex;
  justify-content: flex-start;
}
.CommentStarRate{
  display: flex;
  align-items: center;
}

@media(min-width: 768px){
  .Comment{
    display: flex;
    flex-direction: column;
  }
}