.surveyHome{
  padding: 20px;
  background-color: rgb(242, 242, 243);
  border-radius: 25px;
}
.questionItem{
  background-color: var(--new-faded-white);
  padding: 10px;
  // justify-content: center;
}
.stepCounter{
  margin-top: 15px;
  margin-right: 5px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  color: var(--helper-text-color);
}