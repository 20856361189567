.rbc-btn-group {
  display: none;
}

.rbc-toolbar {
  font-family: Poppins;
  height: 57px;
  background-color: #222c3c;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--white);
}

.rbc-btn-group:first-child {
  display: block;
  margin-left: 5%;
}

.rbc-btn-group:first-child > button:first-child {
  display: none;
}

.rbc-btn-group:first-child > button:nth-child(2) {
  background-color: #313c4d;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  color: transparent;
  position: relative;
}

.rbc-btn-group:first-child > button:last-child {
  position: absolute;
  right: 5%;
  background-color: #313c4d;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  color: transparent;
}

.rbc-btn-group:first-child > button:last-child::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 12px solid #ced0da;
  border-bottom: 6px solid transparent;
  position: absolute;
  right: 22%;
  top: 33%;
}

.rbc-btn-group:first-child > button:nth-child(2)::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-right: 12px solid #ced0da;
  border-bottom: 6px solid transparent;
  position: absolute;
  left: 26.2%;
  top: 30.4%;
}

.rbc-btn-group:first-child > button:last-child:active {
  outline: none;
  border: none;
}

.rbc-date-cell > a {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #354052;
  pointer-events: none;
}

.rbc-row-segment {
  background-color: rgba(27, 185, 52, 0.1);
  border-left: 2px solid rgba(38, 154, 243);
}

.rbc-event {
  background-color: transparent;
}

.rbc-event-content {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #354052;
}

.rbc-event.rbc-selected {
  background-color: var(--tef-red);
  color: var(--white) !important;
}

.rbc-show-more {
  pointer-events: none;
}

.rbc-today {
  background-color: rgba(255, 120, 0, 0.1);
  border-right: 1px solid #ff7800;
}
