// .container {
// }

.post {
  &__form {
    display: flex;
    // justify-content: space-between;
    margin-top: 15px;
    margin-bottom: -30px;
    align-items: flex-start;

    & .img {
      margin-right: 20px;
    }
  }
}

.buttons {
  & button {
    width: 111px;
    background: var(--tef-red) !important;
  }
}

.options {
  display: flex;
  justify-content: flex-end !important;
  // margin: ;
  border-top: 1px solid #d8d8dd;
  padding: 10px 0;
  margin-top: auto;
  margin-bottom: 16px;
}

.attachF {
  transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  margin: 0 20px;
  cursor: pointer;
  color: #6e6e70;
}

.textField {
  min-height: 101px;
}

.container_dd {
  display: flex;
  flex-direction: column;
  & button {
    background: var(--tef-red) !important;
    // margin-bottom: -20px;
    align-self: flex-end;
    width: 111px;
  }
}

.dropArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  cursor: pointer;
  height: inherit;
  // margin-bottom: 5px;

  & span {
    font: normal normal 600 14px/19px Poppins;
    letter-spacing: 0px;
    color: #6e6e70;
    .format {
      font: normal normal 600 12px/19px Poppins;
      letter-spacing: 0px;
      color: #6e6e70;
    }
    &:nth-child(3) {
      color: var(--tef-red);
    }
  }
}

.activeDrag {
  border: 1px dotted #6e6e70;
  background-color: rgb(240, 230, 230);
  opacity: 0.5;
}
