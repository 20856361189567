.NewsBanner:hover {
    cursor: pointer;
    filter: grayscale(0.7);
    transform: scale(1.03);
    transition-property: filter transform;
    transition-duration: 0.03s;
    transition-timing-function: ease-in;
}

.Bookmarked {
    color: #da291c;
}

.ArticleBanner {
    flex: 0 0 30%;
}

.ArticlePoster {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TruncateText {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}