.about-item {
  & > hr {
    margin-top: 0px;
  }
  & > .row {
    // height: 58px;
  }
  &__details > h3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.2px;
    color: #3e3f42;
    margin-left: 20px;
  }

  &__details > p {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin-left: 20px;
    color: var(--helper-text-color);
  }
  &__logo {
    height: 64px;
    width: 64px;
    border-radius: 4px;
  }
}

.info {
  font-family: Roboto;
  font-size: 12px;
  margin-left: 20px;
  color: var(--helper-text-color);
}
.a-t {
  position: relative;
}

.op-fab {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.19);
  background-color: #feffff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
}

.op-dd {
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.19);
  background-color: #feffff;
  margin-top: 5px;
  & ul {
    padding: 5px 0px;
    list-style-type: none;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    & > li {
      padding: 0px 10px;
      &:hover {
        background-color: #fdebea;
        cursor: pointer;
      }
    }
  }
  // width: 100%;
}

.op-fab-c {
  right: 5px;
  top: -5px;
  position: absolute;
  z-index: 1;
  max-width: max-content;
  // box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.19);
  // background-color: #feffff;
  display: flex;
  flex-direction: column;
}

.op-dd-c {
  position: relative;
}
