.hub-details__wrapper {
  background: #e9ecef;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
  .hub-details__description-title {
    border-bottom: 1px solid #e1d2d2;
    margin-bottom: 8px;
    h5 {
      font-size: 20px;
      font-weight: 600;
    }
  }
}