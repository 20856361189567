.MasterclassUser {
  display: flex;
}

.Username {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.Position {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
}

.UserText {
  margin-left: 0.5rem;
}
