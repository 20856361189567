$signinbtn_max_width: 333px;

.container {
  display: flex;
  flex-direction: column;
}
.headerContainer {
  display: flex;
  padding: 24px 6%;
  align-items: center;
  position: relative;
}

.logo_container {
  flex-grow: 2;
  flex-shrink: 1;
}
.menuIcon {
  border: none !important;
  display: none;
  justify-content: flex-end;
  cursor: pointer;
  margin-left: 10px;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}
.menu_container {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
}

.aboutbtn,
.signinbtn,
.signupbtn,
.mobile_signin_btn,
.mobile_signup_btn {
  border: 1px solid #6e6e70;
  background-color: transparent;
  border-radius: 5px;
  text-align: center;
  padding: 10px 15px;
  min-width: 100px;
  margin-left: 20px;
  font: normal normal bold 14px/16px Poppins;
  // width: 152px;
  // & a {
  //   font: normal normal bolder 14px/16px Poppins;
  // }
}

.aboutbtn {
  border: none !important;
  position: relative;
  // &:hover {
  //   .aboutMenu_dp {
  //     display: flex;
  //   }
  // }
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.signupbtn {
  background-color: var(--tef-red) !important;
  color: white;
  border: none !important;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.signinbtn {
  @media only screen and (max-width: $signinbtn_max_width) {
    display: none;
  }
}

.tef_logo {
  height: 30px;
}

.aboutMenu_dp {
  background-color: white;
  position: absolute;
  width: 30vw;
  z-index: 1;
  top: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000024;
  border-radius: 5px;
  padding: 24px 10%;
  // display: none;
  // &:hover {
  //   display: flex;
  // }

  .menu_title {
    text-align: left;
    font: normal normal bold 17px/35px Poppins;
  }

  @media only screen and (max-width: 768px) {
    width: calc(100vw / 1.2);
    // margin-left: -70%;
    top: -25px;
    // right: 0;
    left: 0;
  }
}

.menu_list {
  list-style-type: none;
  text-align: left;
  & li {
    font-weight: bold;
    & a {
      text-align: left;
      font: normal normal 600 14px Poppins;
      color: var(--tef-red);
    }
  }
}

.footerContainer {
  padding: 77px 6% 23px 6%;
  background: #2c333c 0% 0% no-repeat padding-box;
  // align-self: flex-end;
  color: #6e6e70;
  bottom: 0;
  position: sticky;
}

.footerTop {
  // display: flex;
}

.hr {
  border-top: 1px solid #6e6e70;
}
.footer_logo {
  // height: 50px;
  width: 59%;
  margin-bottom: 31px;
}

.footer_title {
  text-align: left;
  font: normal normal 900 36px/54px Poppins;
  color: #ffffff;
  // margin-bottom: ;
}

.footer_menu_list {
  list-style-type: none;
  text-align: left;

  & li {
    font: normal normal medium 14px/20px Poppins;
    font-weight: bold;

    & a {
      font: normal normal medium 14px/20px Poppins;
      font-weight: bold;
      color: #6e6e70;
      &:hover {
        color: white;
      }
    }
  }
}

.social_icon_list {
  list-style-type: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
  color: white;

  & li {
    color: white;
    & a {
      color: white;
    }
  }
}

// .f_logo_container {
//   height: 50px;
//   margin-bottom: 31px;
// }

.f_p {
  text-align: left;
  font: normal normal medium 14px/20px Poppins;
  letter-spacing: 0px;
  color: #6e6e70;
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    // justify-content: space-between;
    align-items: flex-start;
  }
}

.f_bottom_p {
  text-align: left;
  font: normal normal bold 12px/20px Poppins;
  letter-spacing: 0px;
  color: #6e6e70;
}

.f_bottom_list {
  list-style-type: none;
  text-align: left;
  display: flex;
  justify-content: space-between;

  // flex-grow: 1;

  & li {
    font-weight: bold;
    color: #6e6e70;
    &:not(:first-child) {
      margin-left: 30px;
    }

    font: normal normal bold 12px/20px Poppins;
    & a {
      font-weight: bold;
      font: normal normal bold 12px/20px Poppins;
      color: #6e6e70;
    }
  }
}
.mobile_menu {
  @extend .menu_container;
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000024;
  right: 50px;
  // display: flex !important;
  flex-direction: column;
  justify-content: space-evenly !important;
  top: 75px;
  z-index: 999;
  width: max-content;
  align-content: flex-start !important;
  align-items: flex-start !important;
  padding: 20px;
  display: none;
  border-radius: 5px;

  @media only screen and (max-width: 768px) {
    display: flex !important;
    right: 10px;
  }
}

.mobile_signup_btn,
.mobile_signin_btn {
  margin-top: 20px;
}

// .mobile_signup_btn,
.mobile_signin_btn,
.mobile_aboutus_btn {
  align-self: flex-start;
  width: 152px;
}

.mobile_signup_btn {
  @extend .signupbtn;
  display: block;
  // width: 100%;
  // align-self: flex-start;
}

.mobile_signin_btn {
  // @extend .signinbtn;
  display: block;
  width: 100%;
  // display: flex;
  // justify-content: flex-start;
  @media only screen and (min-width: $signinbtn_max_width) {
    display: none;
  }
}
.mobile_aboutus_btn {
  @extend .aboutbtn;
  display: block;
  // width: 100%;
}

.back_btn {
  font-size: 16px;
  font-weight: bold;
  font-family: Poppins;
  text-align: left;
  margin-bottom: 50px;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    display: none;
  }
}
