.comment-content {
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  margin-left: 10px;

  h4 {
    font-size: 12px;
    font-weight: bold;
    color: #222;
  }

  h6 {
    font-size: 11px !important;
    font-weight: 500 !important;
    color: #666 !important;
    margin-left: 0px !important;
    margin-bottom: 10px !important;
  }
}
