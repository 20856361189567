.Button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 50%;
    border-radius: 0.25rem;
    border: solid 1px #d4d4d4;
    min-height: 1.875rem;
    margin-right: 0.75rem;
    padding: 0.25rem 1.2rem;
    cursor: pointer;
    transition: all 0.2s;
}

.Button:focus {
    outline: none;
}

.Button:active {
    transform: translateY(2px);
}

.Label {
    font-size: 0.7rem;
    margin-left:10px;
}

@media(min-width: 768px) {
    .Button {
        flex: 0 0 50%;
    }
}