.create-post {
  padding: 0px 23px 0px 20px;
  max-width: 100%;
  min-height: 196px;
  border-radius: 6px;
  background-color: var(--white);
  margin-bottom: 15px;

  &__top-actions {
    height: 64px;

    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__top-actions > div {
    height: 29.4px;
    border-radius: 105px;
    text-transform: capitalize;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 27px;
    font-size: 12px;
  }

  &__main-actions {
    height: 68px;
    display: flex;
    align-items: center;
  }

  &__post-container {
    height: inherit;
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__post-container > h3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 0.2px;
    color: var(--helper-text-color);
    margin-left: 10px;
  }

  &__main-actions > img {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    margin-right: 17px;
  }

  &__bottom-actions {
    height: 64px;
    border-radius: 6px;
    box-shadow: inset 0 1px 0 0 #f5f7fa;
    background-color: var(--white);

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__bottom-actions > .share-btn {
    max-width: 105px;
    width: 105px;
    height: 37px;
    border-radius: 4.2px;
    border: 0;
    box-shadow: 0 6px 13px 0 rgba(82, 223, 169, 0.13);
    background-image: linear-gradient(to bottom, var(--tef-red), #cf1d10);
    font-family: SFProText;
    font-size: 14.7px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
    text-transform: capitalize;
    transition: all 0.2s;

    &:active {
      transform: translateY(2px);
    }

    &:focus {
      outline: none;
    }
  }
}

.active-tab {
  background-color: var(--new-faded-white);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: var(--h-1-black-new);
  width: 70px;
}

.icons-div {
  display: flex;
}
.icons-div > span {
  margin-right: 20px;
}

.icon-view > svg {
  margin-right: 10px;
  margin-left: 24px;
}

.icons-div > span:first-child {
  transform: rotate(45deg);
}

.icon-view {
  display: flex;
  flex-basis: 49px;
  align-self: flex-end;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--tef-grey);
}

@media screen and (max-width: 375px) {
  .create-post {
    &__bottom-actions {
      margin-left: -15px;
    }

    &__top-actions {
      margin-left: -10px;
      justify-content: space-between;
    }

    &__top-actions > div {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 320px) {
  .create-post {
    &__bottom-actions {
      margin-left: -20px;
    }

    &__top-actions > div {
      font-size: 11px;
    }
  }
}
