.ReviewersCommentsButton {
    background-color: #CECECE !important;
    border: 1px solid transparent;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: center;
    padding: 0.375rem 0.75rem;
    font-size: 0.625rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ModalContainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 25vw;
    height: max-content !important;
    position: absolute;
    right: 0;
    margin: 0 !important;
}

.Content {
    margin-top: 30%;
    margin-left: 0.8em;
    margin-right: 0.8em;
}

.Content h6 {
    border-bottom: 2px solid #E8EAED;
    padding-bottom: 0.5em;
    font-weight: 600;
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* mobile phones, 500 and below */
@media (max-width: 500px) {
  .ModalContainer {
      width: 100vw;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}