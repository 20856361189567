.Content {
  flex-grow: 1;
  // display: flex;
  overflow: auto;

  &__smallTopText {
    margin-left: 5px;
    margin-top: -10px;
    font-family: Poppins;
    text-transform: capitalize;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    font-size: 13px;
  }

  &__mainContainer {
    width: 65%;
    margin-left: 15%;
    margin-top: 6rem;
    padding: 24px 24px 0 24px;
    overflow: scroll;
    position: relative;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__mainContainer_withoutSideRight {
    width: 85%;
    margin-left: 15%;
    margin-top: 6rem;
    padding: 24px 24px 0 24px;
    overflow: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__mainContainerTitle {
    padding-left: 24px;
    font-family: Poppins;
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #171725;
  }
}

@media screen and (max-width: 768px) {
  .Content {
    margin-top: 30px;
    &__mainContainer {
      padding: 10px 0;
      margin-left: 0 !important;
    }
  }

  .Content {
    &__mainContainer_withoutSideRight {
      width: 100%;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .Content {
    &__mainContainer {
      // flex: 1;
      margin-left: 20%;
      width: auto;
    }
  }
}

@media screen and (max-width: "1024px") {
  .Content {
    &__mainContainer {
      flex: 1;
    }
  }
}
