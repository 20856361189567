.stats-card {
  // width: calc(100% /3.3);
  width: 100%;
  height: 157px;
  border: none;
  border-radius: 6.3px;
  // box-shadow: 0 0 11px 0 #f0f0f0;
  box-shadow: 5px 19px 38px 0 rgba(0, 0, 0, 0.09);

  background-color: var(--white);
  margin-top: 28px;
  margin-right: 17px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
    backface-visibility: hidden;
    // transform: translateY(-2px);
    box-shadow: 0 23px 42px 0 rgba(0, 0, 0, 0.09);
  }
}
.stats-figure {
  margin-top: 41px;
  height: 34px;
  font-family: Poppins;
  font-size: 29.4px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: 0.12px;
  text-align: center;
  color: var(--dark);
}
.stats-text {
  height: 15px;
  font-family: Poppins;
  font-size: 12.6px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: var(--helper-text-color);
}
.stats-rect {
  width: 50px;
  height: 7px;
  border-radius: 3.7px;
  // background-color: var(--pre-green);
  margin: 2px auto;
}
