.create-question-div {
  display: flex;
}

.question-form {
  width: 80%;
  margin-left: 15px;
}

.add-field {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--tef-red);
  cursor: pointer;
}

.question-textarea {
  max-width: 92%;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.2px;
  color: var(--h-1-black-new);
}

.expiry-date-details {
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 92%;
  font-family: Poppins;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.2px;
  color: var(--h-1-black-new);

  display: flex;
  align-items: center;

  &__date {
    margin-right: 10px;
    font-weight: normal;
  }
}

@media only screen and (max-width: 375px) {
  .add-field {
    font-size: 12px;
  }
  .expiry-date-details {
    flex-direction: column;
    align-items: stretch;
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .question-textarea {
    max-width: 100%;
  }
}
