.training-report-c {
  padding: 2rem 5rem;
  background-color: #fff;
}
.i-div {
  margin: auto;
  display: flex;
  justify-content: center;
}

.report-body-c {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  & h1 {
    font-family: Poppins;
    font-size: 26px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.37px;
    text-align: center;
    color: #26292d;
  }

  & p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.18px;
    text-align: center;
    color: #63666a;
    text-align: center;
  }
}

.percent-c {
  color: #0db884;
}

.report-continue-btn {
  width: 50%;
  margin: auto;
}
