.TefRedButton {
  width: 90%;
  height: 100%;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 0.8rem;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #fafafb;
  text-transform: capitalize;
  transition: all 0.2s;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TefRedButton:focus {
  outline: none;
}

.TefRedButton:active {
  transform: translateY(2px);
}

@media (max-width: 480px) {
  .TefRedButton {
    width: 70%;
    margin-left: -0.5rem;
  }
}

@media (min-width: 768px) {
  .TefRedButton {
    width: 7rem;
    height: 2rem;
    font-size: 0.8rem;
  }
}

button {
  background-color: var(--tef-red);
}
