.container {
  // height: 100vh;
  // position: absolute;
  margin-bottom: 20px;
}

.createPostContainer {
  border-radius: 10px 10px 0 0;
  // height: 439px;
  // background: #da291c1a 0% 0% no-repeat padding-box;
}

.noticeBoard {
  width: 100%;
  // margin-left: 15%;
  background: transparent linear-gradient(90deg, #919ba8 0%, #f2f4f7 100%) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(90deg, #919ba8 0%, #f2f4f7 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  min-height: 265px;
  padding: 34px 3.7% 5px 3.7%;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 3px 20px #00000024;
  margin-bottom: 30px;
  @media only screen and (max-width:768px) {
    // margin: 0 auto;
    // margin-top: 30px;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    // margin-top: 30px;
  }
  @media only screen and (max-width:568px) {
    flex-direction: column;
  }

  &__banner {
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    // flex: 1;
    & button {
      max-width: 220px;
      margin-bottom: 45px;
    }
    & img {
      max-height: 144px;
      @media only screen and (max-width:568px) {
        align-self: flex-start;
      }
    }
  }
  &__info {
    flex: 2;
    display: flex;
    flex-direction: column;
    &_carouselIndicatorContainer {
      display: flex;
      margin-top: auto;
      margin-bottom: 10px;
      margin-left: auto;
    }
  }
}
.indicatorItem {
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.3;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &:not(:last-child) {
    margin-right: 10px;
  }
}
.carouselContent {
  & h4 {
    text-align: left;
    font: normal normal bold 25px/35px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
  }
  & p {
    text-align: left;
    font: normal normal normal 17px/26px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
  }
  & a {
    font: normal normal bold 17px/26px Poppins;
    color: var(--tef-blue);
  }
}

.postSection {
  margin-top: 24px;
  // margin-bottom: 150px;
  padding-bottom: 10px;
}
