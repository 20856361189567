.container {
  min-height: 241px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #919ba833;
  border-radius: 15px;
  opacity: 1;
  // position: absolute;
  // left: 0;
  // right: 0;
  // bottom: -100px;
  box-shadow: 0px 3px 20px #00000024;
  padding: 15px 30px;
  transition: all 1s ease-in;

  @media only screen and (max-width:768px) {
    padding: 12px 10px;
  }

  // @media only screen and (max-width:568px) {
  //   margin-top: 30px;
  // }
}

.optionContainer {
  display: flex;
  width: 100%;
  position: relative;
  &::before {
    content: "";
    background-color: #d8d8dd;
    height: 1px;
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 8px;
  }
  & h4 {
    text-align: left;
    font: normal normal 600 17px/26px Poppins;
    letter-spacing: 0px;
    // color: #000000;
    display: flex;
    transition: 0.2s ease-in all;
    padding: 10px 0;
    align-items: center;
    position: relative;
    opacity: 0.3;
    z-index: 2;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 33px;
    }
    border-bottom: 1px solid #d8d8dd;
    @media only screen and (max-width:768px) {
      font-size: 13px;
      &:not(:last-child) {
        margin-right: 11px;
      }
    }
  }
}

.optionIcon {
  margin-right: 5px;
  opacity: 0.5;
}
.activeOptionIcon {
  opacity: 1 !important;
}
.activeOption {
  border-bottom: 2px solid var(--tef-red) !important;
  opacity: 1 !important;
}
