.friends-group-main {
  // min-height: 434px;
  border-radius: 15px;
  background-color: var(--white);
  padding: 22px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;

  & .friends-group-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text {
      & .text-h1 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        color: #171725;
        text-transform: capitalize;
      }
      & .text-h3 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.4px;
        color: var(--blue-grey);
      }
    }
    &__link {
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      color: var(--tef-red);
      text-transform: uppercase;
    }
  }

  & .friends-group-cards {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;

    &__no-group {
      width: 100%;
      font-family: SFProText;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #90979c;
      text-align: center;
    }
  }

  & .friends-group-cards-loader {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
