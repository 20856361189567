.Template {
    height: 70vh;
    width: 100vw;
    background-color: #ffffff;
    padding: 1em 1.5em;
    overflow-y: scroll;
}

.Progress {
    height: 0.7em;
}

