.login_container {
  background-color: #f2f4f7;
  // // justify-content: space-between;
  height: 100vh;
  display: flex;
  @media only screen and (max-width:768px) {
    flex-direction: column-reverse;
  }
}

.formSection {
  padding: 48px 7%;
  flex: 1;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width:768px) {
    position: absolute;
    bottom: -500px;
    z-index: 999;
    left: 0;
    right: 0;
    padding: 0;
    // margin-top: -45px;

    // width: 100vw;
  }
}
.formContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000026;
  border-radius: 10px;
  padding: 36px 47px;
  // width: 642px;
  margin: 0 auto;

  @media only screen and (max-width:500px) {
    padding: 30px 25px;
  }
}
.logintitle {
  letter-spacing: 0px;
  color: #000;
  // margin-bottom: 10px;
  font: 900 36px/45px Poppins;
}
.signupHere {
  font: normal normal 900 15px/26px;
  letter-spacing: 0px;
  color: var(--tef-red);
  font: normal normal 900 15px/26px Avenir;
}
.formLabel {
  letter-spacing: 0px;
  color: #6e6e70;
  opacity: 1;
  font: normal normal medium 14px/24px Poppins;
}
.f_row {
  margin-bottom: 25px;
}
.newUser {
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 30px;
  font: normal normal medium 14px/26px Avenir;
}
.carouselContainer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  flex: 1.4;
  // padding: 2px 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  &::before {
    content: "";
    height: inherit;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.9) 100%
    );
    width: 100%;
    padding: 0 !important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media only screen and (max-width:768px) {
    position: relative;
    background-attachment: fixed;
  }
}

.carouselContentContainer {
  display: flex;
  // z-index: 999999;
  align-items: center;
  margin-top: auto;
  margin-bottom: 30px;
  justify-content: space-between !important;
  color: white;
  // width: 100%;
  position: relative;
  // &::after {
  //   content: "";
  //   height: 300px;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   width: 100%;
  //   padding: 0 !important;
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  // }
  margin-left: 5%;
  margin-right: 5%;

  @media only screen and (max-width:768px) {
    margin-bottom: auto;
    margin-top: 500px;
    // min-height: 550px;
    flex-direction: column;
    padding: 4px 4%;
    // justify-content: flex-start;
    // align-items: flex-start;
  }
}
.carouselContentContainer__text {
  flex: 1;
  & h5 {
    font: normal normal bolder 24px/24px Poppins;
  }
  & p {
    font: normal normal bolder 16px/24px Poppins;
    color: rgba(255, 255, 255, 0.807);
  }
}
.carouselIndicatorContainer {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
.carouselIndicator {
  height: 15px;
  width: 15px;
  background-color: rgba(169, 206, 229, 0.5);
  border-radius: 50%;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 10px;
  }
}
.activeCarousel {
  background-color: var(--tef-red) !important;
}

.logoContainter {
  margin-bottom: 70px;
  @media only screen and (max-width:768px) {
    display: none;
  }
}
.forgot_password_text {
  letter-spacing: 0px;
  color: var(--tef-red);
  opacity: 1;
  margin-bottom: 15px;
  font: normal normal 900 16px/26px Avenir;
}

.f_input {
  background: rgba(169, 206, 229, 0.3);
  &:focus {
    background: rgba(169, 206, 229, 0.3);
    outline: none;
  }
}

.loginBTN {
  width: 100%;
  background: var(--tef-red);
  margin-bottom: 15px;
}

.relativeField {
  position: relative;
}

.hr {
  overflow: hidden;
  text-align: center;
  margin-bottom: 15px;
}

.hideIcon {
  position: absolute;
  right: 10px;
  top: 40px;
  cursor: pointer;
}

.hr:before,
.hr:after {
  background-color: rgba(169, 206, 229, 0.5);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.hr:before {
  right: 0.5em;
  margin-left: -50%;
}

.hr:after {
  left: 0.5em;
  margin-right: -50%;
}

.tef_logo {
  height: 30px;
}

.fade {
  animation: fade 3s ease-in-out;
  transition: all;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.carouselSS {
  align-items: flex-start !important;
  // position: relative;
  margin-top: 10px;
  margin-bottom: auto;
  align-self: flex-end;
  justify-content: space-between !important;
  @media only screen and (min-width:768px) {
    display: none;
  }
}

.carouselBS {
  @media only screen and (max-width:768px) {
    display: none;
  }
}

.carouselBS,
.carouselSS {
  @extend .carouselContentContainer;
}
.tef_logo_mobile {
  // align-self: flex-start;
  // margin-left: -20px;
  // position: absolute;
  // top: 150px;
  // bottom: 0;
  margin-top: 5%;
  // margin-bottom: auto;

  @extend .tef_logo;
  @media only screen and (min-width:768px) {
    display: none;
  }
}

.logoContainter_mobile {
  position: fixed;
  top: -20px;
  left: 0;
  right: 0;
  margin-bottom: auto;
  margin-top: 30px;
  display: flex;
  flex: 1;
  padding: 0 6%;
}

.loginRedirect {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  &__inner {
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    background: white;
    display: flex;
    border-radius: 10px;
    padding: 5%;
    box-shadow: 5px 19px 38px 0 rgba(0, 0, 0, 0.08);

    & h4 {
      font-size: 1.2rem;
      color: var(--helper-text-color);
      text-align: center;
    }
  }
}

.passwordEye {
  opacity: 50%;
}
