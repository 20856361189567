.container {
  background: #ffffff 0% 0% no-repeat padding-box;
  // border: 1px solid #919BA833;
  border-radius: 15px;
  opacity: 1;
  padding: 15px 30px;
  box-shadow: 0 0 2px 0 rgba(51, 65, 80, 0.03),
    0 2px 2px 0 rgba(51, 65, 80, 0.03);
  // min-height: 350px;
  margin-bottom: 20px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;

  @media only screen and (max-width:768px) {
    padding: 12px 10px;
  }
}

.postHeaderContainer {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #919ba833;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  margin-bottom: 15px;

  &__avatarContainer {
    display: flex;
    align-items: center;
    flex: 1;

    &_left {
      margin-left: 15px;

      & h5 {
        text-align: left;
        font: normal normal 600 17px/26px Poppins;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        & a {
          text-align: left;
          font: normal normal 600 17px/26px Poppins;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
      }
    }
  }

  &__right {
    & span {
      text-align: left;
      font: normal normal normal 14px/26px Poppins;
      letter-spacing: 0px;
      color: #6e6e70;
      opacity: 1;
      flex: 1;
    }
  }
}
.menuButton {
  position: absolute;
  z-index: 1;
  right: 0;
  top: -10px;
  cursor: pointer;
}

.postBody {
  padding-bottom: 15px;
  border-bottom: 1px solid #919ba833;
}

.postContent {
  margin-bottom: 5px;
}
.actionBtnContainer {
  display: flex;
  // justify-content: space-between;
  width: 50%;
  padding: 15px 0;
  font: normal normal bold 15px/22px Poppins;
  color: #6e6e70;
  // margin-top: -20px;
  opacity: 0.9;
  @media only screen and (max-width:400px) {
    width: 100%;
  }

  & span {
    cursor: pointer;

    font-size: 16px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}
