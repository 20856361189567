.news_page_body {
  width: 100%;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  margin-top: -20px;
}

.news-nav-header {
  background-color: #eaecef;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.news-header-title {
  font-size: 12px;
  color: #333;
  font-weight: 600;
  margin-top: 13px;
}
