.photoMini {
  margin-top: 5%;
  &__bio {
    // margin: 0 20px;
  }
  &__pic {
    margin: 5% 0 0 0;
    // height: 90px;
    // width: 90px;
  }
}
.photoMini-h1 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  color: var(--h1-black-new);
  margin: 15px 20px 0 20px;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
}
.photoMini-span {
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: var(--tef-red);
  position: relative;
  top: 40%;
  cursor: pointer;
}
