.DataCollectionModal__h4 {
  letter-spacing: 0.2px;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: var(--tef-red);
}

.DataCollectionModal__p {
  letter-spacing: 0.2px;
  font-family: Poppins;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: var(--tef-grey);
  margin-bottom: 1rem;
}
