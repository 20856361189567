.ApplicationSidebar {
  width: 15%;
  padding: 24px 16px 0 16px;
  background-color: var(--white);
  border-right: 1px solid var(--pale-lilac);
  padding-top: 24px;
  height: 100vh;
  overflow: auto;
  position: fixed;
  left: 0;
  padding-bottom: 6rem;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__Logo {
    margin-bottom: 20px;
    width: 100%;
  }

  & ul {
    margin-left: 15px;
    padding-left: 0;
  }

  & ul > li {
    list-style: none;
  }

  &__number {
    align-self: start;
    width: 27.8px;
    height: 27.8px;
    border: solid 3.2px #feefef;
    background-image: linear-gradient(to bottom, #ef533c, #da291c);
    border-radius: 50%;
    font-family: Oxygen;
    font-size: 12.8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__topicName {
    margin-left: 15px;
    font-family: Poppins;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #da291c;
  }

  &__sideHeading {
    font-family: SFProText;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #26292d;
    text-transform: uppercase;
  }

  &__countdownTimer {
    height: 50px;
    margin-top: 8px;
    margin-bottom: 48px;
    border-radius: 4px;
    // box-shadow: 0 2px 4px 0 rgba(45, 51, 58, 0.16);
    // background-color: #da291c;
    display: flex;
    justify-content: center;
    align-items: center;

    & h3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60px;
      font-family: SFProText;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;

      & span {
        font-family: SFProText;
        font-size: 6px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
      }
    }
  }

  &__sideHeading {
    font-family: SFProText;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #26292d;
    text-transform: uppercase;
  }

  &__sideActive {
    color: #da291c;
    font-weight: 600;
    font-size: 16px;
  }

  &__subTopics {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  &__logoutLink {
    color: var(--tef-red);
    font-size: 16px !important;
    letter-spacing: 2px !important;
    text-transform: uppercase;

    &:hover {
      font-size: 18px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .ApplicationSidebar {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .ApplicationSidebar {
    width: 20%;
  }
}
