.edit-profile-header {
  width: 100%;
  & h1 {
    font-family: Poppins;
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 0.1px;
    font-weight: bold;
    color: var(--new-h1-black);
  }
}

.btn-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.group-btn {
  display: flex;
}

.btn-update {
  background-color: var(--tef-red);
  color: white;
  &:hover {
    // background-color: rgb(238, 143, 143);
    opacity: 0.5;
  }
}

.btn-choose-photo {
  border: 1px solid var(--tef-red);
  color: var(--tef-red);
  &:hover {
    background-color: var(--tef-red);
    color: white;
  }
}

.btn-update,
.btn-choose-photo,
.btn-cancel {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.17px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  margin: 3px;
  border-radius: 4.1px;
  &:hover {
    cursor: pointer;
  }
}

.edit-list-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 5px 0px;
  background-color: #fff;
  border-radius: 4px;

  font-family: Poppins;
  font-size: 15px;
  letter-spacing: 0.17px;
  color: var(--helper-text-color);
}
.edit-list-container {
  padding: 10px;
  background-color: rgb(236, 236, 236);
  max-height: 350px;
  overflow-y: auto;
}
.heading-h1 {
  font-family: Poppins;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.17px;
  padding: 5px;
}

.search-item-c {
  position: relative;
}

.new-item-c {
  display: flex;
  flex-direction: column;
  position: absolute;
  box-shadow: 0 2px 4px 0 rgba(54, 57, 60, 0.27);
  top: 75px;
  border: 1px solid var(--new-faded-white);
  width: 100%;
  padding: 8px 25px;
  border-radius: 6px;
  background-color: var(--white);
  z-index: 10;
  display: block;
  overflow-y: auto;
  // max-height: 200px;
  scroll-behavior: smooth;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.add-new {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
  letter-spacing: 0.2px;
  color: var(--tef-blue);
  &:hover {
    cursor: pointer;
  }
}
