.Item {
    display: flex;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.ItemImageContainer {
    height: 100%;
    flex: 0 0 40%;
    margin-right: 1rem;
}

.ItemImage {
    width: 100%;
    height: auto;
    object-position: 50% 50%;
    object-fit: contain;
}

.Details {
    display: flex;
    flex-direction: column;
}

.Title {
    text-transform: capitalize;
    text-align: left;
    color: rgb(38, 41, 45);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.Description {
    word-wrap: break-word;
}