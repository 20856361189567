.CustomSelect {
    margin-left: 1em;    
    width: 60%;
}

.CustomSelect select {
    border: none;
    background-color: #F1F1F1;
    padding: 0.2em;
    width: 100%;    
    font-size: 0.8rem;
    color: black;
    font-weight: 500;
}