.communities-categories-main {
  width: auto;
  border-radius: 4px;
  margin-bottom: 43px;
  padding-left: 20px;

  &__heading {
    // padding-left: 20px;
    & .text-h1 {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      color: #171725;
    }

    & .text-h3 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.4px;
      color: var(--blue-grey);
    }
  }

  &__main {
    flex: 1;
    height: 152px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
