.AppointmentsContainer {
    border-radius: 4px;
    border: solid 1px var(--new-faded-white);
    background-color: var(--white);
    /* height: 24rem; */
    margin-bottom: 2rem;
    padding: 1.5rem;
    padding-bottom: 0.5rem;
}

.MyAppointments {
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
    height: 40vh;
}

.AppointmentHeader {
    display: flex;
    padding: 1.5rem 2rem;
    align-items: center;
    height: 2.2rem;
    border-bottom: solid 1px var(--new-faded-white);
    box-sizing: border-box;
    justify-content: space-between;
}

.InvitationsHeader {
    display: flex;
}

.Sent {
    cursor: pointer;
}

.CalendarInvites,
.Sent {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: 0.15px;
    margin-right: 2rem;
    color: var(--h-1-black-new);
    /* flex: 0 0 70%; */
}

.CreateAppointment {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.2px;
    text-align: right;
    color: #0091ff;
    /* flex: 1; */
    cursor: pointer;
}

.ModalFooter {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
}

.OnlineEvent {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
}

.Active {
    color: var(--tef-red);
}

.ShowMore {
    display: flex;
    justify-content: center;
}