.Navigation {
  display: flex;
  flex-direction: column;
  &__navTitle {
    margin-top: 30px;
    padding-left: 22px;
    padding-top: 22px;
    padding-bottom: 22px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.93px;
    color: var(--blue-grey);
  }
}

.bContainer {
  display: flex;
  // min-height:50vh;
  flex-direction: column;
  background: #eeeeef 0% 0% no-repeat padding-box;
  justify-content: flex-end;
  padding: 5px;
  margin-top: auto;
  margin-bottom: 0;

  .nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;

    // position: absolute;
    // bottom: 0;
    // height: 100%;
    // flex:1;

    & > * {
      margin: 0 5px;
    }
  }
}

.mainLinkText {
  margin-right: 15px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.93px;
  color: var(--blue-grey);

  &:hover {
    color: var(--tef-red);
  }
}
