.dmscontainer {   
  min-height: 100vh;
}
.dmscontainer p, a, span {
  font-size: 14px;
  line-height: 1em;  
}

.dmscolone {
  background-color: #ffffff;
}

.dmscolone h2 {
  font-weight: 700;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* mobile phones, 500 and below */
@media (max-width: 500px) {
  .dmscolone {
    padding-bottom: 100px;
    margin-bottom: 2em;
  }

  .dmscoltwo {
    margin-bottom: 2em;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}



    