.training-main-content {
  margin-top: 14px;
}
.training-card {
  // display: flex;
  border-radius: 4px;
  width: 100%;
  min-height: 500px;
  //   background: var(--white);

  //   flex: 1;
  &__overview {
    background: var(--white);
    margin: 10px 19px 16px 17px;
    padding: 29px 40px 56px 24px;
    border: solid 1px var(--new-faded-white);
    height: 100%;
    &--section-header {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: "space-between";
      align-content: space-between;
      margin-bottom: 30px;
      &--icon {
        width: 16px;
        height: 16px;
        // flex: 1;
        // display: flex;
      }
      &--h1 {
        font-weight: 600;
        font-size: 20px;
        font-family: Poppins;
        color: var(--h1-black-new);
        flex: 2;
        display: flex;
        margin: -4px 0px 0px 22px;
      }
    }

    &--section-paragraph {
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: 0.2px;
      color: var(--h1-black-new);
      margin-bottom: 30px;

      & p {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        overflow-x: auto !important;
      }
    }

    &--section-list-item {
      font-family: Poppins;
      font-size: 14px;

      & h1 {
        font-weight: 600;
        font-size: 20px;
        color: var(--h1-black-new);
        // flex: 2;
        // display: flex;
        // margin: -4px 0px 0px 22px;
      }

      & > ul > li {
        margin-left: 15px;
        // list-style-type: none;
        color: var(--helper-text-color);
        &:nth-child(1) {
          margin-top: 30px;
        }
        &:not(:last-child) {
          margin-bottom: 30px;
        }
        @media only screen and (max-width:992px) {
          margin-left: 0;
        }
      }
    }
  }
}

.training-module {
  margin: 21px 19px 16px 17px;
  &--header {
    border-radius: 4px;
    background: var(--white);
    border: solid 1px var(--new-faded-white);
    padding: 8px 21px 8px 21px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-wrap: wrap;
    &__count-modules {
      font-weight: normal;
      color: var(--helper-text-color);
    }
    & h1 {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      //   font-stretch: normal;
      //   font-style: normal;
      //   line-height: normal;
      //   letter-spacing: normal;
      color: var(--dark);
      align-self: center;
    }
    &__link {
      font-family: Poppins;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      //   line-height: normal;
      letter-spacing: 0.2px;
      text-align: center;
      color: var(--helper-text-color);
      align-self: center;
      text-decoration: underline;
      &:hover {
        color: var(--tef-red);
      }
    }
    &__timer {
      display: flex;
      width: 179px;
      height: 32px;
      border-radius: 4px;
      border: solid 1px #eaecef;
      justify-content: space-evenly;
      align-content: center;
      align-items: center;

      & > div {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        text-align: center;
        color: var(--helper-text-color);
      }
    }
  }
  &__content {
    border-radius: 4px;
    margin-top: 6px;
    background: var(--white);
    padding: 24px 21px 34px 21px;
    border: solid 1px var(--new-faded-white);
    flex: 1;

    &--item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f8f9fb;
      border-radius: 8px;
      padding: 13px 20px 13px 20px;
      flex-wrap: wrap;
      flex: 1;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &:hover {
        background: var(--white);
        border: solid 1px var(--new-faded-white);
      }
      &-label {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        // background: #0db884;
        padding: 8px 10px;
        color: #eaecef;
        border-radius: 4.5px;
      }
      &-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        // color: red;
        align-self: flex-start;
        align-content: flex-start;
        & span {
          font-size: 10px;
          letter-spacing: 0.2px;
          color: var(--helper-text-color);
        }
      }
      & h6 {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.06;
        letter-spacing: 0.2px;
        color: var(--h1-black-new);
        word-wrap: break-word;
        text-align: left;
        align-self: flex-start;
      }
      &-timer {
        display: flex;
        justify-content: space-around;
        align-content: space-around;
      }

      &-lecture-count {
        display: flex;
        justify-content: space-between;
      }

      .span-content {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: var(--helper-text-color);
      }
    }
  }
}

// content right

.right-content {
  //   margin: 9px 4px 0 0;
  margin-top: 9px;
  width: 100%;
  &__top {
    background: var(--white);
    border: solid 1px var(--new-faded-white);
    // width: 100%;
    border-radius: 4px;
    padding: 24px 18px 24px 18px;
    display: flex;
    flex-direction: column;
    & > * {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    &--item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      & img {
        width: 25px;
        // flex:1;
        // display: flex;
        height: 25px;
        // margin: 0px 9px 0px 0px;
      }

      &--heading {
        //   margin: 0px 0px 0px 9px;
        align-self: flex-start;
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        & h6 {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: var(--h1-black-new);
          text-transform: capitalize;

          //   margin-top: 3px;
        }
        & span {
          font-family: Poppins;
          font-size: 9px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.18px;
          color: var(--helper-text-color);
        }
      }
    }
  }
  &__learning-resources {
    margin-top: 30px;
    margin-bottom: 12px;
    &--heading {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: var(--tef-grey);
      margin-bottom: 12px;
    }
    &--content {
      background: var(--white);
      border: solid 1px var(--new-faded-white);
      border-radius: 4px;
      padding: 19px;

      &--resources-type {
        margin-bottom: 50px;
        &--item {
          display: flex;
          // flex-wrap: wrap;
          //   justify-content: space-between;
          //   margin-top: 27px;
          &--img-container {
            height: 14px;
            width: 14px;
            flex: 0.5;
            margin-top: -4px;
          }
          & p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.1px;
            color: var(--tef-grey);
            flex: 5;
            align-content: stretch;
          }
        }
      }
      h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: var(--h1-black-new);
        & span {
          font-weight: normal;
          color: var(--helper-text-color);
        }
      }
    }
  }
}

.see-more {
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: var(--helper-text-color);
}

.faq {
  background: var(--white);
  margin: 10px 19px 16px 17px;
  //   padding: 29px 40px 56px 24px;
  padding: 30px;
  border: solid 1px var(--new-faded-white);
}

.free-resources {
  margin: 10px 19px 16px 17px;
  background: var(--white);
  height: max-content;
  padding: 39px 47px 39px 47px;
  & h6 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: var(--h1-black-new);
    margin-bottom: 28px;
    & span {
      font-weight: normal;
      color: var(--helper-text-color);
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // margin-bottom: 24px;
    &--icon {
      // flex: 1px;
      height: 14px;
      width: 14px;
      margin-top: -7px;
    }
    &--link {
      // flex-grow:1;
      flex: 1;
      margin-left: 8px;
      font-family: Poppins;
      font-size: 12px;
      line-height: 1.06;
      letter-spacing: 0.24px;
      color: #212b36;
    }

    &--size {
      font-family: Poppins;
      font-size: 12px;
      line-height: 1.06;
      letter-spacing: 0.24px;
      color: var(--helper-text-color);
      flex: 1;
    }
    &--download {
      display: flex;
      font-size: 12px;
      letter-spacing: normal;
      color: var(--helper-text-color);
      & img {
        margin-top: -5px;
      }
      &-text {
        margin-top: -7px;
      }
    }
  }
}

.discussion {
  margin: 10px 19px 16px 17px;
  background: var(--white);
  height: max-content;
  padding: 39px 47px;
  @media only screen and (max-width:550px) {
    padding: 39px 10px;
  }
  & h6 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: var(--h1-black-new);
    margin-bottom: 28px;
    & span {
      font-weight: normal;
      color: var(--helper-text-color);
    }
  }
}
// .tr-main-c{
//   @media only screen and (max-width:){

//   }
// }

.QANDA-c {
  // background: var(--new-faded-white);
  background-color: rgb(242, 242, 243);
  padding: 10px;

  &__item {
    // &:not(:first-child){
    margin-top: 10px;
    background-color: white;
    // }
  }
}
.QANDA-list {
  max-height: 500px;
  overflow-y: auto;
}
