.personality-card {
    width: 100%;
    padding: 40px;
    margin-top: 40px;
    border: none;
  }
  
  .personality-title {
    font-family: SFProText;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #26292d;
  }
  
  .personality-sub {
    font-family: SFProText;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #63666a;
  }

  .personality-heading {
    font-family: SFProText;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #26292d;
    margin: 80px 0 0 0;
  }

  .personality-text{
    font-family: SFProText;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #63666a;
    margin-top: 10px;
  }

  .badge-light {
    color: #5d5d5d;
    background: #e1e1e1;
    margin-bottom: 10px;
  }
  
  .inputGroup {
    background-color: #fff;
    display: block;
    position: relative;
  
    label {
      width: 100%;
      display: block;
      color: #3c454c;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
  
      &:before {
        width: 20%;
        height: 10px;
        border-radius: 50%;
        content: "";
        background-color: var(--tef-red);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }
  
      &:after {
        width: 32px;
        height: 32px;
        content: "";
        border: 2px solid #d1d7dc;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }
  
    input:checked ~ label {
      color: #fff;
  
      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }
  
      &:after {
        background-color: #54e0c7;
        border-color: #54e0c7;
      }
    }
  
    input {
      order: 1;
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
  }
  
  .inputGroup {
    background-color: #fff;
    display: block;
    position: relative;
    width: 100%;
    float: left;
    label {
      padding: 12px 50px;
      width: 100%;
      display: block;
      text-align: left;
      color: #3c454c;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
  
      &:before {
        border-radius: 50%;
        content: "";
        color: black;
  
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }
  
      &:after {
        content: "";
        border: 2px solid #d1d7dc;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }
  
    input:checked ~ label {
      color: #fff;
  
      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }
  
      &:after {
        background-color: #54e0c7;
        border-color: #54e0c7;
      }
    }
  
    input {
      order: 1;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
  }
  
  .form {
    font-size: 13px;
    line-height: 10px;
  }
  .timer {
    font-family: 'Orbitron', sans-serif;
    font-size: 30px;
  }
  