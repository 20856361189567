.error-wrapper {
  font-size: 14px;
  color: #fa5c6c !important;

  svg {
    height: 14px !important;
    width: 14px !important;
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    color: #fff !important;
    fill: #fa5c6c !important;
  }
}