.avatar-badge {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-block {
  position: absolute;
  bottom: 1%;
  background-color: #eaf5f1;
  margin: auto;
  color: #0db884;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  border-radius: 50px;
  height: 20%;
  width: 50%;
}
.avatar-container {
  position: relative;
}

.profile-avatar-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  background-color: white;
  padding: 13px 9px;

  &__image-c {
    // background-color: black;
    border-radius: 50%;
    position: relative;
    margin: 0 0 10px 0px;
    align-self: center;
    &--badge {
      position: absolute;
      z-index: 1;
      border: solid 1.3px #ffffff;
      border-radius: 63px;
      padding: 2px;
      bottom: -10px;
      left: 0;
      right: 0;

      font-family: SFProText;
      font-size: 6.3px;
      font-weight: 500;
      line-height: 1.6;
      color: #ffffff;
      text-align: center;
      background-color: #eaf5f1;
      color: #0db884;
    }
  }
  &__name {
    margin: 5px 0px 13px 0px;
    & h5 {
      font-family: Poppins;
      font-size: 15.1px;
      font-weight: 600;
      letter-spacing: 0.13px;
      text-align: center;
      color: #26292d;
    }
  }

  &__scores {
    display: flex;
    padding: 17px 5px;
    // border-top-width: 1px;
    border: 1px solid #eaecef;
    border-left: 0px;
    border-right: 0px;
    // border-bottom-width: 1px;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    &--score {
      flex: 1;
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        border-right: 1px solid #eaecef;
      }
      padding: 0px 10px;
      & span {
        font-family: Poppins;
        font-size: 11.3px;
        font-weight: 500;
        text-align: center;
      }
      & p {
        font-family: Poppins;
        font-size: 7.6px;
        text-align: center;
        color: #919ba8;
      }
    }
  }
  &__view-all {
    font-family: SFProText;
    font-size: 9px;
    text-align: center;
    color: #919ba8;
    margin-top: 10px;
    cursor: pointer;
  }
}
