.RightSideBar {
  // flex: 0 0 20%;
  width: 20%;
  position: fixed;
  top: 6rem;
  right: 0;
  height: 100vh;
  background-color: var(--white);
  border-left: 1px solid var(--pale-lilac);
  padding: 24px 0.5% 6rem 0.5%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  &::-webkit-scrollbar {
    display: none;
  }
}

.RightSideBarHide {
  display: none;
}

.nav {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin: 0 5px;
  }
}

.mainLinkText {
  margin-right: 15px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.93px;
  color: var(--blue-grey);

  &:hover {
    color: var(--tef-red);
  }
}

@media screen and (max-width: "1024px") {
  .RightSideBar {
    display: none;
  }
}
