.chats-tab {
  .nav-item:hover {
    border: 0 !important;
    overflow: hidden;
  }

  .chat-tab__content {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .custom-tab {
    border: 0 !important;

    &:hover {
      cursor: pointer;
      border: 0 !important;
    }
  }

  .active-nav {
    .custom-tab {
      background: transparent !important;
      outline: 0 !important;
      border: none !important;
      font-weight: 600 !important;
      border-bottom: 2px solid var(--tef-red) !important;
      color: var(--tef-red) !important;
      cursor: pointer;
      padding: 0.5rem .7rem;
      font-size: 13px;
    }
  }
}