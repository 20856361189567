.RequestC{
  background-color: white;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  padding: 20px;
  
}
.heading{
  & h5{
    color: #000;
    font-size: 18px;
    font-weight: bold;
  }
}

.toggles{
  display: flex;
  font-size: 25px;
  // color: black;
  font-weight: 600;
  cursor: pointer;
  // &:not(:last-child){
  //   margin-right: ;
  // }
  // justify-content: ;
  margin-top: 20px;
  & span{
    margin-right: 10px;
  }
}

.active{
  border-bottom: 2.5px solid var(--tef-red);
  color:  var(--tef-red) !important;
  padding-bottom: 10px;
}

.requestItemC{
  margin: 10px 0;
  // width: 100%;
  border:0.5px solid var(--new-faded-white);
  padding: 10px 5px;
  border-radius: 5px;
  // box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.groupHeading{
  margin-left: 10px;
  display: flex;
  align-items: center;
  & h5{
    color: #000;
    font-size: 15px;
    font-weight: bold;
  }
  & span{
    color: var(--helper-text-color);
    margin-top: -7px !important;
  }
}
.btnGroup{
  margin-left: 10px;
}

.btn{
  border-radius: 25px;
  margin:2px;
}

.listWrapper{
  // max-height: 500px;
  // overflow-y: auto;
}