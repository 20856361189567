.container {
  display: flex;
  flex-direction: column;
  background: #f9fbfd 0% 0% no-repeat padding-box;
  border: 1px solid #919ba833;
  border-radius: 15px;
  opacity: 1;
  padding: 40px 4%;

  & h4 {
    text-align: left;
    font: normal normal bold 17px/26px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    & span {
      color: var(--tef-red);
    }
  }
}

.section {
  margin-top: 20px;
  & span {
    text-align: left;
    font: normal normal medium 15px/26px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
}

.socialContainer {
  display: flex;
  flex-wrap: wrap;
  &__item {
    &:not(:last-child) {
      margin-right: 1.5%;
    }
  }
}
.input {
  background: #edf5fa 0% 0% no-repeat padding-box;
  // opacity: 0.2;
  height: 50px;
  &:focus {
    background: #edf5fa 0% 0% no-repeat padding-box;
  }
}
.sendBtn {
  background: var(--tef-red);
  width: 50px;
}
