.application-faq-heading {
  font-family: SFProText;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 10px 0 0 0;
}
.app-faq-back-btn {
  margin: 70px 0 0 0;
  cursor: pointer;
  font-weight: bold;
}
.faq-question {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #26292d;
}
.faq-answer {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.2px;
  color: #26292d;
  float: left;
}
