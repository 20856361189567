.opp-list-container {
  display: flex;
  flex: 1;
  margin-top: 23px;
  flex-wrap: wrap;
  // justify-content:space-between;
}

.opp-list-item {
  border-radius: 3.6px;
  box-shadow: 0 2px 4px 0 rgba(145, 155, 168, 0.06);
  background-color: var(--white);
  margin: 15px 3.5px;
  // width: 324px;
  max-width: 324px !important;
  // flex:1;
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 339px;
  // justify-content: space-between;
  cursor: pointer;
  &__image-c {
    height: 159px;
    width: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-fit: top;
    }
  }
  &__info-c {
    display: flex;
    padding: 8px 10px 8px 20px;
    justify-content: space-between;
    flex-direction: column;

    &__category-date {
      display: flex;
      justify-content: space-between;
      // flex:2;
      margin-top: 8px;
    }

    &__opp-title {
      margin-top: 13px;
      & h5 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
      }
    }
    &__deadline-fund {
      margin-top: 21px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.opp-cat {
  color: orange;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 300;
  letter-spacing: 0.24px;
  flex: 1;
}

.opp-date-posted {
  flex: 1;
  color: var(--tef-grey);
  font-size: 12px;
  font-family: Poppins;
  font-weight: 300;
  letter-spacing: 0.24px;
}

.opp-icon {
  background-color: #f6f8fc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
}

.deadline-fund {
  display: flex;
  flex-direction: column;
  & > span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    // color: var(--h-1-black-new);
  }
}

.info-badge {
  font-family: Poppins;
  font-size: 8.7px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #8f92a1;
}

@media only screen and (max-width: 645px) {
  .opp-tab-navs {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .opp-list-item {
  }
}

@media only screen and (max-width: 575px) {
  .opp-list-item {
    margin: 15px auto;
  }
}
