.container {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #919ba833;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  // align-items: center;
  @media only screen and (max-width:500px) {
    padding: 20px 0 20px 10px;
  }
  align-items: flex-start;
  &__left {
    flex: 1;
    display: flex;
    align-items: flex-start;
  }
  &__right {
    & p {
      text-align: left;
      font: normal normal normal 14px/26px Poppins;
      letter-spacing: 0px;
      color: #6e6e70;
      opacity: 1;
    }
  }
}

.commentDetail {
  margin-left: 20px;
  // margin-top: 12px;
  & h4 {
    text-align: left;
    font: normal normal 600 15px/24px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  & .content {
    text-align: left;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0px;
    color: #6e6e70;
    opacity: 1;
  }
}
