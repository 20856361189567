.opp-tips-Container{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.opp-tips-item{
  border-radius: 6px;
  border: solid 1px var(--new-faded-white);
  background-color: rgba(247, 248, 251, 0.3);
  padding: 28px 21px 5px 21px;
  margin: 5px 0px;
  & h1{
    font-weight: 600;
    letter-spacing: 0.2px;
    text-align: left;
  }
  & p{
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 0.2px;
    color: var(--tef-grey);
    margin-top: -20px;
  }
}

.red{
  color: var(--tef-red);
}