.UserSection {
  height: 64px;
  border-radius: 6px;
  border: solid 1px var(--pale-lilac);
  background-color: var(--pale-grey);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: var(--h1-black-new);
  padding-left: 12px;

  &__dp {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__name {
    margin-top: 8px;
    margin-left: 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #171725;
  }
}
