.overlay {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0,0,0, 0.2);
}
.custom-loading-bar {
 margin: 0px;
 padding: 0px;
}