.main-card {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: solid 1px var(--new-faded-white);
  background-color: var(--white);
  margin: 0 0 10px 0;
  padding: 0 15px;
}

.my-board-heading {
  height: 19px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #354052;
  margin-left: 24px;
}

.main-card-container {
  margin: 24px 0 10px 0;
}

.top-row-icon {
  color: var(--helper-text-color);
  margin-top: -3px;
}

.top-row-text {
  margin-left: 8px;
  padding-top: 12px;
  position: relative;
  top: 2px;
}

.goal-button {
  margin-top: -7px;
}

.mentor-search::placeholder {
  width: 122px;
  height: 14px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--helper-text-color);
}

.mentor-search {
  top: -5px;
  position: relative;
  width: 192px;
  height: 32px;
  border-radius: 4px;
  border: solid 1px var(--pale-lilac);
  background-color: var(--white);
}

.select-container {
  position: relative;
}

.goal-list-container {
  position: absolute;
  border: solid 1px var(--new-faded-white);
  border-radius: 4px;
  padding: 10px;
  z-index: 1;
  width: 100%;
  max-height: 200px;
  max-width: 500px;
  background-color: var(--white);
  overflow-y: auto;
  // display: none;
}

.goal-list-item {
  cursor: pointer;
}

.calendar_page {
  width: 100%;
}

.conn-list {
  @media only screen and (max-width: 809px) {
    justify-content: center;
  }
}

.loadmore-btn {
  background: var(--new-faded-white);
  color: black;
  margin: 0 auto;
  border-color: var(--new-faded-white);
  width: 50%;
  margin-top: 20px;
  &:hover {
    opacity: 0.5;
    background: var(--new-faded-white);
    border-color: var(--new-faded-white);
    color: black;
  }
  &:focus {
    background: var(--new-faded-white);
    border-color: var(--new-faded-white);
  }
}

.conn-main {
  //  background-color: #fff;
  //  padding: 20px;
  margin-top: 20px;
  margin-bottom: 100px;
  //  height: 800px;
  //  overflow-y: auto;
  width: 100%;
}

.conn-nav-c {
  display: flex;
  flex-wrap: wrap;
  height: max-content;
  align-self: flex-start;
  position: sticky;
  //  justify-content:center;
  //  align-items: center;
  width: 100%;
  @media only screen and (max-width:500px) {
    flex-direction: column;
  }

  &__tabs {
    flex: 2;
    margin: 0 !important;
  }
  &__search {
    // align-self: center;
    // align-items: center;
    padding: 5px;
    border: solid 1px var(--new-faded-white);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--tef-grey);
    margin: 10px 0px;
    border-radius: 0px 4px 4px 0px;
    transition: all 2s ease-in-out;

    &:hover {
      cursor: pointer;
      & .ci {
        transform: rotate(45deg);
      }
    }
    & .cs {
      display: none;
    }
    @media only screen and (max-width:500px) {
      // width: 100%;
      // margin-left: 10px;
      // margin-right: 10px;
      padding: 10px;
      & .cs {
        display: inline;
      }
    }
  }
}

.conn-search-c {
  height: 50px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  & input[type="text"] {
    border: none;
    padding: 12px;
    height: 100%;
    &:focus {
      outline: none;
    }
    width: 80%;
    &::placeholder,
    ::placeholder {
      font-size: 18px;
      color: var(--helper-text-color);
    }
  }

  &__cl {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    & span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--helper-text-color);
      padding: 10px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0.5;
    }
  }
}

.cht-c {
  // min-width: 36px;
  // height: 36px;
  // margin: 0 10px 0 0;
  // padding: 8px 11px;
  border-radius: 4px;
  border: solid 1px var(--helper-text-color);
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  cursor: pointer;
}
