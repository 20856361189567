.cover-photo {
  height: 219px;
  border-radius: 6px;
  position: relative;
  margin-bottom: 21px;
  background-color: #f0f0f0;

  &__image {
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    object-fit: cover;
  }

  &__form {
    position: absolute;
    right: 74px;
    top: 21px;
  }

  &__label {
    cursor: pointer;
    width: 139px;
    height: 41px;
    border-radius: 6px;
    border: solid 1px var(--white);
    background-color: rgba(23, 23, 37, 0.5);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
