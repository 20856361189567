.Layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__loader {
    margin-top: 6rem;
    width: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    display: flex;
    flex: 1;
  }

  &__mainContent {
    width: 83%;
    margin-left: 17%;
    margin-top: 6rem;
    padding: 24px 20px;
  }

  &__emptyContent {
    width: 100%;
    margin-top: 6rem;
    padding-top: 24px;
  }
}

@media screen and (max-width: 768px) {
  .Layout {
    &__mainContent {
      padding: 10px 0;
      margin-left: 0 !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .Layout {
    &__mainContent {
      margin-left: 20%;
      width: auto;
    }
  }
}

@media screen and (max-width: "1024px") {
  .Layout {
    &__mainContent {
      flex: 1;
    }
  }
}
