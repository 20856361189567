.Logout {
  max-width: 540px;
  height: 573px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  background-color: var(--white);
  padding-left: 70px;
  padding-right: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  &__logoutIcon {
  }

  &__text {
    font-family: Poppins;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.2px;
  }

  &__h1 {
    font-size: 28px;
    font-weight: 600;
    color: var(--h-1-black-new);
  }

  &__h3 {
    font-size: 20px;
    line-height: 1.4;
    color: var(--tef-grey);
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    width: 116px;
    height: 38px;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    margin: 0 6px;

    &:focus {
      outline: none;
    }
  }

  &__signinBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--tef-red);
    color: #fafafb;
  }

  &__closeBtn {
    background-color: #f1f1f5;
    color: var(--tef-grey);
  }
}
