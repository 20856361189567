.Mentor-card {
  height: 283px;
  border-radius: 3.2px;
  border: solid 1px var(--new-faded-white);
  background-color: var(--white);
  margin-top: 18px;
}
.Mentor-status-bar {
  width: 87px;
  height: 16px;
  border-radius: 3px;
  background-color: var(--pre-green);
  margin: 14px 0 0 17px;
}
.Mentor-status {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: var(--white);
  position: relative;
  margin: auto;
  bottom: 5px;
}
.Mentor-program-bar {
  width: 87px;
  height: 16px;
  border-radius: 3px;
  border: solid 1px var(--tef-grey);
  margin: 14px 0 0 17px;
}
.Mentor-program {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: var(--tef-grey);
  position: relative;
  margin: auto;
  bottom: 5px;
}
.Mentor-text {
  font-family: Poppins;
  font-size: 15.4px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.22px;
  color: var(--h-1-black-new);
  margin: 15px 0 0 16px;
  display: block;
}
.Mentor-handle {
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--helper-text-color);
  margin: 5px 0 0 0;
  display: block;
}
.Mentor-bio {
  height: 41.6px;
  font-family: Poppins;
  font-size: 12.8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.16px;
  color: #44444f;
  margin: 0 0 0 16px;
}
.Mentor-comment-area {
  margin-top: 24px;
}
.mentor-img {
  position: relative;
  top: 19px;
  width: 62px;
  height: 62px;
  margin-bottom: 12px;
}
.mentor-connection-button {
  border-radius: 4.8px;
  // background: var(--tef-red);
  margin: 31px 0 0 0;
}
.mentor-button-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: var(--white);
}
.mentor-chat-button {
  width: 36px;
  height: 30px;
  border-radius: 4px;
  border: solid 1px var(--helper-text-color);
  background-color: var(--white);
  margin: 31px 0 0 5px;
  // position: relative;
  // left: 200px;
  // bottom: 30px;
}
.mentor-elipse {
  float: right;
  // position: relative;
  // left: 160px;
  // bottom: 27px;
  color: var(--helper-text-color);
}
.mentor-card-interests {
  width: 80%;
  min-height: 82px;
  border-radius: 3.2px;
  background-color: #e6e9ed;
  border: solid 1px var(--new-faded-white);
}

.connection-card-c {
  flex: 1;
  background-color: #ffffff;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);

  display: flex;
  flex-direction: column;
  padding: 19px 14px 14px 22px;
  border-radius: 3.2px;
  border: solid 1px var(--new-faded-white);
  margin: 9px 5px 9px 5px;
  position: relative;
  max-width: 318px !important;
  height: 300px;
  // flex-wrap: wrap;
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__name {
    margin-top: 12px;
    & h6 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.16px;
      color: #171725;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__bio {
    margin-top: 6px;
    & p {
      font-family: Poppins;
      font-size: 12.8px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: 0.16px;
      color: #44444f;
    }
  }
  &__interest-c {
    // margin-top: 25.4px;
    // border-radius: 3.2px;
    // background-color: #f6f8fa;
  }
  &__availabilty,
  &__mode-of-communication,
  &__interest-c {
    margin-top: 6px;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;
    font-family: Poppins;
    & > span {
      font-weight: 600;
      letter-spacing: 0.2px;
      color: var(--tef-grey);
    }
  }
}

// .extended{
//   height: calc(300px + 50px);
// }

.interest-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  border-radius: 11px;
  border: solid 1px var(--helper-text-color);
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: var(--tef-grey);
  margin-left: 2px;
  margin-top: 2px;
}

.day-item {
  margin: 2px;
  display: flex;
  justify-content: space-evenly;
  font-size: 12px;
  font-family: Poppins;
  color: var(--tef-grey);
  padding: 2px 5px;
}

.d-container {
  display: flex;
  flex-wrap: wrap;
}

.available-day {
  background-color: var(--tef-red);
  color: #ffffff;
  border-radius: 50%;
}

.connection-card-image {
  height: 62px;
  width: 62px;
  border-radius: 100%;
  & .img-style {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.connection-card__buttons {
  margin-left: 50px;
  // float:left;
  display: flex;
  // flex:1;
  align-items: center;
  justify-content: center;
}
.connect-btn {
  border-radius: 4.8px;
  width: 94px;
  background-color: var(--tef-white);
  color: var(--tef-red);
  padding: 7px 18px;
  border: 1px solid var(--tef-red);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  cursor: pointer;
}
.connect-btn-connect {
  &:hover {
    // opacity: .5;
    color: white;
    background-color: var(--tef-red);
  }
}

.connect-btn-pending {
  background-color: var(--tef-grey);
  border-color: var(--tef-grey);
  opacity: 0.5;
  color: #ffffff;
  &:hover {
    // opacity: .5;
    color: white;
    border-color: var(--tef-grey) !important;
  }
}

.connect-btn-connected {
  background-color: var(--tef-red);
  color: #ffffff;
}
.more-option-btn {
  cursor: pointer;
}
.connection-card-more-option {
  // width: 50%;
  background-color: #ffffff;
  position: absolute;
  border: solid 1px var(--new-faded-white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  z-index: 1;
  left: 130px;
  right: 5px;
  top: 70px;
  padding: 5px;
  display: flex;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: normal;
}

.more-interest-item {
  font-size: 12px;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: rgba(145, 155, 168, .5);
  background-color: var(--tef-red);
  border-radius: 11px;
  margin-left: 2px;
  margin-top: 2px;
  letter-spacing: 0.2px;
  color: white !important;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

@media only screen and (max-width:435px) {
  .connection-card__buttons {
    margin-left: 10px;
  }
}

.more-interest-list {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  & span {
    font-size: 14px;
    letter-spacing: 0.2px;
    color: var(--tef-grey);
    line-height: 22px;
  }
}
.other-interest-h4 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #44444f;
}

.interest-more {
  width: 60%;
  background-color: white;
  border: 1px solid var(--new-faded-white);
  position: absolute;
  padding: 20px;
  z-index: 800;
  border-radius: 5px;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  // left:70px;
  right: -10px;
  top: 5px;
}

.interest-view-more {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.2px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.break-word {
  word-wrap: break-word;
  hyphens: auto;
}
