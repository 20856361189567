.TCMain {
  max-width: 1000px;
  height: 90vh;
  margin: 20px auto;
  font-family: Poppins;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.29px;
  padding: 10px;

  &__title {
    text-transform: uppercase;
    text-align: center;
  }

  &__redText {
    color: red;
    & a {
      color: red;
      text-decoration: underline;
    }
  }

  &__boldText {
    font-weight: bolder;
  }

  li {
    padding: 5px 0;
  }

  &__structuredList {
    counter-reset: item;

    li {
      display: block;
      margin-left: 20px;
    }
    li:before {
      content: counters(item, ".") " ";
      counter-increment: item;
      margin-right: 5px;
    }
  }

  &__btn {
    margin: 20px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-transform: uppercase;
    padding: 10px 0;
  }

  &__rejectBtn,
  &__acceptBtn {
    align-self: center;
    text-transform: uppercase;
    width: 113px;
    height: 38px;
    border: 1px solid var(--tef-red);
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1),
      inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
      transform: translateY(2px);
    }
  }

  &__rejectBtn {
    &:hover {
      text-decoration: none;
    }
  }

  &__acceptBtn {
    color: white;

    &:focus {
      outline: none;
    }
  }
}
