.thread-nav {
  margin: 0 13px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & .thread-btn {
    border: 0;
    border-radius: 30px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    transition: all 0.2s;

    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      outline: none;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  &__left {
    display: flex;
    // height: 38px;
    margin-bottom: 10px;

    &--select {
      margin-right: 20px;
      border-radius: 30px;
      padding-left: 10px;
      border: 0;

      &:focus {
        outline: none;
      }
    }

    &--btn {
      width: 100px;
      margin-right: 20px;
      background-color: white;
      color: black;

      &:hover {
        background-color: var(--tef-red);
        color: #fafafb;
      }

      & .active {
        background-color: var(--tef-red);
        color: #fafafb;
      }
    }

    &--search,
    &--icons {
      height: 38px;
      background-color: var(--white);
      color: var(--blue-grey);
      border: 0;

      &:focus {
        outline: none;
      }
    }

    &--search {
      width: 90%;
      border-radius: 6px;
      padding: 11px 13px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      transition: all 0.2s;
      margin-right: -2.25rem;
      // border: 0;
      &:focus {
        width: 100%;
      }
      &::placeholder {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.11px;
        color: var(--helper-text-color);
      }
    }

    &--icons {
      &:active {
        transform: translateY(2px);
      }
    }

    &--icons[disabled] {
      border: 0;
      background-image: none;
    }
  }

  &__new-btn {
    width: 147px;
    height: 38px;
    background-color: var(--tef-red);
    color: #fafafb;
  }
}

@media screen and (max-width: 1024px) {
  .thread-nav {
    flex-direction: column;

    &__left {
      flex-wrap: wrap;

      & > * {
        height: 30px;
        margin-bottom: 5px;
      }
    }

    &__new-btn {
      align-self: center;
    }
  }
}
.pgin_c {
  max-width: 500px;
  overflow-x: auto;
  margin-top: -40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
