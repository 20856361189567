.opportunity-header {
  display: flex;
  background-color: #fff;
  justify-content: space-between;

  margin-bottom: 20px;

  &__photo {
    width: 100%;
  }

  &__title {
    font-size: 30px;
    color: #26292d;
    font-weight: bold;
    line-height: 35px;
  }
}

// &__photo-box {

// }
.opportunity-action--apply {
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
}
.opportunity-header__photo-box {
  width: 50% !important;
}
.opportunity-header__detail-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
}

.tag-box {
  display: flex;
  align-items: baseline;
}
.opportunity-posted-by {
  font-size: 14px;
  color: #919ba8;
  margin-bottom: 25px;
}
.opportunity-creator {
  font-size: 14px;
  font-weight: bold;
  color: #26292d;
  line-height: 16px;
}
.opportunity-tag {
  display: inline-block;
  border-radius: 20px;
  border: 1px solid #919ba8;
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 5px;
}
.alumni-flag {
  top: 8% !important;
  left: 15px;
}
.opportunity-detail-short-desc {
  // color: #919BA8;
  font-size: 16px;
  line-height: 16px;
  color: #919ba8;
}
.opportunity-tag-box {
  margin-right: auto;
  color: #919ba8;
}
.apply_section {
  display: flex;
  margin-right: auto;
  align-items: center;
}
.detail-font {
  font-size: 15px;
  align-items: center;
}
.opportunity-summary {
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0 0px 0;
  font-weight: 600;
}

.remainder {
  display: flex;
  border-radius: 0.25rem;
  border: solid 1px #d4d4d4;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.remainder-text {
  margin-bottom: 0px;
  font-size: 12px;
  padding: 0 15px;
}
.remainder-alarm {
  margin-left: 15px;
}
.remainder-caret {
  margin-right: 15px;
}
.opportunity-action {
  display: flex;
  margin-bottom: 20px;
}
.opportunity-share__icons {
  display: flex;
}

.opportunity-related {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
}

.hide-reply-button {
  display: none;
}
.showReplyButton {
  display: block;
}
.opp-shares {
  display: none !important;
}
.remainder-wrapper {
  width: 100%;
}
.remainder-menu {
  width: 100%;
}
.remainder-freq {
  text-align: center;
}
.remainder-popover {
  padding: 8px;
  margin-left: 10px;
}
.remainder-popover {
  background: none;
  display: flex;
  color: #26292d;
  align-items: center;
  border: 1px solid rgb(184, 191, 199);
  padding: 8px;
  justify-content: space-between;
  // margin-left: 10px;
  width: 100% !important;
  // padding: 20px;
}
.share-opportunity {
  display: block !important;
}

.comment-input-area {
  display: none;
}
#share-btn {
  display: none;
}
@media screen and (max-width: 790px) {
  .opportunity-header {
    display: block;
  }
  .opportunity-action {
    display: block;
  }
  .remainder-popover {
    justify-content: flex-start;
    align-items: center;
  }
  .remainder-text {
    margin-right: auto;
    font-weight: bold;
    font-size: 15px;
  }
  .share-opportunity {
    display: none !important;
  }
  .opp-shares {
    display: block !important;
    margin-top: 20px;
  }
  .opp-share {
    display: flex;
    justify-content: space-around;
  }
  .opportunity-header__photo-box {
    height: 300px;
    width: 100% !important;
  }
  .apply_section {
    display: block;
  }

  .opportunity-header__detail-box {
    width: 100%;
  }
  .opportunity-action--apply {
    width: 100% !important;
    margin: 10px 0;
    padding: 15px;
  }
  .summary-highlist {
    display: block;
  }
  .detail-font {
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
  }
  .comment-input-area {
    display: block;
  }
  .mobile-pad {
    padding: 0;
  }
  .react-text {
    display: none;
  }
  .show-more-btn {
    width: 100%;
  }
  // .opportunity-related {
  //     margin-left: 15px
  // }
  .remainder-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }
  .summary-highlist {
    display: flex;
  }
  .detail-font {
    display: flex;
    flex-direction: column;
    // align-self: center;
    // justify-content: center;
    text-align: center;
    font-size: 14px;
  }
  .remainder-popover {
    margin-left: 0;
    padding: 15px;
  }
  .icon-pad {
    padding: 5px 10px;
    background-color: #ffeeee;
    width: 100%;
  }
  .detail-pad {
    margin-top: 10px;
  }
  .info-pad {
    font-size: 18px;
  }
  .r-pad {
    padding: 10px;
    margin-right: 0;
  }
  .r-pad > div:last-child {
    margin-left: 8px;
  }
  .full-input {
    display: none;
  }
  .comment-textarea {
    background-color: rgb(226, 229, 236);
  }
  .bg-gray {
    background-color: rgb(226, 229, 236);
  }
  .opportunity-related {
    padding: 15px;
  }
  #share-btn {
    display: flex;
  }
}
@media screen and (max-width: 590px) {
  .icon-pad {
    font-size: 14px;
  }

  .info-pad {
    font-size: 14px;
  }
}
