.table_th {
  font-weight: 400;
  color: #999;
  font-family: Poppins;
  text-transform: capitalize;
  font-stretch: normal;
}

.table_td {
  font-weight: 400;
  color: #333;
  font-family: Poppins;
  font-stretch: normal;
}

.text-gray {
  color: #ccc;
  font-weight: 500;
}
