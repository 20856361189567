// .container {
// }

.header {
  display: flex !important;
  border-bottom: none !important;
  & h4 {
    text-align: left;
    font: normal normal bold 25px/35px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    float: left;
  }
  .closeIcon {
    float: right;
    cursor: pointer;
  }
}

.file_info {
  text-align: left;
  color: #6e6e70;
  & h5 {
    text-align: center;
    font: normal normal bold 18px/35px Poppins;
    color: var(--tef-red);
    cursor: pointer;
  }
}

.footer {
  border: none;
  & button {
    background: var(--tef-red);
  }
}
