*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: max-content !important;
  width: 100%;
  /* width: 1660px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  overflow-x: hidden !important;
  min-height: 100vh;
  background-color: #f6f8fc;
}

.goog-te-banner-frame.skiptranslate,
.goog-te-gadget-icon {
  display: none !important;
}

body {
  top: 0px !important;
}

body {
  top: 0px !important;
}

// colors
.bg-grey {
  background: #f6f8fc !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.pointer {
  cursor: pointer;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.emoji-mart-preview {
  position: relative;
  display: none;
  height: 70px;
}

fadeDiv {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;

  display: block;

  width: 100%;
  height: 400px;

  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.9) 100%
  );
}
