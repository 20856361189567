.SubHeading{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.3rem;
  margin-top: 1rem;
}
.SubHeadingLogo{
  margin-right: 1.2rem;
}
.SubHeadingText{
  color: #26292d;
  margin: 0;
  font-weight: 600;
}