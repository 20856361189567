.tab-heading {
  font-size: 0.87rem;
}

.discover-menu {
  color: var(--helper-text-color);
  background-color: var(--white);
  padding: 1rem 0.8rem 1rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  .active {
    color: var(--tef-red);
    border-bottom: 2px solid var(--tef-red);
    border-radius: 0px 1px;
  }
}

.sort-dropdown {
  color: #919ba8;
  background-color: var(--white);
  padding: 1rem 0.8rem 1rem 1rem;
  border-radius: 4px;
}

.select-dropdown {
  outline: none;
  border: none;
}

.MainDiscoverHomeColumn {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.MoreTopOptions {
  font-weight: 600;
  padding: 1rem;
}

.MoreTopOptions:hover {
  color: white;
  background-color: var(--tef-red);
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}

.DiscoverHead {
  display: flex;
  justify-content: space-between;
}

.SearchDiscover {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  right: -1.9rem;
  margin-bottom: 0.5rem;
}

.DiscoverPageTitle {
  flex: 0 0 70%;
  font-family: Poppins;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #171725;
}

.SearchIcon {
  position: relative;
  right: 2rem;
}
