.check-div {
  padding: 0px 10px 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10% 0;

  &__check {
    width: 200px;
    height: 150px;
    margin-bottom: 15px;
  }
}

.action-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 25px;
}

.message-button {
  width: 120px;
  padding: 6px;
  text-decoration: none;
}
.action-btn > a:hover {
  color: white;
}

.message-button:active,
.message-button:focus {
  outline: none;
}

.resend-btn {
  background-color: white;
  color: red;
}

.confirmation_logo {
  height: 33px;
  margin: 30px 0;
}
