.LanguageItem {
  padding: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  font-family: Poppins;
}

.LanguageToggle {
  background-color: white;
  border-width: 1px;
  border-color: #6e6e70;
  padding: 10px 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  font-family: Poppins;
}

.LanguageDropdown {
  overflow: initial !important;
  float: initial !important;
}
