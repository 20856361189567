.Message {
  display: flex;
  margin-bottom: 15px;
  width: fit-content;

  &__dp {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 15px;
  }

  &__messageDisplay {
    flex-grow: 1;
  }

  &__NameTime {
    margin-top: 3px;
    display: flex;
  }
  &__name,
  &__time,
  &__message {
    font-family: Poppins;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.38px;
    color: var(--h-1-black-new);
    font-size: 1rem;
    line-height: 1.42857;
  }

  &__name {
    text-transform: capitalize;
    font-weight: 600;
    margin-right: 10px;
  }

  &__time {
    color: var(--blue-grey);
    font-size: 0.7rem;
    font-weight: 600;
  }
}

.MessageCard {
  padding: 10px;
  font-family: Poppins;
  width: fit-content;
  font-size: 1rem;
  border-radius: 7px;
}

.UserMessage {
  margin-left: auto;
  flex-direction: row-reverse;
}

.MessageUser {
  background-color: #f7574b;
  color: white;
}

.MessageGuest {
  background-color: var(--new-faded-white);
}
