.chat-bubble__wrapper {
  max-width: 65%;


  .chat-bubble__body {
    -webkit-box-flex: initial;
    flex: initial;
    display: table;
    min-width: 0;
    
    padding: 2px 8px;
    .chat-bubble__message {
      position: relative;
      border: 1px solid rgba(209, 213, 219, 1);
      background-color: rgba(255, 255, 255, 1);
      padding: 6px 8px;
      margin: 4px 0;
      border-radius: 8px;
      font-weight: 100;
      color: #9b9b9b;
      font-size: 14px;
      display: flex;
    
    }
    .chat-bubble__meta {
      background-color: transparent !important;
      color: #9b9b9b;
      margin-top: 6px;
      padding: 0;
      span {
        font-size: 13px;
      }
    }
  }
  
} 

