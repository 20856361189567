.section-container {
  &:not(:last-child) {
    border-bottom: solid 1px var(--new-faded-white);
  }
  // padding-bottom: 83px;
  &-heading {
    & h1 {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      color: var(--h1-black-new);
      // margin-bottom: 48px;
    }

    & span {
      font-family: Poppins;
      font-size: 10px;
      // text-align: right;
      color: var(--helper-text-color);
      // margin-top: -5px;
    }
  }
}
