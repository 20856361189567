.create-opp-banner-holder{
  margin-top: 23px;
  margin-bottom: 13px;
}
.create-opp-breadcrumb{
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #656565;
  margin-top: 24px;
  margin-left: 3px;
}
.create-opp-form-container{
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap-reverse;
  &__left{
    flex:2;
    background-color: white;
    // height: 100vh;
    margin-right: 15px;
    padding: 36px 91px 37px 37px;
  
  }
  &__right{
        flex:1;
        background-color: white;
        padding: 32px 28px 37px 28px;
        height: max-content;
        // align-self: flex-end;
    & h1{
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
      letter-spacing: 0.2px;
      text-align: center;
      color: var(--h-1-black-new);
      margin-bottom: 30px;
    }
  }
}

@media only screen and (max-width:765px){
.create-opp-form-container{
  flex-direction: column-reverse;
  &__left{
    margin: 15px 15px;
    padding-right: 37px;
  }
  &__right{
    margin: 0px 15px;
  }
}
}


@media only screen and (max-width:400px){
  .create-opp-form-container{
    flex-direction: column-reverse;
    &__left{
      margin: 15px 15px;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
  }