.gallery-box__wrapper {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;

  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 40px;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #fff;
    }  
    &:hover {
      &::-webkit-scrollbar {
        background-color: #f5f5f5;
      }
    }
  }
}