body {
  overflow-x: hidden;
  font-family: "Poppins" !important;
}

.alumni {
  // display: flex;

  // align-items:flex-start;
  // justify-content: flex-end;
  // flex-direction: column;
  position: relative;
  margin-bottom: 30px;
  // background: transparent linear-gradient(180deg, #26292D00 0%, #26292D 100%) 0% 0% no-repeat padding-box;

  &__slide--text {
    position: absolute;
    bottom: 3%;
    left: 3%;
  }

  &__title {
    padding: 10px;

    text-transform: uppercase;
    background-color: #d42316;
    color: #ffffff;
    font-size: 25px;
    border-radius: 10px;
    width: 28%;
    margin-bottom: 10px;
    text-align: left;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;

    &--para {
      line-height: 1;
      font-size: 20px;
      line-height: 28px;
    }
  }
  &__discription {
    width: 50%;
    color: #fff;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 300;
  }
}
.event-box,
.interact__title {
  cursor: pointer;
}
.under-alumni {
  border-bottom: 0.5px solid #e2e2ea;
  margin-bottom: 20px;
}
.alumni__discription--para {
  font-size: 14px;
  line-height: 22px;
}
.alumni-box {
  display: flex;
  align-items: center;

  &__category {
    margin-right: auto;
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    color: #171725;

    text-transform: uppercase;
  }
  &__more {
    color: #d42316;
    font-size: 11px;
    cursor: pointer;
  }
}
.view-alumni-arr {
  display: none;
  cursor: pointer;
}
.view-alumni {
  background-color: rgb(226, 229, 236);
  padding: 5px 18px;
  color: rgb(140, 145, 151);
  border-radius: 3px;
  font-size: 10px;
}

.event-box {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  &__photo {
    width: 54px;
    height: 50px;
    object-fit: cover;
  }

  &__detail-box {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-left: 10px;
  }
  &__title {
    font-weight: bold;
    margin-bottom: auto;
    font-size: 11px !important;
  }
  &__location,
  &__time {
    margin-bottom: 2px;
    margin-top: 0px !important;
    font-size: 12px !important;
    color: #919ba8;
    line-height: 16px;
    font-weight: medium;
    text-transform: capitalize;
  }
}
.interact__title {
  font-weight: bold;
  font-size: 14px;
}

.resource {
  display: flex;

  flex-direction: column;
  margin-bottom: 10px;

  //   &__title::before {
  //     content: " \25B6";
  //     position: relative;
  //     color: #d42316;
  //     margin-right: 5px;
  //   }
  &__title {
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
  }
  &__description {
    font-size: 10px;
    margin-left: 5px;
    font-style: italic;
    color: #919ba8;
    text-transform: capitalize;
    font: italic normal medium 12px/14px;
  }
}
.interact-text {
  text-transform: uppercase;
  color: #171725;
  font: normal normal 600 14px/15px;
}
.resource-text {
  text-transform: uppercase;
  color: #171725;
  font-size: 10px;
}
.alumni__discription {
  width: 40%;
}
@media screen and (max-width: 790px) {
  .alumni {
    border-radius: none;
  }
  .alumni__title {
    width: 70%;
    background-color: rgb(218, 44, 31) !important;
  }
  .alumni__discription {
    width: 100%;
  }
  .alumni > * {
    margin: 10px;
  }
  .alumni__main-content {
    margin-left: 0px;
    margin-right: 0px;
  }
  .resource {
    display: block;
  }
  .alumni__discription {
    font-size: 20px;
  }
  .event-box__title {
    font-weight: bold;

    font-size: 15px !important;
  }
  .resource__title {
    margin-bottom: 25px;
  }
  .resoure-text-box {
    display: flex;
    flex-direction: column;
    margin-right: auto;
  }
  .resource__title {
    display: flex;
    align-items: flex-start;
  }
  .resource-text {
    margin-bottom: 6px;
  }
  .resource__description {
    margin-left: 0;
  }

  .view-alumni {
    display: none;
  }
  .view-alumni-arr {
    display: block;
  }
}
