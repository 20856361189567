.DateTimeDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.Value {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.Label {
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
}
