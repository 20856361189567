.transInput{
  border: none;
  &:hover{
    background-color: var(--new-faded-white);
  }
  &:focus{
    background-color: none;
    outline: none;

  }
}

.dueCalender{
  padding: 5px;
  border-radius: 5px;
  border:1px solid var(--new-faded-white);
  margin-bottom: 5px;
  &:focus{
    outline: none;
  }
}