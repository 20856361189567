.friends-group-card {
  width: 464px;
  height: 89px;
  border-radius: 4px;
  background-color: var(--white);
  margin: 5px 0;
  display: flex;
  justify-content: space-between;

  &__image {
    flex: 0 0 21%;
    border-radius: 4px;
    background-color: blueviolet;

    & .group-image-1 {
      object-position: 50% 50%;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__details {
    width: 79%;
    max-width: 357px;
    border-radius: 4px;
    border: solid 1px #f1f1f5;
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .friends-group-card-details-button {
      width: 97px;
      height: 38px;
      border-radius: 4px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: center;
      text-transform: capitalize;
      transition: all 0.2s;

      &:focus {
        outline: none;
      }
      &:hover {
        background-color: var(--tef-red);
        color: #fafafb;
      }
      &:active {
        transform: translateY(2px);
      }
    }

    & .friends-group-card-button-active {
      background-color: var(--tef-red);
      border: 0;
      color: #fff;

      &:hover {
        border: 1px solid var(--tef-red);
        color: var(--tef-red);
        background-color: #fafafb;
      }
    }

    & .friends-group-card-button-inactive {
      background-color: #fff;
      border: 1px solid var(--tef-red);
      color: var(--tef-red);

      &:hover {
        background-color: var(--tef-red);
        color: #fafafb;
      }
      &:disabled {
        color: white;
        border: none;
      }
    }
  }

  .friends-group-details-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__h1 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      color: #171725;
    }
    &__h3 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      color: var(--blue-grey);
      text-transform: capitalize;
    }
  }
}

@media screen and (max-width: 500px) {
  .friends-group-card {
    height: 100px;

    &__image {
      flex: 0 0 30%;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &__details {
      flex: 1;
      flex-direction: column;
      align-items: center;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      & .friends-group-details-info {
        align-items: center;
        &__h1,
        &__h3 {
          font-size: 11px;
        }
      }
    }
  }
}
