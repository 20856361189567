.MainContent {
    width: 97%;
    margin-top: 1.5rem;
}

.TopRow {
    background-color: var(--white);
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
}

.Logo {
    flex: 0 0 15%;
}

.LogoImage {
    width: 80px;
    height: 80px;
    border-radius: 4px;
}

.TopRowDetailBody {
    flex: 1;
    /* margin-left: 0.5rem; */
    padding-right: 1rem;
}

.WebsiteButton {
    flex: 1;
}

.ToolTitle {
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--h-1-black-new);
}

.SubTitle {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--h-1-black-new);
}

.Tags,
.Poster {
    margin-top: 1.2rem;
}

.ShareOnSocialMedia {
    display: flex;
    color: var(--white);
    margin-top: 1.2rem;
}

.TwitterButton {
    width: 103px;
    height: 33px;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21);
    background-color: var(--twitter);
    margin-right: 0.5rem;
    cursor: pointer;
}

.LinkedButton {
    width: 103px;
    height: 33px;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21);
    background-color: var(--linkedin-colour);
    cursor: pointer;
    margin-right: 0.5rem;
}

.BottomRow {
    border-radius: 4px;
    background-color: var(--white);
    display: flex;
    margin-top: 1rem;
    padding: 1rem 2rem 2rem 0;
}

.SpaceLeft {
    flex: 0 0 16.67%;
}

.DescriptionContent {
    flex: 1;
    padding-top: 1rem;
    text-align: justify;
}


/* .SpaceRight {
    flex: 1;
} */

.DescriptionTitle {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 1px;
    color: var(--helper-text-color);
    margin-bottom: 1.5rem;
}

.DescriptionBody {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
    padding-bottom: 1rem;
}

.CommentTitle {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: var(--h-1-black-new);
}

.CommentCount {
    font-weight: normal;
}

.InputComment {
    display: flex;
    border: 1px solid var(--tef-grey);
    border-radius: 0.25rem;
    max-width: 60%;
    align-items: center;
    padding: 0.2rem;
}

.ViewComments {
    max-width: 60%;
}

.AvatarContainer {
    flex: 0 0 10%;
}

.EditorBoxContainer {
    flex: 0 0 70%;
}

.EditorBox {
    width: 95%;
    border: none;
    outline: none;
}

.EditorBox textarea:active,
.EditorBox textarea:focus {
    border: none;
    outline: none;
}

.EditorBox::placeholder {
    line-height: inherit;
}

.ShareButton {
    flex: 1;
    background-color: var(--tef-red);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    padding: 0.2rem 0;
    border-radius: 0.25rem;
    cursor: pointer;
}

.DisabledShareButton {
    flex: 1;
    background-color: var(--tef-red);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    padding: 0.2rem 0;
    border-radius: 0.25rem;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.5;
}

.ShareButton:focus {
    outline: none;
}

.ShareButton:active {
    transform: translateY(2px);
}

.MobileWebsiteButton {
    display: none;
}

@media(max-width: 480px) {
    .SubTitle,
    .WebsiteButton {
        display: none;
    }
    .MobileWebsiteButton {
        display: block;
        margin-left: 1rem;
        width: 100%;
    }
    .ShareOnSocialMedia {
        justify-content: space-between;
        font-size: 12px;
    }
    .TwitterButton,
    .LinkedButton {
        width: 55%;
    }
    .TopRowDetailBody {
        margin-left: 0.5rem;
        flex: 0 0 70%;
    }
    .DescriptionContent {
        flex: 0 0 60%;
    }
    .EditorBox {
        font-size: 12px;
        margin: 0 0.5rem;
    }
    .ShareButton {
        font-size: 14px;
    }
}