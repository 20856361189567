.CertificateCard {
    display: flex; 
    background-color: #ffffff;
}

.CertificateImgContainer {
    padding: 20px;
}

.CertificateImgContainer img{    
    width: 200px;
    height: 150px;
}

.CertificateDetails {
    width: 100%;
}

.Topic {
    color: #BDC3CC;
    font-weight: 700;
}

    
