@media only screen and (min-width: 992px) {
    .profile-nav-card {
      width: 100%;
      height: 71px;
      border: solid 1px var(--new-faded-white);
      border-left: none;
      padding: 0;
      z-index: 0;
    }
  
    .profile-tab-links {
      height: 70px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.2px;
      text-align: center;
      padding-top: 25%;
      padding-left: 0px;
      padding-right: 0px;
      color: var(--helper-text-color);
      border-left: 2px solid var(--new-faded-white) ;
      width: 124%;
      // width: 100%;
    }
  
    .profile-tab-links.nav-link.active {
      color: var(--tef-red);
      border-bottom: 2px solid var(--tef-red);
    }
    
  }
  