.hide-comment {
  display: none;
}

.comment {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  max-width: 100%;
  min-height: 108px;
  border-radius: 4px;
  background-color: var(--white);

  & h2 {
    margin-top: 10px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #44444f;

    & span {
      color: var(--blue-grey);
    }
  }

  & .more-comments {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #696974;

    height: 24px;
    border-radius: 6px;
    background-color: #eaedf3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.comment-form {
  height: 56px;
  border-radius: 4px;
  background-color: var(--white);
  margin-bottom: 12px;
  margin-top: 24px;

  display: flex;
  align-items: center;
}

.comments-div {
  margin-top: 12px;
}
