.NotificationBody {
  display: flex;
  cursor: pointer;
  font-family: Poppins;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  /* text-transform: capitalize; */
  color: var(--h-1-black-new);
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  align-items: center;
}

.NotificationBody:hover {
  text-decoration: none;
  background-color: #eee;
  color: var(--tef-red);
}

.Avatar {
  flex: 0 0 20%;
}

.Message {
  flex: 1;
}

.IsUnread {
  border-right: 0.25rem solid var(--tef-red);
}

/* .IsUnread {
    flex: 1;
    background-color: var(--tef-red);
    border-radius: 50%;
    width: 3px;
} */

.Notify {
  font-size: 0.7rem;
}

.Who {
  font-size: 0.8rem;
  font-weight: 600;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.When {
  font-size: 0.6rem;
}
