.results-main {
  border-radius: 4px;
  min-height: 100vh;
  padding: 22px 20px 20px 20px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;

  &__heading {
    margin-bottom: 15px;
    max-width: max-content;

    &--back {
      max-width: max-content;
      display: flex;
      align-items: center;
      font-family: SFProText;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #637381;
    }

    &--title {
      text-transform: capitalize;
      font-family: SFProText;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: var(--h1-black-new);
    }
  }

  &__loader {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__pagination {
    // width: 100%;
    overflow: scroll;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    &--bottom {
      margin-top: 15px;
    }
  }

  &__results {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;

    &--no-group {
      font-family: SFProText;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #637381;
    }
  }
}
