.sections {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Poppins;
}

.Banner {
  display: none;
}

.Banner img {
  display: none;
}

.sectionContainer {
  background-color: white;
  padding: 0.5rem 2rem;
  width: 100%;
  height: 100%;
  margin: 0.5rem 2.5rem 0.5rem 0.5rem;
  text-align: justify;
}

.Header {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 95%;
  padding: 2rem;
}

/* .HeaderLeft {
	flex: 0 0 100%;
} */

.HeaderLeft .MainTitle {
  line-height: 1.11;
  letter-spacing: -0.2px;
  font-weight: 600;
}

.HeaderLeft .Tag {
  margin: 1.2rem 0;
  width: 95%;
}

.PostedBy {
  display: flex;
  max-height: 1rem;
  margin: 1.5rem 0;
}

.ShareButton {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.HeaderRight {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 1rem;
}

.ApplyAndReminder {
  display: flex;
  flex: 0 0 66%;
  margin-right: 0.8rem;
}

.ApplyButton {
  flex: 0 0 50%;
}

.ApplyNow,
.ApplyNow:hover,
.ApplyNow:visited {
  text-decoration: none;
}

.ReminderContainer {
  width: 100%;
  height: 3rem;
  border-radius: 4px;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NotReminded {
  background-color: #eef1f5;
}

.Reminded {
  background-color: var(--pre-green);
}

.Reminder {
  background-color: white;
  width: 95%;
  height: 65%;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
}

.Reminder p {
  margin: 0;
  font-size: 0.55rem;
}

.EditOpportunity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.EditOpportunity .Edit {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
}

.EditOpportunity .Edit p {
  margin-bottom: 0;
  font-size: 0.55rem;
}

.Uneditable {
  font-size: 0.75rem;
  cursor: pointer;
}

.Summary {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.SummaryText {
  margin-bottom: 2.5rem;
}

.SummaryHighlight {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2rem;
}

.RelatedOpportunities {
  /* background-color: transparent; */
  width: 95%;
}

.Link,
.Link:hover,
.Link:active,
.Link:visited {
  text-decoration: none;
  display: flex;
  align-items: flex-end;
}

.Option {
  display: block;
  padding: 1rem 2rem;
  cursor: pointer;
  text-align: center;
}

.Option:hover {
  background-color: #fff5f4;
}

.Flagged {
  text-align: center;
  background-color: var(--tef-red);
  color: white;
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.showCommentText {
  cursor: pointer;
}

.Comments {
  width: 95%;
}

@media (min-width: 481px) {
  .Banner {
    display: flex;
    justify-content: center;
    width: 95%;
    height: 377px;
  }
  .Banner img {
    display: flex;
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: 768px) {
  .section {
    padding: 2% 4%;
  }
  .Banner {
    max-width: 100%;
    max-height: 100%;
  }
  .Banner img {
    object-fit: cover;
    width: 100%;
    display: flex;
    /* transform: scale(1); */
  }
  .Header {
    display: flex;
    flex-direction: row;
    margin-top: 2.5rem;
  }
  .HeaderLeft {
    flex: 0 0 60%;
  }
  .HeaderRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  .ApplyAndReminder {
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
  }
  .ApplyButton {
    margin: 1rem 0;
    flex: 0 0 10%;
  }
  .ReminderContainer {
    width: 7rem;
    height: 2rem;
    font-size: 0.8rem;
  }
  .Reminder {
    font-size: 0.5rem;
  }
  .Edit {
    display: flex;
  }
  .EditOpportunity {
    /* padding-right: 3rem; */
    width: 10rem;
  }
}
