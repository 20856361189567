.Transcriptions {
  display: flex;
  margin: 2rem 0;
}

.Time {
  flex: 0 0 12%;
}

.Content {
  flex: 1;
}

.SelectLanguage {
  border: 1px solid #c4cdd5;
  box-sizing: border-box;
  border-radius: 4px;
  width: 30%;
}
