.goals-card {
  width: 96%;
  height: 100%;
  border-radius: 4px;
  border: solid 1px #e6eaee;
  margin-top: 39px;
  border-top: 4px solid #ffa49d;
  display: inline-block;
  padding: 20px auto;
  background-color: #fafbfb;
  margin-left: 18px;
}
.goals-container {
  width: 100%;
  // // text-align: center;
  // margin-left: 10px;
  // margin-right: 10px;
}
.goals-heading {
  height: 36px;
  font-family: Inter;
  font-size: 18px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--black);
}
.goals-show-more {
  width: 163px;
  height: 41px;
  border-radius: 6px;
  background: var(--very-light-blue);
  border: none;
  margin-left: 35%;
  margin-top: 35px;
  margin-bottom: 35px;
  color: black;
}

// mentorship view
.g-container-main {
  padding: 18px 20px;
  margin: 30px 0px;
  background-color: #f6f8fc;
  border-top: 4px solid #ffa49d;
  border-radius: 4px;
  width: 100%;
  //  border: solid 1px #e6eaee;
}

.goals-tab {
  &-c {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    // margin: 10px 0px 10px 0px;
  }
  &-ind {
    margin-right: 40px;
    cursor: pointer;
    padding: 10px 0px;
    align-self: center;
    overflow: hidden;
    &:hover {
      color: var(--helper-text-color);
    }
    transition: 0.2s ease-in-out;
    height: 100%;
  }
  &-active-item {
    border-bottom: 2px solid var(--tef-red);
    color: var(--tef-red);
    &:hover {
      color: var(--tef-red);
    }
  }
}

.mentee-info {
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  background-color: white;
  padding: 10px;
  // height: 250px;
  min-height: max-content !important;
  & h1 {
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--tef-grey);
    margin-bottom: -30px;
    align-self: center;
    text-align: center;
    margin-bottom: -5px;
    // margin-top: 10px;
  }
}

.select-mentee-c {
  // margin:0 auto;
  // width: 100%;
  // background-color: white;
  // padding: 5px;
  // border: solid 1px #e6eaee;
  // display: flex;
  // border-radius: 4px;
  // // justify-content: center;
  // // align-items: center;
  // // flex-direction: column;
  // position: relative;
  margin: 10px 0px;
  padding: 10px;
}

.selected-mentee {
  // width: 50%;
  border-radius: 4px;
  color: var(--tef-grey);
  // border: solid 1px #e6eaee;
  min-width: 70px;
  position: relative;
  padding: 3px 10px;
}

.mentee-list {
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  // color: var(--tef-grey);
  border: solid 1px #e6eaee;
  position: relative;
  min-width: 300px;
}

.mentee-list-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6eaee;
  border-top: 1px solid #e6eaee;
  padding: 5px;
}

.goals-item-c {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.selected-i {
  cursor: pointer;
}
