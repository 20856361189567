.community-group-home {
  display: flex;

  &__side {
    flex: 0 0 30%;
  }

  &__main {
    flex: 0 0 70%;
    padding-right: 14px;
    padding-left: 14px;

    &--container {
      width: 100%;

      .no-post {
        margin: 10% auto;
        max-width: 80%;
        font-size: 40px;
        font-family: Poppins;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.2px;
        color: var(--helper-text-color);
        text-align: center;

        &__sub {
          font-size: 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    &__main {
      flex: 0 0 100%;
    }
  }
}

.no-member {
  margin: 0 auto;
  max-width: 80%;
  font-size: 13px;
  font-family: Poppins;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.2px;
  color: var(--helper-text-color);
  text-align: center;
}

@media screen and (max-width: 940px) {
  .community-group-home {
  }
}
