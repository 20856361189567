.opp{
  // padding:0px 19px 0px 32px;
}
.opp-tab-navs{
  // display: flex;
  margin-top: 39px;
  margin-left: 5px;
  margin-right:5px;
  // align-items: center;
  // flex-wrap: wrap;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
}
.opp-home-heading{
  display: flex;
  justify-content: space-between;
  margin:10px 0px;
  position: relative;

  & h1{
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: 0.2px;
    text-align: center;
    color: var(--h-1-black-new);
  }
}

.link-create{
  padding: 5px 10px;
  background-color: var(--tef-red);
  color: white;
  border-radius: 20px;
  position:sticky;
  &:hover{
    cursor: pointer;
    opacity: .5;
    color: white;
    text-decoration: none;
  }
}

.st-dp{
  &:focus{
    outline: none;
  }
}

.label-dp{
  color: var(--helper-text-color);
  font-size: 14px;
  font-weight: 100;
}
.opp-tabs-title{
  color: var(--helper-text-color);
  font-size: 15px;
  font-weight: 600;
}