.side-info-container {
  display: flex;
  flex-direction: column;

  &__group-details {
    min-height: 371px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--white);
    padding: 0 25px;

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &--more {
      margin-top: 10px;
      align-self: flex-end;
      cursor: pointer;
    }

    &--icon {
      width: 108px;
      height: 108px;
      border-radius: 21px;
      border: solid 5.6px var(--white);
      margin-bottom: 18px;
      background-color: #f0f0f0;
      object-position: 50% 50%;
      object-fit: cover;
    }

    &--title {
      font-family: Poppins;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: var(--h-1-black-new);
      margin-bottom: 4px;
      text-align: center;
      text-transform: capitalize;
    }

    &--sub-title {
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.2px;
      color: var(--helper-text-color);
      text-transform: capitalize;
      margin-bottom: 17px;
    }

    &--info {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border-top: 1px solid #f6f8fc;
      width: 100%;
      padding-top: 20px;
    }

    &--button {
      margin-top: 13px;
      margin-bottom: 13px;
      width: 100%;
      height: 38px;
      border-radius: 6px;
      background-color: var(--tef-red);
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      text-transform: capitalize;
      color: #fafafb;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      transition: all 0.2;

      &:focus {
        outline: none;
      }

      &:active {
        transform: translateY(2px);
      }
    }
  }
}

.side-info {
  &__posts {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;
    color: #44444f;
  }

  &__label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    color: var(--helper-text-color);
  }
}

.created-by-info {
  margin-top: 13px;
  min-height: 170px;
  padding: 10px 0;
  border-radius: 6px;
  background-color: var(--white);
  padding-right: 12px;
  padding-left: 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  &__title {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: var(--tef-grey);
  }

  &__details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    &--image {
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }

    &--name-details {
      text-align: center;
      & h2 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.11px;
        color: #171725;
        text-transform: capitalize;
        word-break: break-word;
      }

      & h6 {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--bluey-grey);
      }
    }

    &--button {
      width: 110px;
      height: 38px;
      border-radius: 6px;
      background-color: var(--tef-red);
      border: 0;
      transition: all 0.2s;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      text-align: center;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      &:focus {
        outline: none;
      }

      &:hover {
        text-decoration: none;
        background-color: white;
        color: red;
        border: 1px solid red;
      }

      &:active {
        transform: translateY(2px);
      }
    }
  }
}

.group-about {
  margin-top: 13px;
  margin-bottom: 13px;
  display: flex;
  flex-direction: column;
  // height: 370px;
  height: 180px;
  border-radius: 6px;
  background-color: var(--white);

  &__title {
    border-bottom: 1px solid #f1f1f5;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: inherit;
    padding-left: 20px;
    padding-right: 20px;

    &--info {
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.2px;
      color: var(--tef-grey);
    }

    &--info-group {
      & h4 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: var(--helper-text-color);
      }

      & h6 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.1px;
        color: var(--h-1-black-new);
      }
    }
  }
}

.group-topics {
  margin-top: 13px;
  margin-bottom: 13px;
  height: 190px;
  border-radius: 4px;
  border: solid 1px var(--new-faded-white);
  background-color: var(--white);

  &__title {
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 40px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    color: #171725;
  }

  &__info {
    border-top: 1px solid #f1f1f5;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 20px;
    height: 134px;

    & p {
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: 0.4px;
      color: var(--tef-grey);
      margin-bottom: 0;
    }

    & button {
      width: 124px;
      height: 28px;
      border-radius: 4px;
      background-color: var(--tef-grey);
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      text-align: center;
      color: #fafafb;
      border: 0;
      transition: all 0.2s;

      &:focus {
        outline: none;
      }

      &:active {
        transform: translateY(2px);
      }
    }
  }
}

.details-disabled-btn {
  pointer-events: none;
}

// @media screen and (max-width: 940px) {
// }
