.uploaded-documents p{
  font-size: 16px;
  font-weight: 700;
}

.btn-outline-secondary {
  color: #A4A5A6;
  border-color: #A4A5A6;
}

.submit-document-container button {
  width: 12em;
  margin-right: 10px;
}

.rvtbn {
  background-color: #f0f0f0 !important;
  border: 1px solid transparent;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.625rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
