.profile-status {
  padding: 15px 20px 23px 20px;
}
.profile-status-heading {
  height: 19px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  color: var(--h-1-black-new);
  margin-bottom: 26px;
}
.profile-status-percentage {
  // height: 16px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--tef-grey);
  position: relative;
  bottom: 5px;
}
@media only screen and (max-width:767px) {
  .profile-status-percentage {
    text-align: center;
    margin: 10px;
  }
}
