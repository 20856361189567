@media screen and (max-width: 996px) {
  .application-sidebar {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }

  .side-logo {
    // width: 50%;
  }

  .sub-topics {
    width: inherit;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul > li {
    list-style: none;
  }

  .number,
  .side-logo,
  .topic-name,
  .side-heading,
  .countdown-timer {
    display: none;
  }
}

.side-active {
  color: #da291c;
  font-weight: 600;
  font-size: 16px;
}

// tablet
// @media (min-width: 768px) {
// }

// desktop
@media (min-width: 1024px) {
  .side-logo {
    margin-bottom: 20px;
    width: 100%;
  }

  .side-heading {
    font-family: SFProText;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #26292d;
  }

  .countdown-timer {
    height: 50px;
    margin-top: 8px;
    margin-bottom: 48px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(45, 51, 58, 0.16);
    background-color: #da291c;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .countdown-timer > h3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;

    font-family: SFProText;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;

    span {
      font-family: SFProText;
      font-size: 6px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }

  .sub-topics {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .number {
      align-self: start;
      width: 27.8px;
      height: 27.8px;
      border: solid 3.2px #feefef;
      background-image: linear-gradient(to bottom, #ef533c, #da291c);
      border-radius: 50%;
      font-family: Oxygen;
      font-size: 12.8px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.75;
      letter-spacing: normal;
      color: #ffffff;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .topic-name {
      margin-left: 15px;
      font-family: Poppins;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #da291c;
    }

    ul {
      margin-left: 15px;
      padding-left: 0;
    }
    ul > li {
      list-style: none;
    }
  }

  .application-sidebar {
    padding: 24px 16px 0 16px;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
  }
}

.logout-link {
  font-size: 16px !important;
  letter-spacing: 2px !important;
  text-transform: uppercase;
}

.logout-link:hover {
  font-size: 18px !important;
}
