.container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  position: relative;
  @media only screen and (max-width:768px) {
    flex-direction: column;
  }
}

.left::before,
.right::before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
}

.left,
.right {
  width: 50%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    transform: scale(1.1);
    &:before {
      display: block;
    }
  }

  @media only screen and (max-width:768px) {
    width: 100%;
    height: 50vh;
  }
}
.left {
  background-image: url("../../../assets//img/onbording/entrepreneur.webp");
}

.right {
  background-image: url("../../../assets//img/onbording/mentor.webp");
}

.role_container {
  position: absolute;
  bottom: 11.4%;

  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  &__text {
    color: #fff;
    font-weight: bold;
    font-size: 36px;
    font-family: Poppins;
  }
}

.headings {
  display: flex;
  position: absolute;
  justify-content: space-between;
  left: 0;
  right: 0;
  top: 6%;
  z-index: 1;
  padding: 0 34px;
  // background-color: green;
  @media only screen and (max-width:768px) {
    flex-direction: column;
  }

  &_right {
    display: flex;
    flex-direction: column;
    color: white;

    @media only screen and (max-width:768px) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
}

.tef_logo {
  height: 45px;
  @media only screen and (max-width:768px) {
    height: 30px;
  }

  @media only screen and (max-width:568px) {
    height: 25px;
  }
}

._step {
  font: normal normal 900 25px Poppins;
  color: #f2f4f7;
  margin-top: 15px;
  @media only screen and (max-width:568px) {
    font-size: 15px;
  }
  &_1 {
    color: var(--tef-red);
    font: normal normal 900 25px Poppins;
    @media only screen and (max-width:568px) {
      font-size: 15px;
    }
  }
  &_2 {
    font: normal normal bold 15px Poppins;
    @media only screen and (max-width:568px) {
      font-size: 11px;
    }
  }
}
.choose_text {
  font: normal normal 900 36px Poppins;
  color: #ffffff;
  margin-top: 10px;
  @media only screen and (max-width:568px) {
    font-size: 25px;
  }
}

.choose_text_paragraph {
  font: normal normal medium 15px Poppins;
  letter-spacing: 0px;
  color: #f2f4f7;
  margin-top: -10px;

  @media only screen and (max-width:568px) {
    font-size: 12px;
  }
}

.loader_container {
  // height: 100vh;
  // width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}
