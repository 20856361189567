body {
  overflow-x: hidden;
  font-family: "Poppins" !important;
}

.alumni__opportunity {
  border: none;
  position: relative;
}
.opp-home-heading {
  align-items: center;
}
.alumni-opportunity-imgbox {
  min-height: 360px;
  height: 365px;
  position: relative;
}
.opp-home-heading-mobile {
  display: none;
}
.add-opp-box::after {
  content: "";
  clear: both;
  display: table;
}
.sort-by {
  border-right: 1px solid #919ba8;
  padding: 10px;
  background-color: white;
  font-size: 14px;
  font-family: "Sp";
}
.page-name {
  font-size: 24px;
  letter-spacing: 0px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 0px;
  line-height: 25px;
}
.opp-card-txt {
  padding: 20px;
}
.opportunity__slide-wrap {
  position: absolute;
  display: flex;

  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-bottom: 10px;
  bottom: 0%;
  z-index: 1000;
}
.alumni-opportunity-imgbox__btn--featured {
  background: none;
  border: 1px solid #fff;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 12px;
  margin-bottom: 20px;
}
.alumni-opportunity-imgbox__title--para {
  font-size: 40px;
  line-height: 50px;
  color: #fff;
  font-weight: 600;
}
.opportunity__slide-wrap {
  width: 50%;
}

// .opportunity__slide-wrap {
//   position: absolute;
//   left: 3%;
//   bottom:30px
// }

.keyword-title {
  color: rgb(184, 191, 199);
}
.upload-wrapper {
  display: flex;
}
.upload-wrapper .opp-create-form__image-c {
  width: 90%;
  margin-right: 15px;
}
.upload-image-btn {
  background-color: #fff !important;
  border-radius: 5px !important;
  padding: 10px 23px !important;
  color: #da291c !important;
  font-weight: bold !important;
}
.upload-image-label {
  font-size: 20px !important;
  font-weight: bold;
}
.photo-icon-box {
  position: absolute;
  top: 23%;
  left: 4%;
}
.fileformat {
  color: #919ba8;
  font-size: 14px;
}
.sort-by-dropdown {
  background: none;
  background-color: white;
  color: black;
  border: none;
  border-left: 1px solid rgb(240, 240, 240);
}
.alumni-opportunity-imgbox__metaData {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.view-opportunity {
  align-self: center;
}
.meta-info {
  margin-right: auto;
}
.alumni-opportunity-imgbox__icon {
  margin-right: 15px;
}

.opport-deadline-fund {
  font-size: 16px;
}
.search-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}

.search-boxes {
  width: 35%;
  display: flex;
  align-items: center;
}
.search-cate {
  background-color: #fff;
  padding: 12px;
  color: rgb(184, 191, 199);
}

.opportunity {
  &__short-desc {
    font-size: 14px;
    line-height: 25px;
    width: 40%;
    font-weight: bold;
  }
  &__img {
    height: 160px;
  }

  &__title {
    font-weight: bold;
    color: #26292d;
    font-size: 18px;
    line-height: 23px;
  }
  &__posted {
    display: flex;
    justify-content: space-between;
  }

  &__icon {
    margin-right: 10px;

    padding: 3px;
    background-color: #e2e5ec;
    border-radius: 5px;
  }

  &__time {
    font-size: 12px;
  }
}
.deadline-date-val {
  font-size: 12px;
  font-weight: bold;
}
.create-opportunity {
  padding: 10px;
}

#deadline .react-datepicker-component .react-datepicker-input input {
  background-color: #f0f0f0;
}
.opportunity__feature {
  background-color: white;
  padding: 15px;
}
.opportunity__feature__tip {
  background-color: #f4f5f7;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.opportunity__content-tip {
  text-align: center;
  padding: 10px;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}
.create-header-cancel {
  cursor: pointer;
}
.no-border {
  border: none !important;
}
.no-border .react-datepicker-input {
  background-color: #efefef !important;
  border: none !important;
}
.special-feature {
  color: rgb(212, 35, 22);
}
.opportunity__feature--title,
.opportunity__content-tip {
  font-weight: bold;
  font-size: 13px;
  text-transform: capitalize;
}
.opportunity__feature--para {
  font-size: 12px;
  color: #919ba8;
}
.country-background-color {
  background-color: #efefef;
}

.opportunity__content-tip {
  text-align: center;
}
.alumni__only {
  display: inline-block;
  background-color: rgb(212, 35, 22);
  color: white;
  position: absolute;
  top: 38%;
  left: 0;
  border-top-right-radius: 25px;
  border-radius: 0px 20px 20px 0px;
  padding: 5px 8px;
  // border-radius: 15px 50px 30px 5px;
  // padding-right: 15px;
}

.article-detail {
  &__video {
    width: 75px;
    max-width: 100%;
    height: 60px;
    margin-right: 12px;
    object-fit: cover;
    border-radius: 5px;
  }

  &__details > h6 {
    line-height: 1.57;
    font-size: 14px;
    color: var(--helper-text-color);
  }
}

.tab-border {
  border-left: 1px solid #919ba8;
  height: 0.8rem;
  margin-top: 4px;
}

.video-card {
  border-radius: 4px;
  border: solid 1px var(--new-faded-white);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  cursor: pointer;
}

.post-item {
  h1 {
    line-height: 1.57;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
  }

  h2 {
    line-height: 1.8;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.2px;
  }

  h3 {
    line-height: 1.57;
    font-size: 12px;
    font-weight: 600;
    color: var(--helper-text-color);
  }

  h4 {
    line-height: 1.9;
    font-size: 10px;
    font-weight: 600;
    color: var(--helper-text-color);
  }

  &__1 {
    top: 180px;
    left: 20px;
    width: 93%;
  }
  .user-avartar {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.category-image {
  height: 280px;
  width: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.post-list-item {
  border-radius: 3.6px;
  box-shadow: 0 2px 4px 0 rgba(145, 155, 168, 0.06);
  background-color: var(--white);
  margin: 15px 3.5px;
  max-width: 324px !important;
  display: flex;
  flex-direction: column;
  min-height: 246px;
  cursor: pointer;

  &__image {
    height: 150px;
    width: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px 4px 0px 0px;
    }
  }

  h3 {
    line-height: 1.57;
    font-size: 10.5px;
    font-weight: 600;
    color: var(--helper-text-color);
  }

  h1 {
    line-height: 1.57;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
  }

  h2 {
    line-height: 1.9;
    font-size: 8.6px;
    font-weight: bolder;
    // letter-spacing: 0.2px;
    color: var(--h-1-black-new);
  }

  h4 {
    line-height: 1.9;
    font-size: 9px;
    font-weight: 600;
    color: var(--helper-text-color);
  }

  .user-avartar {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.all-articles {
  background-color: var(--white);
  border-radius: 4px;
  padding: 0.5rem 0.7rem;

  h1 {
    color: var(--h-1-black-new);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.2px;
  }

  &__length {
    color: var(--helper-text-color);
  }

  .submit-article {
    background-color: var(--tef-red);
    color: var(--white);
    font-weight: bold;
    padding: 0.3rem 0;
    border-radius: 3px;
    font-size: 0.8rem;
    cursor: pointer;
    margin: 0 auto;
  }
}

.username {
  color: var(--helper-text-color);
  font-size: 0.6rem;
  font-weight: bold;
}

.date-posted {
  color: var(--helper-text-color);
  font-size: 0.7rem;
}

.separator {
  border-left: 1px solid #919ba8;
  font-size: 0.6rem;
}

.save-btn,
.share-btn {
  font-size: 0.8rem;
  color: var(--h-1-black-new);
}

.view-tef-article {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  padding-top: 32px;
  background-color: var(--white);
}

.follow-btn {
  background-color: var(--new-faded-white);
  padding: 8px 12px;
  border-radius: 12px;
  cursor: pointer;
  font-weight: bold;
}

.writer-avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.related-articles {
  background-color: var(--new-faded-white);
  border-radius: 8px;
  padding: 10px;
}

.share-icon {
  padding: 4px 6px;
  border: 1px solid var(--new-faded-white);
  width: 28px;
  border-radius: 5px;
}

.article-tag {
  padding: 4px 12px;
  border: 1px solid var(--new-faded-white);
  border-radius: 5px;
}

.download-format {
  padding: 4px 12px;
  border: 1px solid var(--new-faded-white);
  border-radius: 5px;
  margin-top: 16px;
}

.download-article-btn {
  background-color: var(--tef-red);
  color: var(--white);
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 4px;
}

.cancel-download-btn {
  background-color: var(--new-faded-white);
  color: var(--helper-text-color);
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 4px;
}

.download-format input[type="radio"]:checked {
  border: 1px solid var(--tef-red);
  background-color: var(--tef-red);
  border-color: var(--tef-red);
}
post-list-item h2 {
  font-size: 10px;
  line-height: 1.5px;
  margin-left: 10px;
}
.btn-drop {
  background: none;

  background-color: white;
  color: gray;
  border: none;
  margin-right: 20px;
}

input::placeholder {
  font-size: 14px;
  color: #f0f0f0;
}
.create-opp-banner {
  padding: 20px;
}
.create-short-para {
  background-color: #fff;
  padding: 10px;
}
.create-para {
  display: flex;
  font-size: 15px;
  line-height: 15px;
  flex-wrap: wrap;
}
.create-para-desc {
  flex: 0 0 47%;
}
.left-create-para {
  margin-right: 10px;
}

.create-input {
  display: flex;
  align-items: center;
}
.count-length {
  margin-left: -40px;
  z-index: 99;
}
.count-length.textarea-count-length {
  align-self: flex-end;
}
.opp-home-heading-full {
  margin-bottom: 20px;
  margin-top: 20px;
}

.alumni-opp-list {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  // justify-content: space-between;
}
.alumni-opp-list-item {
  width: 30%;
}

.alumni-opp-list-item {
  margin-right: 5%;
}
.alumni-opp-list-item:nth-child(3n + 0) {
  margin-right: 0;
}

.opp-meta-deaddline {
  display: flex;
  justify-content: space-between;
}

.opp-meta-opportunity__icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.opp-card-txt {
  padding: 20px;
}
.title-mobile {
  display: none;
}
.title-full {
  display: block;
}
.alumni__only-mobile {
  display: none;
}

.custom-dialog > .rs-drawer-content {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.drawer-search {
  display: none;
}
.custom-opp-select {
  width: 100%;
  border: none;
}

.opp-select__control {
  border: none !important;
  border-radius: 0 !important;
  padding: 8px !important;
}
.opp-select__placeholder {
  color: rgb(0, 0, 0) !important;
}
.opp-search-cate {
  padding: 18.5px;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #9ea7b2;
  width: 40%;
  margin-right: -2px;
}
.opportunity__short-desc {
  font-size: 25px;
  font-weight: bold;
}
.deadline-fund .deadline-date-val {
  font-size: 15px;
}
@media screen and (max-width: 790px) {
  .drawer-search {
    display: block;
  }
  .title-full {
    display: none;
  }
  .title-mobile {
    display: block;
    font-size: 14px;
  }
  .deadline-fund .deadline-date-val {
    font-size: 13px;
  }
  .opportunity__time {
    margin-right: auto;
    color: rgb(0, 0, 0);
  }
  .opp-home-heading {
    display: none;
  }
  .opportunity__short-desc {
    width: 100%;
  }
  .opp-meta-deaddline {
    flex-direction: column;
  }
  .opp-meta-opportunity__icon {
    justify-content: flex-start;
  }
  .opp-card-txt {
    padding: 5px;
  }
  .opportunity__img {
    height: 120px;
  }
  .alumni-opp-list {
    display: flex;
    flex-wrap: wrap;
    align-self: start;
    width: 100%;
    justify-content: space-between;
  }
  .alumni-opp-list-item {
    display: flex;
    flex: 0 0 48%;

    margin-bottom: 10px;
  }
  .alumni-opp-list-item {
    margin-right: 0;
  }
  .alumni-opp-list-item:nth-child(3n + 0) {
    margin-right: 0;
  }
  .opportunity__slide-wrap {
    bottom: 10%;
  }
  .alumni-opportunity-imgbox__title--para {
    font-size: 29px !important;
    line-height: 39px;
    font-weight: bold;
  }
  .opp-home-heading-mobile {
    display: flex;
    padding: 20px;
    align-items: center;
  }
  .opport-deadline-fund {
    font-size: 15px;
  }
  .view-opportunity {
    width: 100%;
    padding: 10px;
  }
  .opportunity__mobile-desc {
    font-size: 20px;
    font-weight: bold;
    margin-right: auto;
    text-transform: capitalize;
  }
  .alumni-opportunity-imgbox__title {
    width: 100%;
  }
  .opportunity__slide-wrap {
    margin: 0;
    padding: 20px;
    transform: translateY(10%);
    width: 100%;
  }
  .opportunity__slide-wrap > * {
    margin-bottom: 15px;
  }
  .mobile-add {
    background-color: #da291c;
    padding: 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-left: 27px;
  }
  .alumni-opportunity-imgbox__btn--featured {
    margin-bottom: 40px;
    background: none;
    border: 1px solid #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
    margin-bottom: 20px;
  }
  .alumni-opportunity-imgbox {
    height: 500px;
    display: flex;
    flex-direction: column;
  }
  .alumni-opportunity-imgbox__metaData {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    width: 100%;
  }
  .alumni-opportunity-imgbox__metaData > *:not(:last-child) {
    margin-bottom: 25px;
  }
  .search-select {
    width: 100%;
    display: block;
    margin-bottom: 8px;
  }
  .add-opp-box {
    display: none;
  }
  .search-boxes {
    width: 100%;
    margin-bottom: 18px;
  }

  .opp-meta-opportunity__icon {
    margin-bottom: 20px;
  }
  .deadline-date-text {
    font-size: 15px;
  }
  .alumni__only {
    display: none !important;
  }
  .alumni__only-mobile {
    text-align: center;
    background-color: rgb(212, 35, 22);
    padding: 10px;
    border: none;
    color: #fff;
    outline: none;
    font-size: 12px;
    display: block;
  }
  .drawer-search {
    background-color: rgba(0, 0, 0, 0.8);
    width: 0px;
    transform: translateX(0);
    height: 100%;
    z-index: 2000;
    top: 0;
    right: 0;
    position: fixed;
    padding: 20px 0;

    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    overflow: scroll;
    transform: translateX(100%);
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .show-drawer {
    transform: translateX(0%);
    width: 100%;
  }

  .hide-drawer {
    transform: translateX(100%);
    width: 100%;
  }

  .drawer-header {
    display: flex;
    align-items: center;
  }
  .close-drawer {
    margin-right: 35%;
    margin-left: 20px;
    display: inline-block;
    font-size: 30px;
  }
  .drawer-content {
    padding: 20px;
    color: #fff;
    z-index: 9000000;
  }
  .opp-select__indicator-separator {
    margin-bottom: 3px !important;
    margin-top: 3px !important;
  }
  .drawer-filter {
    text-align: center;
    font-size: 18px;
  }
  .drawer-body {
    padding: 20px;
  }
  search-boxes {
    margin-bottom: 30px;
  }
  .upload-wrapper {
    display: flex;
    flex-direction: column;
  }
  .upload-wrapper .opp-create-form__image-c {
    width: 100%;
  }
  .photo-icon-box {
    left: 2%;
  }
  // .custom-opp-select {
  //   width: 100%;
  //   border: none
  // }
  // search-boxes {
  //   margin-bottom: 30px
  // }
  // .opp-select__control {
  //   border: none !important;
  //   border-radius: 0 !important;
  //   padding: 8px !important
  // }
  // .opp-select__placeholder {
  //   color: rgb(0,0,0) !important;
  // }
  // .opp-search-cate{
  // padding: 18.5px;
  // font-size: 15px;
  // display:flex;
  // align-items: center;
  // color:#9EA7B2;
  // width: 40%;
  // margin-right: -2px;
  // }
}
