.UserReactions {
    width: 70%;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    box-sizing: border-box;
    font-size: 0.8rem;
    line-height: 3;
    vertical-align: baseline;
}

.ActionButton {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-right: 1rem;
    cursor: pointer;
}

.ActionButton:hover {
    color: var(--tef-red);
    font-weight: 600;
}

.Disabled {
    pointer-events: none;
}

@media (min-width: 550px) {
    .ActionButton {
        display: block;
        margin-bottom: 1rem;
    }
}