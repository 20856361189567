.community-category-card {
  width: 179px;
  height: 152px;
  border-radius: 4.9px;
  border: solid 0.8px var(--new-faded-white);
  background-color: var(--white);

  &__image {
    height: 57%;
    border-top-left-radius: 4.9px;
    border-top-right-radius: 4.9px;
    background-color: orangered;

    & .group-image-1 {
      object-position: 50% 50%;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__details {
    height: 43%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    & .category-card-details-h1 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.38px;
      color: var(--h-1-black-new);
    }

    & .category-card-details-h3 {
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.09px;
      color: var(--tef-grey);
    }
  }
}
