.create-tef-story-modal {
    // max-width: 500px;
    // height: auto;
    border-radius: 6px;
    background-color: var(--white);
}

.modal-title {
    width: 100%;
}

.create-tef-story {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: normal;
    color: #656565;
    margin-top: 24px;
    margin-left: 3px;
}

.create-tefstory-banner {
    background-color: #fff;
    margin-top: 10px;
    padding: 32px 4px 4px 37px;
    h1 {
        font-size: 24px;
        font-weight: 600;
    }
    h6 {
        font-size: 14px;
        font-weight: 400;
        color: var(--helper-text-color);
    }
    &__logo {
        & img {
            width: 100%;
            object-fit: cover;
        }
    }
}

.create-tefstory-form {
    background-color: #fff;
    padding: 32px 4px 4px 37px;
    font-size: 15px;
    .required {
        color: var(--helper-text-color);
        font-weight: 500;
    }
}

.tefstory-editor {
    background-color: #fff;
    padding: 16px 8px 12px 28px;
}

.submit-tef-story {
    background-color: var(--tef-red);
    color: var(--white);
    font-weight: bold;
    padding: 0.5rem 0.2rem;
    border-radius: 3px;
    font-size: 0.8rem;
    cursor: pointer;
}

.preview-btn {
    background-color: var(--new-faded-white);
    color: var(--helper-text-color);
    font-weight: bold;
    padding: 0.5rem 0.2rem;
    border-radius: 3px;
    font-size: 0.8rem;
    cursor: pointer;
}

.input-maxlength {
    color: var(--helper-text-color);
    font-size: 0.8rem;
}

.form-step {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: var(--tef-red);
    background-color: #f8d1d7;
}

.userFirstName {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0.3rem;
}

.StoryBanner {
    object-fit: cover;
    width: 100%;
    height: 50vh;
}

@media only screen and (max-width: 600px) {
    .create-tefstory-banner {
        &__logo {
            display: none;
        }
    }
}