.profile-card {
  margin-top: 20px;
  width: 100%;
}
.profile-card > div > h1 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.4px;
  color: var(--h1-black-new);
  margin: 22px 0 5px 30px;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
}
.profile-card > p {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: var(--h-1-black-new);
  margin: 22px 0 24px 30px;
}
.children-c {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: var(--h-1-black-new);
  margin: 22px 0 24px 30px;
}

.profile-card > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-icon {
  padding: 10px;
  display: flex;
  border-radius: 50%;
  // float: right;
  margin-top: 15px;
  margin-right: 20px;
  background-color: #fff;
  transition: ease-out 0.5s all;
  &:hover {
    // background-color: var(--tef-red)
    background-color: rgba(236, 140, 140, 0.7);
    // opacity: .5;
    color: #fff;
    cursor: pointer;
    font-weight: bolder;
  }
}
