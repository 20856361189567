.auth-layout {
  width: 100%;
  min-height: 100vh;
  background-color: white;

  &__main {
    min-height: 90vh;
  }

  &__link-main {
    border-top: solid 1px rgba(99, 102, 106, 0.3);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 10vh;
    list-style: none;
  }
}

.main-wrapper {
  min-height: 100vh;
  background-color: var(--white);
  font-family: Poppins;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  &__form-div {
    // height: 933px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__link-div {
    min-height: 91px;
    border: solid 1px var(--new-faded-white);
    padding-top: 33px;
  }

  &__main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    font-size: 14px;
    letter-spacing: 0.27px;
    color: blue;
  }

  .main-link-text,
  .main-link-text:hover {
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0.27px;
    // color: var(--tef-grey);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main-wrapper {
    &__link-div {
      padding-top: 16px;
    }

    &__main {
      justify-content: space-between;
      padding-left: 0px;
    }

    &__main > li {
      padding: 0 15px 14px 15px;
    }
  }
}

@media screen and (max-width: 600px) {
  .auth-layout {
    height: 100%;

    &__main {
      height: 85%;
    }

    &__link-main {
      height: 15%;
      padding-top: 10px;

      & > li {
        padding: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) {
  .auth-layout {
    min-height: 100vh;
    height: 100%;

    &__main {
      min-height: 70vh;
      height: 80%;
    }
    &__link-main {
      min-height: 30vh;
      height: 20%;
      flex-direction: column;

      & > li {
        padding: 0 20px;
      }
    }
  }
}
