.MasterclassDetails {
    background: #ffffff;
    padding: 5%;
}

.MasterclassBody {
    display: flex;
}

.MasterclassMainBody {
    flex: 0 0 70%;
    border-right: 1px solid #e1e1e1;
    padding-right: 2%;
}

.MasterclassComments {
    flex: 1;
    padding-left: 1%;
}

.TabHeader {
    font-size: 14px;
    line-height: 14px;
    color: var(--gray-dark);
}

.TabBody {
    font-size: 12px;
    line-height: 14px;
    color: var(--tef-grey);
}

.CommentInput {
    border: none;
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    height: 2.7rem;
    margin-bottom: 1.5rem;
}

.CommentInput:focus {
    outline: none;
}

.CommentCount {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}