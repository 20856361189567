.MoreViewers {
  width: 30px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--tef-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -12px;
  z-index: 5;
  border: 2px solid white;
}

.HowManyMore {
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}
