.container {
}
.question {
  margin-bottom: 40px;
  font-family: Poppins;
  // font-weight: bold;
}

.info {
  text-align: left;
  font: normal normal normal 14px/22px Poppins;
  letter-spacing: 0px;
  color: #6f6f79;
  opacity: 1;
}

.optionContainer {
  display: flex;
  margin-top: 21px;
  width: 50%;
  flex-direction: column;
  @media only screen and (max-width:500px) {
    width: 100%;
  }
}
.pollcardButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6e6e70;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  text-align: left;
  padding: 9px 20px;
  font: normal normal medium 15px/30px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

  &:not(:first-child) {
    margin-top: 15px;
  }
}
.actionBtns {
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: 20px;
  & span {
    margin-left: 20px;
    text-align: left;
    font: normal normal bold 14px/22px Poppins;
    letter-spacing: 0px;
    color: #6e6e70;
    opacity: 0.9;
    cursor: pointer;
  }
}
.vote {
  color: var(--tef-red) !important;
}

.reviewed {
  background-color: transparent;
  border: 1px solid #6e6e70;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  padding: 15px 0;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  // font: normal normal medium 15px/30px Poppins;
  // letter-spacing: 0px;
  // color: #000000;
  // opacity: 1;

  &:not(:first-child) {
    margin-top: 15px;
  }
}

.bar {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  // width: 101%;
  background-color: #dbd8d8;
  border-radius: 5px;
  display: flex;
  z-index: -1;
}

.optionText,
.resultText {
  font: normal normal medium 15/30px Poppins;
  letter-spacing: 0px;
  font-size: 15px !important;
  color: #000000;
  opacity: 1;
}

.optionText {
  margin-left: 20px;
}

.resultText {
  margin-right: 20px;
}

.loadingVote {
  font: normal normal medium 15/30px Poppins;
  font-size: 15px !important;
  color: #dbd8d8;
  opacity: 1;
}
