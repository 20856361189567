h2 {
  font-size: 1.5rem;
  line-height: 1.33;
  font-weight: bold;
}

p {
  margin-bottom: 10px;
  line-height: 1.33;
}

section {
  margin-bottom: 25px;
}

label {
  font-weight: bold;
  display: block;
  line-height: 1.5;
  margin-bottom: 5px;
  cursor: pointer;
}

.hint-text {
  font-size: 0.8rem;
  color: #777575;
}

.expandButton {
  cursor: pointer;
  display: block;
  /* width: 30%; */
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  border: none;
  background-color: #eaedf3;;
  color: #696974;
  font-size: 14px !important;
  padding: 0.5rem;
  transition: all 0.1s ease-in-out;
  padding-left: 10px;
  padding-right: 10px;
}
.expandButton:hover, .expandButton:active, .expandButton:focus {
  transform: translateY(0.2rem);
  border: none;
}

.specialText {
  position: relative;
  max-height: 100px;
  overflow: hidden;
  -webkit-transition: max-height 1s ease;
  transition: max-height 1s ease;
}
.specialText.-expanded {
  max-height: 100vh;
}

.specialText:not(.-expanded)::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(white));
  background: linear-gradient(rgba(255, 255, 255, 0), white);
}

@media(min-width: 481px){
  .expandButton{
    font-size: 1rem;
  }
}