.video-view-body {
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.navigation {
  width: 100%;
  margin: 10px;
  margin-left: 0px;
  margin-bottom: 30px;

  b {
    font-family: Poppins;
    text-transform: capitalize;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #333;
    margin-right: 5px;
  }

  span {
    font-family: Poppins;
    text-transform: capitalize;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.4px;
    color: #555;
  }
}

.video-player {
  width: 100%;
  height: 400px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;

  video {
    width: 100% !important;
    height: 400px;
  }
}

.page_description {
  h3 {
    font-family: Poppins;
    text-transform: capitalize;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.4px;
    color: #333;
  }

  h5 {
    width: 100px;
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #666;
    margin-left: 10px;
    margin-right: 10px;
  }

  p {
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    font-size: 11px;
    letter-spacing: 0.4px;
    color: #666;
  }

  &__like-btn {
    padding-right: 20px;

    b {
      font-family: Poppins;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-size: 9px;
      font-weight: 900;
      letter-spacing: 0.4px;
      color: #777;
      margin-left: 5px;
    }
  }
}

.video-view-content {
  &__recommended_container {
    margin-top: 10px;

    h6 {
      font-family: Poppins;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-size: 11px;
      font-weight: 800;
      letter-spacing: 0.4px;
      color: #777;
      margin-left: 15px;
    }
  }

  &__comment_container {
    margin-top: 20px;
    background: white;
    border-radius: 10px;
    padding: 10px;

    h6 {
      font-family: Poppins;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-size: 12px;
      font-weight: 800;
      letter-spacing: 0.4px;
      color: #777;
      margin-left: 5px;
      margin-bottom: 20px;
    }

    &__comment_list {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .create-comment-box {
    &__btn-component {
      text-align: right;
    }
  }
}
