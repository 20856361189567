.container {
  background-color: #f2f4f7;
  // // justify-content: space-between;
  height: 100vh;
  display: flex;
  @media only screen and (max-width:768px) {
    flex-direction: column-reverse;
  }
}

.formSection {
  padding: 48px 7%;
  flex: 1;
  @media only screen and (max-width:768px) {
    position: absolute;
    bottom: -50px;
    z-index: 1;
    left: 0;
    right: 0;
    padding: 0;

    // width: 100vw;
  }
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
.formContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000026;
  border-radius: 10px;
  padding: 36px 47px;
  // width: 642px;
  margin: 0 auto;

  @media only screen and (max-width:500px) {
    padding: 30px 25px;
  }
}
.logintitle {
  letter-spacing: 0px;
  color: #000;
  font: 900 36px/45px Poppins;
}
.signupHere {
  font: normal normal 900 15px/26px Avenir;
  letter-spacing: 0px;
  color: var(--tef-red);
}
.formLabel {
  letter-spacing: 0px;
  color: #6e6e70;
  opacity: 1;
  font-size: 14px;
  font: normal normal medium 14px/24px Poppins;
}
.f_row {
  margin-bottom: 30px;
}
.newUser {
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 20px;
  font: medium 14px/26px Avenir;
}
.carouselContainer {
  background-image: url("../../assets/img/login1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  flex: 1;
  flex: 1.4;
  // padding: 2px 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  &::before {
    content: "";
    height: inherit;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.9) 100%
    );
    width: 100%;
    padding: 0 !important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .carouselContentContainer {
    display: flex;
    // z-index: 999999;
    align-items: center;
    margin-top: auto;
    margin-bottom: 30px;
    justify-content: space-between !important;
    color: white;

    @media only screen and (max-width:768px) {
      margin-bottom: auto;
      margin-top: 500px;
      // min-height: 550px;
      flex-direction: column;
      padding: 4px 4%;
      // justify-content: flex-start;
      // align-items: flex-start;
    }
  }
  .carouselContentContainer__text {
    flex: 1;
    & h5 {
      font: normal normal bolder 24px/24px Poppins;
    }
    & p {
      font: normal normal bolder 16px/24px Poppins;
      color: rgba(255, 255, 255, 0.807);
    }
  }
  .carouselIndicatorContainer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
  .carouselIndicator {
    height: 15px;
    width: 15px;
    background-color: rgba(169, 206, 229, 0.5);
    border-radius: 50%;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  .activeCarousel {
    background-color: var(--tef-red) !important;
  }

  @media only screen and (max-width:768px) {
    // height: 50vh;
    position: relative;
    // flex: 1;
    background-attachment: fixed;
  }

  // & img {
  //   width: 100%;
  //   display: block;
  //   // width: 100vw;
  //   // height: 100vh;
  //   // im
  //   // aspect-ratio: ;
  //   object-fit: cover;
  //   // object-position: 50% 50%;
  //   object-position: bottom;
  //   // height: 100%;
  // }
}

.logoContainter {
  margin-bottom: 198px;
  @media only screen and (max-width:768px) {
    display: none;
  }
}
.forgot_password_text {
  letter-spacing: 0px;
  color: var(--tef-red);
  opacity: 1;
  margin-bottom: 50px;
  font: normal normal 900 16px/26px Avenir;
}

.f_input {
  background: rgba(169, 206, 229, 0.3);
  &:focus {
    background: rgba(169, 206, 229, 0.3);
    outline: none;
  }
}

.loginBTN {
  width: 100%;
  background: var(--tef-red);
  margin-bottom: 91px;
}

.relativeField {
  position: relative;
}

.hr {
  // width: 100%;
  // text-align: center;
  // border-bottom: 1px solid rgba(169, 206, 229, 0.5);
  // line-height: 0.1em;
  // margin: 10px 0 20px;

  overflow: hidden;
  text-align: center;
  margin-bottom: 30px;
}

.hideIcon {
  position: absolute;
  right: 10px;
  top: 40px;
  cursor: pointer;
  z-index: 9999;
}

.hr:before,
.hr:after {
  background-color: rgba(169, 206, 229, 0.5);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.hr:before {
  right: 0.5em;
  margin-left: -50%;
}

.hr:after {
  left: 0.5em;
  margin-right: -50%;
}

.hr span {
  // background: #fff;
  // padding: 0 10px;
  // margin-top: 15px;
}

.tef_logo {
  height: 30px;
}

.errorText {
  color: var(--tef-red);
}

.fade {
  animation: fade 3s ease-in-out;
  transition: all;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.fade {
  animation: fade 3s ease-in-out;
  transition: all;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.carouselSS {
  align-items: flex-start !important;
  // position: relative;
  margin-top: 10px;
  margin-bottom: auto;
  align-self: flex-end;
  justify-content: space-between !important;
  @media only screen and (min-width:768px) {
    display: none;
  }
}

.carouselBS {
  @media only screen and (max-width:768px) {
    display: none;
  }
}

.carouselBS,
.carouselSS {
  @extend .carouselContentContainer;
}
.tef_logo_mobile {
  // align-self: flex-start;
  // margin-left: -20px;
  // position: absolute;
  // top: 150px;
  // bottom: 0;
  margin-top: 5%;
  // margin-bottom: auto;

  @extend .tef_logo;
  @media only screen and (min-width:768px) {
    display: none;
  }
}

.logoContainter_mobile {
  position: fixed;
  top: -20px;
  left: 0;
  right: 0;
  margin-bottom: auto;
  margin-top: 30px;
  display: flex;
  flex: 1;
  padding: 0 6%;
}

.fadeOut {
  animation: fadeOut 1s ease-out;
  transition: all;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
