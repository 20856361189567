.MasterclassPageNav {
  display: flex;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e1e1e1;
}

.TabHeader {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0.3rem;
  cursor: pointer;
}

.TabBody {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.Details {
  flex: 0 0 35%;
}

.Transcript {
  flex: 0 0 25%;
}

.Files {
  flex: 1;
}

.Selected {
  border-bottom: solid 2px var(--tef-red);
  transition: all 0.3s ease-out;
}
