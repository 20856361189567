.ReplyContainer {
	display: flex;
	margin: 1rem 0 1rem 1rem;
	border: solid 1px var(--new-faded-white);
  padding: 0.1rem 0.5rem;
  align-items: stretch;
}
.ReplyAvatar {
	flex: 0 0 12%;
}
.ReplyInput {
	flex: 0 0 78%;
}
.ReplyInput textarea {
	outline: none;
  border: none;
  width: 95%;
}

@media(min-width: 481px){
  .ReplyContainer{
    align-items: center;
  }
  .ReplyAvatar{
    flex: 0 0 7%;
  }
  .ReplyInput{
    flex: 0 0 78%;
  }
  .ReplyInput textarea{
    width: 98%;
  }
}
