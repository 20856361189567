.user-likes {
  width: max-content;
  height: max-content;
  background-color: #fff;
  position: absolute;
  z-index: 1;
  border: 1px solid #dfe1e3;
  border-radius: 4px;
  opacity: 0.93;

  &__list {
    list-style: none;
    margin: 0;
    padding: 5px 10px;

    &--item {
      margin-left: 0;
      font-family: Poppins;
      font-size: 9px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: var(--h-1-black-new);
      text-transform: capitalize;
    }
  }
}
