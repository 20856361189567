.ModalLabel{
  /* text-align: center; */
  font-size: 1.3rem;
}
.ModalHeader{
  background-color: #f4f5f7;
  display: flex;
  justify-content: space-between;
}
.ModalHeader div{
  margin-right: 3rem;
}
.ModalBody{
  font-size: 0.75rem;
}
.ModalBody h4{
  font-weight: 600;
  font-size: 0.85rem;
  text-align: justify;
}
.ModalBody h6{
  font-weight: 600;
  font-size: 0.75rem;
}
.ModalFooter{
  display: flex;
  justify-content: center;
}
.Done{
  width: 90%;
}
