.investor-page-container {
  &__banner {
    height: max-content;
    min-height: 282px;
    background-image: url("../../../assets/img/onboading-banner-image.png");
    background-position: top;
    background-size: cover;
    padding: 100px 139px 65px 139px;
    color: var(--white);
    width: 100%;

    // display: flex;
    // flex-direction: column;
    & h1 {
      font-family: Poppins;
      font-size: 48px;
      font-weight: bold;
      letter-spacing: 0.2px;
    }
  }

  &__form-section {
    display: flex;
    background: var(--white);
    // padding-left: 120px;
    // padding-right:237px;
    flex-wrap: wrap;

    &--left {
      flex: 1;
      //   background: blue;
      //   background: var(--white);
      //   padding: 71px 139px 0px 139px;
      position: relative;
      padding: 0px 237px 0px 139px;
      margin-top: 53px;
    }
    &--right {
      flex: 1;
      background-color: #f6f7f8;
    }
  }
}

.toggle-add-investment-title {
  top: 25px;
}

.toggle1 {
  // display: inline-block;
}

.toggle2 {
  display: none;
}

@media only screen and (max-width: 1300px) {
  .toggle1 {
    display: none;
  }
  .toggle2 {
    display: block;
  }
  .toggle-add-investment-title {
    top: -5px;
  }
}

@media only screen and (max-width: 992px) {
  .investor-page-container {
    &__banner {
      padding: 100px 10px 65px 30px;
    }

    &__form-section {
      &--left {
        padding: 0px 10px 0px 10px;
      }
      &--right {
        display: none;
      }
    }
  }
  .toggle1 {
    display: flex;
  }
  .toggle2 {
    display: none;
  }
  .toggle-add-investment-title {
    top: 25px;
  }
}

.custom-row {
  display: flex;
  //   flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-between;
  align-content: space-between;
  align-items: center;
}

.custom-flex-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  //   justify-content: center;
}

.flex {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}
.flex-end {
  align-self: flex-end;
}
.j-center {
  align-self: center;
}

.pad-form {
  // &:not(:first-child) {
  //   margin-left: 10px;
  // }
  // padding:10px;
}

.add-new {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
  letter-spacing: 0.2px;
  color: var(--tef-blue);
  &:hover {
    cursor: pointer;
  }
}
.flex-start {
  align-self: flex-start;
}

.steps-count {
  font-family: Poppins;
  font-size: 20px;
  letter-spacing: 0.24px;
  color: var(--helper-text-color);
}

.pad-inner {
  padding-left: 21px;
  padding-right: 21px;
}

.achievement-list {
  flex: 1;
  &__item {
    display: flex;
    margin: 28px 0px;
    // flex-direction: column;
    // flex-wrap: wrap;
    // flex:1;

    &--image-container {
      height: 64px;
      width: 64px;
      // size: 64px;
    }
    &--main {
      &--header {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-content: space-between;
        align-items: stretch;
        & h1 {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0.2px;
          color: var(--h1-black-new);
          flex: 3;
          margin-left: 17px;
        }
        & span {
          font-family: Poppins;
          font-size: 12px;
          letter-spacing: 0.2px;
          color: var(--helper-text-color);
          cursor: pointer;
        }
      }
    }
  }
}

.investment-list {
  margin: 43px 0px;
  &__item {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &--image-container {
      height: 64px;
      width: 64px;
      background-color: var(--new-faded-white);
      border-radius: 4px;
      margin-right: 17px;
      // flex:1
    }
    &--content-right {
      flex: 1;
      & h1 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.2px;
        color: var(--h1-black-new);
      }
      & span {
        font-family: Poppins;
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: 0.13px;
        color: var(--tef-grey);
      }
    }
  }
}
.remove-item {
  font-family: Poppins;
  font-size: 12px;
  line-height: 1.42;
  letter-spacing: 0.13px;
  color: var(--tef-grey);
  cursor: pointer;
}
