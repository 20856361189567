.photo {
  padding: 14px 0 10px 20px;
  &__h1 {
    // font-family: Poppins;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #171725;
    vertical-align: baseline;
    margin-top: 5px;
  }
  &__pic {
    margin: 1% 0 0 0;
    position: relative;
    -webkit-transition: background 0.1s linear;
    -moz-transition: background 0.1s linear;
    -ms-transition: background 0.1s linear;
    -o-transition: background 0.1s linear;
    transition: background 0.1s linear;
    &:hover {
      cursor: pointer;
      .faded-option {
        // display: flex;
        max-height: 500px;
        padding: 5px;
        display: flex;
      }
    }
  }
  &__pic > img {
    border-radius: 6px;
    object-fit: cover;
    object-fit: top;
    height: 203px;
  }
}

.faded-option {
  position: absolute;
  display: none;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  margin: 0px 15px;
  // height: 3em;
  max-height: 0;
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background-image: -moz-linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background-image: -o-linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  background-image: -ms-linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
}

.app-photo-btn-container {
  display: flex;
  justify-content: space-between;
}

.add-photo-title {
  font-weight: 600;
  color: var(--helper-text-color);
}

.add-photo-btn-1 {
  display: flex;
  background-color: #fff;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 25px;
  color: var(--tef-red);
  max-width: max-content;
  border: 1px solid var(--tef-red);
  cursor: pointer;
  &:hover {
    color: white;
    background-color: var(--tef-red);
  }
}

.photo-option {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  // align-content: flex-start;
  width: 100%;
}

.photo-delete-option {
  display: flex;
  border: 1px solid white;
  padding: 5px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.empty-image-state {
  font-size: 28px;
  margin: 0 auto;
  text-align: center;
  color: var(--helper-text-color);
  font-weight: bold;
}

.photo-texts {
  display: flex;
  align-items: "center";
  // flex-wrap: wrap;
  flex: 1;
  margin-top: 10px;
  & > p {
    font-size: 13px;
    letter-spacing: 1.25;
    font-weight: 400;
  }
}

@media only screen and (max-width: 767px) {
  .photo {
    &__pic > img {
      height: 100%;
    }
  }
}

.after {
  &::after {
    content: "";
    height: 2px;
    width: 2px;
    background-color: #fff;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 50%;
  }
}
