.container{
  display: flex;
  background-color: var(--white);
  position:fixed;
  left:0;
  right: 0;
  z-index:1000;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  padding: 0 1.5%;
  min-height: 6rem;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width:768px) {
    // display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 1.5%;
  }
}
.left{
  // flex: 1;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    display: flex;
    // flex-grow: 2;
    width: 78%;

    @media only screen and (max-width:1003px) {
      width: 70%;

    }

    @media only screen and (max-width:768px) {
     width: 100%;
    }



  &_l1{
    display: flex;
    // flex: 2;
    align-self: stretch;
    align-items: center;



  }
}

.right{
  align-items:center;
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  flex: 1;
}

.logo{
  width: 100%;
  height: 26px;
  @media only screen and (max-width:768px) {
    display: none;
  }
}
.gt_sm{
  display: none;
}