.sarf {
  text-transform: capitalize;
}

.sar-form {
  max-width: 700px;
}

.email-pure {
  outline: none;
}

.email-error {
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: solid 2px var(--tef-red);
  outline: none;
}
