.opp-create-form {
  width: 100%;
  &__image-c {
    // height: 72px;
    border-radius: 3px;
    border: dashed 1px #c4cdd5;
    background-color: rgba(234, 236, 239, 0.5);
    display: flex;
  }
}

.opp-form-img-box {
  width: 72px;
  border-radius: 3px;
  height: 72px;
  display: flex;
  background-color: rgba(234, 236, 239, 0.5);
  & img {
    width: 100%;
    object-fit: cover;
    // height: inherit;
  }
  &__default-img {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--tef-grey);
    flex: 1;
  }
}

.opp-form-btn-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex: 1;
  & span {
    font-size: 10px;
    line-height: 1.28;
    letter-spacing: 0.2px;
    text-align: center;
    color: #212b36;
  }
  &__button {
    border-radius: 2.8px;
    box-shadow: 0 4px 8px 0 rgba(66, 80, 92, 0);
    background-color: var(--white);
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;

    font-size: 10px;
    letter-spacing: 0.2px;
    color: var(--tef-red);
  }
}
.opp-create-form-file-info {
  font-family: Poppins;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.2px;
  color: var(--helper-text-color);
}

.opp-file-upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  & + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
  }
}
