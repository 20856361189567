.Bold {
  font-family: Poppins;
  font-size: 1.1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.2px;
  color: #171725;
}

.Center {
  text-align: center;
}

.Subtitle1 {
  font-family: Poppins;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.2px;
  color: #171725;
}

.NumberedParagraph {
  display: flex;
}

.Number {
  flex: 0 0 5%;
}
