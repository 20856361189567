.no_comment {
  font: normal normal medium 15/30px Poppins;
  font-size: 15px !important;
  color: #a6a2a2;
  opacity: 1;
  text-align: center;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
