.BookmarksContainer {
  &__noContent {
    margin: 20px auto;
    max-width: 80%;
    font-family: Poppins;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.2px;
    color: var(--helper-text-color);
    text-align: center;
  }
}
