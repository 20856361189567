.document {
  max-width: 1000px;
  margin: 20px auto;
  font-family: Poppins;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.29px;
  padding: 10px;
}

.blue-link {
  color: rgb(30, 42, 219);
}

.red-text {
  color: red;
  & a {
    color: red;
    text-decoration: underline;
  }
}

.alpha {
  list-style-type: lower-alpha;
}

.roman {
  list-style-type: lower-roman;
}

.center {
  text-align: center;
}

.bolder-text {
  font-weight: bolder;
}

li {
  padding: 5px 0;
}

.text-italic {
  font-style: italic;
}

.structured-list {
  counter-reset: item;

  li {
    display: block;
  }
  li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
  }
}

.declaration-h6 {
  display: flex;
  align-items: center;
  font-family: SFProText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #637381;
}
