.user-background {
  background-color: blue;
  height: 150px;
  position: relative;
  margin-bottom: 50px;
}
.user-hub-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  left: 35%;
  top: 55%;
}
.hub-user-no-img {
    background-color: #bec4cb;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 35%;
    top: 55%
}
