.faq-heading {
  font-family: SFProText;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 10px 0 0 0;
}

.faqContainer {
  background-color: chartreuse !important;
}
