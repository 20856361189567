.InvitedEvent {
    margin: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.Left {
    display: flex;
    flex: 0 0 80%;
}

.Right {
    flex: 1;
    display: flex;
}

.EventLogo {
    flex: 0 0 15%;
}

.EventDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.EventTitle {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.13px;
    color: #171725;
}

.EventDate {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.26px;
    color: var(--h-1-black-new);
}

.Requestedby {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.26px;
    color: var(--helper-text-color);
}

.Requestee {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.3px;
    color: var(--h-1-black-new);
}

.AcceptButton {
    border-radius: 0.25rem;
    background-color: var(--tef-red);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--white);
    width: 5rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.25rem;
    cursor: pointer;
}

.AcceptedButton {
    border-radius: 0.25rem;
    background-color: var(--tef-red);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--white);
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.25rem;
    cursor: pointer;
}

.DeclineButton {
    border-radius: 0.25rem;
    border: solid 1px var(--helper-text-color);
    background-color: var(--white);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--tef-grey);
    width: 5rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.DeclinedButton {
    border-radius: 0.25rem;
    border: solid 1px var(--helper-text-color);
    background-color: var(--white);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--tef-grey);
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}