.social-button-div {
  // min-width: 501px;
  // height: 150px;
  // border-radius: 8px;
  // background-color: #f9f9f9;
  font-family: Poppins;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  display: flex;

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }

  &__button > button {
    display: flex;
    outline: none;
    // max-width: 220px;
    width: 100%;
    border: 1px solid #6e6e70 !important;
    box-shadow: none !important;
  }

  &__button button:disabled {
    background-color: #bbbbcc;
  }
}

.btn-linkedin {
  padding: 10px 12px;
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
  //   rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}
.google-button {
  border: 1px solid red;
}

.social-button-border {
  border: 1px solid #6e6e70 !important;
  box-shadow: none !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .social-button-div {
    margin-bottom: 10px;
    min-width: inherit;

    &__button {
      flex-direction: column;
    }

    &__button > button {
      max-width: 100%;
    }
  }

  .google-button {
    width: 197px;
  }
}

.favicon {
  margin-right: 8px;
  flex-shrink: 0;
}

.spacer {
  margin: 10px;
}
