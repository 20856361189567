.alumniHub {
  display: flex;

  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;

  &>* {
    margin-left: 20px;
  }

  &__title {
    padding: 10px 10px 0px 10px;
    font-weight: bold;
    text-transform: uppercase;

    color: #fff;
    display: inline-block;
    font-size: 40px;
    line-height: 70px;
    border-radius: 10px;
    width: 30%;
    text-align: right;
    text-transform: capitalize;
    text-align: right;
    margin-right: 10px;

    & p {
      font-weight: bold;
    }
  }

  &__meta-box {
    color: #fff;
    display: flex;
    padding: 15px;
    justify-content: flex-end;
    width: 40%
  }

  &__meta-box:last-child {
    margin-right: 10px;
  }

  &__icon {
    margin-right: 10px;
  }
}