.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  &__container {
    max-height: 380px;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    margin-top: 30px;
  }

  &__title {
    font-family: Poppins;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #171725;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__desc {
    font-family: Poppins;
    text-transform: capitalize;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #888;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
