.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: var(--tef-red);
  border-color: var(--tef-red);
  color: #fff;
}
.pagination > li {
  padding: 7px 0;
}
.pagination > li > a {
  border: 1px solid #000;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin-left: 3px;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: var(--tef-red);
  border-color: var(--tef-red);
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #000;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
