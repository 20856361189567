.comments {
  display: flex;
  margin-bottom: 36px;

  & img {
    width: 36px;
    height: 36px;
  }
}

.comments-content {
  margin-left: 13px;
  display: flex;
  flex-direction: column;

  & h3 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
    text-transform: capitalize;
  }

  & p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
    word-break: break-word;
  }
}

.comments-actions {
  display: flex;
  justify-items: center;

  & span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
  }

  & h5 {
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--tef-grey);
    margin-left: 15px;
    margin-top: 2px;
  }
}
