.SideNavItem {
  height: 45px;
  padding-left: 22px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-family: Poppins;
  text-decoration: none;
  list-style: none;
  fill: #6e6e70;
  color: #6e6e70;

  &:hover {
    transition: all 0.5s ease-in;
    background-color: #eee;
    color: var(--tef-red);
    text-decoration: none;
    fill: var(--tef-red);
  }

  & > a {
    text-decoration: none;
    padding-bottom: 20px;
  }

  &__link {
    margin-left: 20px;
    color: var(--h-1-black-new);
  }

  &__avatarIcon {
    border-radius: 7px;
  }
}

.SideItemActive {
  fill: var(--tef-red);
  color: var(--tef-red);
  background-color: #eee;
}

.SideItemInActive {
  fill: var(--h-1-black-new);
  color: var(--h-1-black-new);
}

@media screen and (max-width: "1024px") {
  .SideNavItem {
    padding-left: 12px;

    &__link {
      margin-left: 10px;
    }
  }
}
