.NotificationCounter {
  height: 1rem;
  width: 1rem;
  background-color: var(--tef-red);
  color: var(--white);
  font-size: 0.5rem;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -5px;
}
