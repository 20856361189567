.a-o-card-main {
  background-color: white;
  border-radius: 6px;
  padding: 67px 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 10px;
  position: relative;
  overflow-y: auto;
  & h1 {
    font-family: Poppins;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: normal;
    text-align: center;
    & span {
      color: #da291c;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 18px;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: center;
    color: #63666a;
  }
}

.lang-card-c {
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  flex-wrap: wrap;
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 21px 26px 28px 26px;
    border-radius: 6px;
    box-shadow: 0 5px 10px 0 rgba(38, 41, 45, 0.11);
    min-width: 160px;
    background-color: #ffffff;
    cursor: pointer;
    // &:not(:last-child){
    margin-right: 16px;
    // }
    & h6 {
      font-family: Poppins;
      font-size: 10px;
      text-align: center;
      color: #919ba8;
      margin-top: 17px;
    }
    & h5 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #26292d;
    }
  }
}

.item-img-c {
  height: 48px;
  width: 48px;
}
.selected {
  box-shadow: 0 20px 30px 0 rgba(38, 41, 45, 0.11);
}

@media only screen and (max-width:860px) {
  .lang-card-c {
    justify-content: center;
    &__item {
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width:506px) {
  .lang-card-c {
    justify-content: center;
    &__item {
      margin-top: 10px;
      margin-right: 0px;
      min-width: 300px;
    }
  }
}

@media only screen and (max-width:425px) {
  .lang-card-c {
    justify-content: center;
    &__item {
      margin-top: 10px;
      margin-right: 0px;
    }
  }
}
