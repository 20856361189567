.moveChatRight {
  transform: translateX(50%);
  // justify-content: end;
  // justify-items: end;
  display: flex;

  flex-direction: row-reverse;
}
.moveChatLeft {
  display: flex;

  flex-direction: row;
  // justify-content: start;
  // justify-items: start;
}
.moveChatLeft-bg {
  background-color: #819ec4 !important;
  color: #fff !important;
}
.moveChatRight-bg {
  background-color: #3b4b5c !important;
  color: #fff !important;
}
.chat-user-img {
  border-radius: 50%;
}
.user-no-img {
  background-color: #bec4cb;
  border-radius: 50%;
  width: 45px;
  min-width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.del-chat {
  margin-top: 100%;
  display: inline-block;
  cursor: pointer;
}
.group-no-img {
  background-color: #bec4cb;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.group-users > *:not(:last-child) {
  margin-right: -20px;
}

.modal-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 25vw;
  height: 100vh;
  position: absolute;
  right: 0;
  margin: 0 !important;
}
.modal-container > .modal-content {
  height: 100vh;
}
.user-info-name {
  font-weight: bold;
  color: #000;
  font-size: 12px;
}

.alumni-member {
  width: 100vw;
}
.chat-date-seperator {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.date-seperator-value {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 25px; 
  color: #8f949a;
  background-color: #e2e5ec;
  font-size: 11px;
  margin: auto;
}
.chat-date-seperator::after {
  content: "";
  width: 35%;
  height: 1px;
  background-color: #e2e5ec;
  display: inline-block;
}
.chat-date-seperator::before {
  content: "";
  width: 35%;
  height: 1px;
  background-color: #e2e5ec;
  display: inline-block;
}
@media screen and (max-width: 790px) {
  .desktop-add-member {
    display: none;
  }
  .mobile-add-member {
    width: 100vw;
    display: block;
    z-index: 999999;
    position: absolute;
  }
}
