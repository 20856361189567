// he modal component.
.overlay {
    position: fixed;
    display: block; 
    overflow: auto; 
    width: 100%; 
    height: 100%; 
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); 
    z-index: 9999; 
    // cursor: pointer;
  }

.content {
    margin: 15% auto;
    background-color: white;
    border-radius: 0.25rem;
    width: 50vw;
    padding: 2rem;
    position: relative;
  }


  @media only screen and (max-width: 800px){
    .content{
     width: 80vw;
    }
  }
@media only screen and (max-width: 600px) {
  .content {
    width: 80vw;
    padding: 0.5rem;
  }
}