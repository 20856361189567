.right-side-bar {
  padding-top: 52px;
  height: 100%;
}

.language-dropdown {
  max-width: 189px;
  height: 36px;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(51, 65, 80, 0.03), 0 2px 2px 0 rgba(51, 65, 80, 0.03);
  background-color: #ffffff;

  position: relative;
  left: 120px;
}

.language-dropdown:active,
.language-dropdown:focus {
  outline: none;
}

.application-tips {
  position: absolute;
  top: 170px;
  height: 207px;
  max-width: 320px;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 #33415008;
  background-color: #fffcfb;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 15px;
    text-align: center;
    font-family: SFProText;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.2px;
    color: #63666a;
  }
}

.need-help {
  max-width: 160px;
  height: 46px;
  border-radius: 4px;
  border: solid 1px rgba(99, 102, 106, 0.09);
  background-color: #ffffff;

  font-family: SFProText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212b36;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  top: 400px;
}

@media only screen and (min-width: 989px) {
  .right-side-bar {
    // background-color: limegreen;
  }
}