.ToolCards {
    display: flex;
    flex-wrap: wrap;
}

.ToolCard {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(145, 155, 168, 0.06);
    background-color: var(--white);
    width: 12.8rem;
    height: 16rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1rem;
    margin: 0.5rem 0 0.5rem 0.5rem;
}

.Row1 {
    display: flex;
    margin-bottom: 0.7rem;
}

.ToolIcon {
    flex: 0 0 15%;
    margin-right: 0.5rem;
}

.IconImage {
    width: 1.5rem;
    border-radius: 50%;
    box-shadow: 0 5px 20px 0 var(--new-faded-white);
}

.ToolTitle {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    color: var(--h-1-black-new);
}

.CategoryAndDateCreated {
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    color: var(--tef-grey);
}

.DateCreated {
    font-weight: 300;
    letter-spacing: 0.2px;
}

.ToolDescription {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: var(--tef-grey);
    margin-bottom: 1.5rem;
    height: 4.5rem;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.AvatarAndComment {
    display: flex;
    justify-content: space-between;
}

.Comments {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CommentCount {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--tef-grey);
    margin-left: 0.3rem;
}

.ViewTool {
    border-radius: 2.8px;
    border: solid 1px var(--helper-text-color);
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-evenly;
    padding: 0.5rem;
    margin-top: 1rem;
    cursor: pointer;
    color: var(--tef-grey);
}

.ViewToolText {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
}

.ViewTool:hover {
    background-color: var(--tef-red);
    color: var(--white);
    border: solid 1px var(--tef-red);
}

@media(max-width: 480px) {
    .ToolCard {
        width: 100%;
    }
    .ViewTool {
        width: 100%;
        justify-content: center;
    }
}