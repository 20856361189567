.PostedBy{
  display: flex;
  font-size: 0.75rem;
} 
.Posted{
  margin-right: .5rem;
  color: #919ba8;
  display: flex;
  justify-content: center;
}
.PosterImage{
  margin-right: 0.75rem;
}
.PosterName{
  margin-right: 0.75rem;
  color: #111111;
  display: flex;
  justify-content: center;
}
.OpportunityType{
  color: #b620e0;
  margin-right: 0.75rem;
  display: flex;
  justify-content: center;
}
/* @media(min-width: 768px){
  .PostedBy{
    font-size: 0.875rem;
  }
} */
