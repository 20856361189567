.groupRequestsItem{
  display: flex;
  // flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  // justify-content: space-evenly;
  // align-items: center;
  &:hover{
    background-color: var(--very-light-blue);
  }
  &:not(:last-child){
    border-bottom: 1px solid var(--new-faded-white);

  }
}
.avatarC{
  margin-right: 10px;
}
.name{
  & h6{
    // font-weight: bold;
    font-size: 13px;
    text-transform: capitalize;
    color: #171725;
  }
  
}
.groupRequestC{
  height: 200px;
  overflow-y: auto;

  // -ms-overflow-style: none;
  // scrollbar-width: none;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
}