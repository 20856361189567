.goal-card {
  width: 100%;
  border-radius: 4.6px;
  border: solid 1.2px var(--new-faded-white);
  background-color: var(--white);
  margin-top: 5%;
  cursor: pointer;
}
.goal-status-bar {
  border-radius: 3px;
  // background-color: var(--pre-green);
  margin: 14px 0 0;
  width: 100%;
}
.goal-status {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: var(--white);
  position: relative;
  margin: auto;
}
.goal-program-bar {
  border-radius: 3px;
  border: solid 1px var(--tef-grey);
  margin: 14px 0 0 0;
  width: 100%;
}
.goal-program {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: var(--tef-grey);
  position: relative;
  margin: auto;
}
.goal-text {
  height: 43.8px;
  font-family: Poppins;
  font-size: 15.4px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.22px;
  color: var(--h-1-black-new);
  margin: 11px 0 0 16px;
}
.goal-comment-area {
  margin-top: 24px;
}

// @media only screeb and (min-width:992px){

// }

.g-card-c {
  padding: 14px 8px;
  display: flex;
  flex-direction: column;
  border-radius: 4.6px;
  border: solid 1.2px var(--new-faded-white);
  background-color: var(--white);
  margin-top: 0.5em;
  cursor: pointer;
  // align-items: center;
  justify-content: space-between;
  align-content: space-between;
  min-height: 142px;
  &__head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    &--bar {
      padding: 2px 5px;
      font-size: 10.5px;
      font-weight: 600;
      letter-spacing: 0.42px;
      text-align: center;
      color: var(--tef-grey);
      text-transform: uppercase;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // flex-direction: column;
      margin: 0px 2px;
      cursor: pointer;
    }
  }
  &__title {
    margin: 12px 0;
    & h1 {
      font-family: Poppins;
      font-size: 15.4px;
      font-weight: 600;
      line-height: 1.42;
      letter-spacing: 0.22px;
      color: var(--h-1-black-new);
    }
  }
  &__like-comment {
    display: flex;
    justify-content: space-between;
  }
}

.prog {
  border-radius: 3.1px;
  border: solid 1px var(--tef-grey);
  text-align: center;
}
.stat {
  color: white;
  border-radius: 3.1px;
  position: relative;
}

.g-like-comment {
  margin: 0px 32px 0px 0px;
}

.goal-update-card {
  position: absolute;
  z-index: 1;
  border-radius: 4.6px;
  border: solid 1.2px var(--new-faded-white);
  background-color: var(--white);
  padding: 10px;
  width: 100%;
  top: 30px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
}

.goal-status-update-card-items {
  width: 100%;
}

.goal-status-update-head {
  display: flex;
  justify-content: space-between;
  color: var(--tef-grey);
  font-size: 15px;
}

.status-list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &__item {
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 4px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    margin: 5px;
    cursor: pointer;
  }
}

.goal-more-list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &__item {
    flex: 1;
    display: flex;
    // align-self: center;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    font-family: Poppins;
    color: #26292d;
    cursor: pointer;
  }
}

.due {
  padding: 5px;
  display: flex;
  background-color: pink;
  // font-weight: bolder;
  color: hotpink;
}
.due_and_delete {
  // display: flex;
  justify-content: space-between;
}
.due-text {
  color: var(--helper-text-color);
  font-size: 13px;
}
.title_of_goal {
  font-size: 20px !important;
}
