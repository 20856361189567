.chat-block__wrapper {
  cursor: pointer;
  .user-details__wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    .user-image {
      flex-basis: 18%;

      .user-initials {
        height: 42px;
        width: 42px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #444;
        color: #fff;
      }
    }

    .user-online__status {
      flex-basis: 3%;

      span {
        height: 5px;
        width: 5px;
        background: #28a745;
        display: block;
        border-radius: 100%;
      }
    }

    .user-name {
      flex-basis: 52%;

      p {
        font-size: 14px;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95%;
        color: #000;
        font-weight: 600;
      }
    }

    .last-chat__time {
      flex-basis: 23%;
      text-align: right;

      p {
        font-size: 12px;
        color: rgb(155, 155, 155);
        font-style: italic;
        margin-bottom: 0;
      }
    }
  }

  .last-message__wrapper {
    display: flex;

    .last-chat__message {
      flex-basis: 82%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      margin-top: 5px;
      font-size: 14px;
      color: rgb(155, 155, 155);
    }
  }

  .group-members__wrapper {
    display: flex;

    .group-members {
      flex-basis: 82%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.125);

      .group-members__image-array {

        .group-member__image {
          height: 30px;
          width: 30px;
          border-radius: 100%;
          object-fit: cover;

          &:not(:first-child) {
            position: absolute;
            left: 50%;
          }
        }
      }

      .group-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 13px;
          margin-left: 8px;
          font-weight: 600;
        }
      }
    }
  }
}