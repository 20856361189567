.SideDrawer {
  background-color: #2e3d49;
  width: 280px;
  max-width: 70%;
  height: 100%;
  z-index: 2000;
  top: 0;
  left: 0;
  position: fixed;
  padding: 20px 0;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__search {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: -16%;
  }

  &__back {
    padding: 20px 0;
    display: flex;
    border-top: 1px solid white;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: white;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    font-family: Poppins;
    text-decoration: none;
    &:hover {
      color: white;
      font-weight: 900;
    }
  }
}

.SideDrawerOpen {
  transform: translateX(0);
}

.SideDrawerHide {
  transform: translateX(-100%);
}

@media screen and (min-width: "769px") {
  .SideDrawer {
    display: none;
  }
}
