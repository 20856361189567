.chat-wrapper {
  max-height: 80vh;
  overflow: hidden;
  border: 1px solid #b3cce6;
  background: #fff;

  .unread-mesage__count {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 0;

    .unread-mesage__count-text {
      font-weight: 600;
      font-size: 22px;
    }

    .unread-mesage__count-number {
      background: #da291c;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      font-size: 13px;
      border-radius: 50%;
      top: -4px;
      position: absolute;
      left: 96px;
      color: #fff;
      font-size: 10px;
    }
  }

  .chat-widget__body {
    height: 100%;

    .current-chat__info {
      height: 10%;
      background: #f5f9ff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      width: 100%;

      .current-chat__image {
        display: flex;
        align-items: center;

        img {
          height: 30px;
          width: 30px;
          border-radius: 100%;
          object-fit: cover;
        }
      }

      .current-chat__name {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 15px;
        margin-left: 10px;
      }
    }

    .current-chat__messages {
      height: 78%;
      padding: 15px;
      overflow-y: auto;
      display: flex;
      margin-top: 10px;
      flex-direction: column;
      // display: grid;
      // grid-template-columns: 70%;
    }

    .current-chat__action {
      padding: 20px;
      height: 12%;
      display: flex;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      &-left {
        flex-basis: 15%;
        display: flex;
        justify-content: flex-start;
      }
      &-input__box {
        flex-basis: 80%;
        display: flex;
        padding-bottom: 15px;
        form {
          flex-basis: 90%;
          input,
          input:focus {
            outline: none !important;
            box-shadow: none !important;
            border: 0 !important;
          }
        }
      }
      &-right {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--tef-red);
        border-radius: 100%;
        color: #fff;
        cursor: pointer;
        svg {
          position: relative;
          left: -1px;
          top: -px;
        }
      }
    }
  }
}
