.contentContainer {
  padding: 30px;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  text-align: center;
}

.heading {
  // text-align: center;
  color: #070707;
  margin-top: 42px;
}

.text {
  // text-align: center;
  color: #6e6e70;
  margin-top: 20px;
}

.button {
  background: var(--tef-red);
  width: 100%;
  margin: 74px 0 30px 0;
}
