.article-detail {
  &__video {
    width: 75px;
    max-width: 100%;
    height: 60px;
    margin-right: 12px;
    object-fit: cover;
    border-radius: 5px;
  }
}

.tab-border {
  border-left: 1px solid #919ba8;
  height: 0.8rem;
  margin-top: 4px;
}

.video-card {
  border-radius: 4px;
  border: solid 1px var(--new-faded-white);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  cursor: pointer;
}

.post-item {
  h1 {
    line-height: 1.57;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
  }
  h2 {
    line-height: 1.8;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.2px;
  }
  h3 {
    line-height: 1.57;
    font-size: 12px;
    font-weight: 600;
    color: var(--helper-text-color);
  }
  h4 {
    line-height: 1.9;
    font-size: 10px;
    font-weight: 600;
    color: var(--helper-text-color);
  }
  &__1 {
    top: 180px;
    left: 20px;
    width: 93%;
  }
  .user-avartar {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.category-image {
  height: 280px;
  width: 100%;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.post-list-item {
  border-radius: 3.6px;
  box-shadow: 0 2px 4px 0 rgba(145, 155, 168, 0.06);
  background-color: var(--white);
  margin: 15px 3.5px;
  max-width: 324px !important;
  display: flex;
  flex-direction: column;
  min-height: 246px;
  cursor: pointer;
  &__image {
    height: 150px;
    width: 100%;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px 4px 0px 0px;
    }
  }
  h3 {
    line-height: 1.57;
    font-size: 10.5px;
    font-weight: 600;
    color: var(--helper-text-color);
  }
  h1 {
    line-height: 1.57;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
  }
  h2 {
    line-height: 1.9;
    font-size: 8.6px;
    font-weight: bolder;
    // letter-spacing: 0.2px;
    color: var(--h-1-black-new);
  }
  h4 {
    line-height: 1.9;
    font-size: 9px;
    font-weight: 600;
    color: var(--helper-text-color);
  }
  .user-avartar {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.all-articles {
  background-color: var(--white);
  border-radius: 4px;
  padding: 0.5rem 0.7rem;
  h1 {
    color: var(--h-1-black-new);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.2px;
  }
  &__length {
    color: var(--helper-text-color);
  }
  .submit-article {
    background-color: var(--tef-red);
    color: var(--white);
    font-weight: bold;
    padding: 0.3rem 0;
    border-radius: 3px;
    font-size: 0.8rem;
    cursor: pointer;
    margin: 0 auto;
  }
}

.username {
  color: var(--helper-text-color);
  font-size: 0.6rem;
  font-weight: bold;
}

.date-posted {
  color: var(--helper-text-color);
  font-size: 0.7rem;
}

.separator {
  border-left: 1px solid #919ba8;
  font-size: 0.6rem;
}

.save-btn,
.share-btn {
  font-size: 0.8rem;
  color: var(--h-1-black-new);
}

.view-tef-article {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  padding-top: 32px;
  background-color: var(--white);
}

.follow-btn {
  background-color: var(--new-faded-white);
  padding: 8px 12px;
  border-radius: 12px;
  cursor: pointer;
  font-weight: bold;
}

.writer-avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.related-articles {
  background-color: var(--new-faded-white);
  border-radius: 8px;
  padding: 10px;
}

.share-icon {
  padding: 4px 6px;
  border: 1px solid var(--new-faded-white);
  width: 28px;
  border-radius: 5px;
}

.article-tag {
  padding: 4px 12px;
  border: 1px solid var(--new-faded-white);
  border-radius: 5px;
}

.download-format {
  padding: 4px 12px;
  border: 1px solid var(--new-faded-white);
  border-radius: 5px;
  margin-top: 16px;
}

.download-article-btn {
  background-color: var(--tef-red);
  color: var(--white);
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 4px;
}

.cancel-download-btn {
  background-color: var(--new-faded-white);
  color: var(--helper-text-color);
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 4px;
}

.download-format input[type="radio"]:checked {
  border: 1px solid var(--tef-red);
  background-color: var(--tef-red);
  border-color: var(--tef-red);
}

post-list-item h2 {
  font-size: 10px;
  line-height: 1.5px;
  margin-left: 10px;
}

.Article-title {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: var(--h-1-black-new);
}

.Article-category {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: var(--tef-grey);
}

.Article-short-description {
  word-break: break-word;
}

.ArticleBanner {
  object-fit: cover;
  width: 100%;
  height: 50vh;
}
