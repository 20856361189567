.application-layout {
  padding: 0;
  // height: 100vh;
}

//
// .col2 {
// height: 100vh;
//
// overflow: scroll;
// -ms-overflow-style: none;
// scrollbar-width: none;
// &::-webkit-scrollbar {
// display: none;
// }
// }
