/* .UserReactions {
    display: flex;
    box-sizing: border-box;
    font-size: 0.5rem;
}

.Likes {
    display: flex;
    flex: 0 0 15%;
    align-items: center;
    padding: 0 0.4rem;
    box-sizing: border-box;
    cursor: pointer;
}

.Reply {
    display: flex;
    flex: 0 0 7%;
    align-items: center;
    padding: 0 1.5rem;
    box-sizing: border-box;
    cursor: pointer;
}

.Share {
    display: flex;
    flex: 0 0 15%;
    align-items: center;
    padding: 0 0.9rem;
    box-sizing: border-box;
    cursor: pointer;
}

.Delete {
    cursor: pointer;
}

.Time {
    display: flex;
    flex: 0 0 25%;
    align-items: center;
}

.More {
    display: flex;
    flex: 1;
    align-items: center;
}

.Disabled {
    pointer-events: none;
    opacity: 0.5;
}

@media (min-width: 550px) {
    .UserReactions {
        font-size: 0.7rem;
    }
    .Likes {
        flex: 0 0 18%;
    }
    .Time {
        flex: 0 0 15%;
    }
    .Share {
        flex: 0 0 15%;
    }
} */

.UserReactions {
    width: 70%;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    box-sizing: border-box;
    font-size: 0.8rem;
    line-height: 3;
    vertical-align: baseline;
}

.ActionButton {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-right: 1rem;
    cursor: pointer;
}

.ActionButton:hover {
    color: var(--tef-red);
    font-weight: 600;
}

.Disabled {
    pointer-events: none;
}

@media (min-width: 550px) {
    .ActionButton {
        display: block;
        margin-bottom: 1rem;
    }
}