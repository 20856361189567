.custom-btn {
  // &:link,
  // &:visited {
  //   text-align: center;
  //   font-family: Poppins;
  //   border-radius: 4px;
  //   display: inline-flex;
  //   align-content: center;
  //   justify-content: center;
  //   flex-direction: column;
  //   flex-wrap: wrap;
  //   box-sizing: border-box;
  //   text-decoration: none;
  //   font-weight: 300;
  //   transition: all 0.2s;
  //   align-items: center;
  // }
  text-align: center;
  font-family: Poppins;
  border-radius: 4px;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  transition: all 0.2s;
  align-items: center;
  cursor: pointer;

  &:hover {
    // transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(#000, 0.2);
    opacity: 0.7;
  }
  &:active {
    transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(#000, 0.2);
  }
  &:focus {
    outline: none;
  }
}

@media only all and (max-width: 300px) {
   .btn {
    display: flex;
    margin: 2px auto;
  }
}
