.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  overflow-x: scroll;

}
.pagination > .active > a{
  background-color: var(--tef-red);
  border-color: var(--tef-red); ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #000;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin-left: 3px;
  border-radius: 5px;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color:  var(--tef-red) ;
  border-color: var(--tef-red);
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #000
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}

.next-c{
  border-radius: 5px !important;
  
}