.create-post-modal {
  max-width: 700px;
  height: auto;
  border-radius: 6px;
  background-color: var(--white);

  &__top-actions {
    display: flex;
    align-items: center;
  }

  &__top-actions > div {
    height: 29.4px;
    border-radius: 105px;
    text-transform: capitalize;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 27px;
  }

  &__main-actions {
    display: flex;
    align-items: center;
  }

  &__image-div {
    align-self: stretch;
    padding-top: 20px;
  }

  &__main-actions > img {
    height: 42px;
    width: 42px;
    border-radius: 50%;
  }

  &__bottom-actions {
    height: 64px;
    padding-left: 26px;
    padding-right: 20px;
    border-radius: 6px;
    background-color: var(--white);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &__bottom-actions > button {
    width: 105px;
    height: 37px;
    border: 0;
    border-radius: 4.2px;
    box-shadow: 0 6px 13px 0 rgba(82, 223, 169, 0.13);
    background-image: linear-gradient(to bottom, var(--tef-red), #cf1d10);
    font-family: SFProText;
    font-size: 14.7px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
    text-transform: capitalize;
    transition: all 0.2s;

    &:active {
      transform: translateY(2px);
    }

    &:focus {
      outline: none;
    }
  }

  &__publish {
    height: 59px;
    border-radius: 6.3px;
    background-color: #f4f8fd;
    padding-left: 26px;

    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--tef-blue);
  }

  &__publish > p > span {
    font-weight: normal;
    color: var(--tef-grey);
  }
}

#text-area,
.content-editorBox {
  margin-left: 17px;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  margin-bottom: 5px;
}

.hide-emoji {
  display: none;
}

.emoji {
  position: absolute;
  top: 55px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .create-post-modal {
    &__bottom-actions {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .icons-div > span {
    margin-right: 10px;
  }

  .post-msg {
    padding-left: 0;
    width: 83%;
  }
}
