.interests-wrapper {
  min-height: 244px;
  border-radius: 4px;
  border: solid 1px var(--new-faded-white);
  background-color: var(--white);
  margin: 20px 0;
  padding: 22px 0 0 6px;
}
.interest-wrapper {
  border-radius: 15px;
  border: solid 1px var(--helper-text-color);
  padding: 5px 10px;
  margin: 0 6px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: white !important;
    border-color: var(--tef-red);
    cursor: pointer;
    background-color: var(--tef-red);
    .interest-text {
      color: white;
    }
  }
}
.interest-text {
  font-family: Poppins;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: var(--h1-black-new);
  text-align: center;
  &:hover {
    color: white;
  }
}

.interest-spinner-w {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.following {
  background-color: var(--tef-red) !important;
  border-color: var(--tef-red);
  .interest-text {
    color: white !important;
  }
}
.show-more-interest-btn {
  font-size: 12px;
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  font-size: 12px;
  padding: 5px 10px;
  background: var(--very-light-blue);
  border: none;
  color: black;
}

@media only screen and (max-width: 1074px) {
  .show-more-interest-btn {
    font-size: 9px;
  }
}

// .tt{
//   z-index: 100000;
// }
