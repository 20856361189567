.skills-item {
  & > hr {
    margin-top: 0px;
  }
  & > .row {
    height: 38px;
  }
  &__details > h3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.2px;
    color: #3e3f42;
  }

  &__details > p {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--helper-text-color);
  }
}

.skills-h1 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: 0.2px;
  color: var(--h1-black-new);
  margin: 21px 0 13px 0;
}

@media screen and (max-width: 991px) {
  .skills-h1 {
    margin-left: 15px;
    margin-right: 10px;
  }
}
