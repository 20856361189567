.form-input {
  border-radius: 4px;
  border: solid 1px #e1e3e7;
  background-color: #fdfeff;
  opacity: 0.5;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.2px;
  color: var(--h-1-black-new);
}

.form-input:first-letter {
  text-transform: capitalize;
}

.form-label {
  // background-color: var(--blue-grey);
  cursor: pointer;
}
