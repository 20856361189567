.instruction-modal {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: var(--h-1-black-new);

  &__image {
    max-width: 60%;
    margin: 20px auto;

    &--img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__title,
  &__sub-title {
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }

  &__title {
    text-decoration: underline;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  &__button {
    margin-top: 20px;
    align-self: center;
    text-transform: uppercase;
    width: 113px;
    height: 38px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1),
      inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
    background-image: linear-gradient(to bottom, var(--tef-red), #cf1d10);
    color: var(--white);
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      outline: none;
    }
    &:active {
      transform: translateY(2px);
    }
  }
}

@media screen and (max-width: 600px) {
  .instruction-modal {
    font-size: 12px;

    &__image {
      max-width: 30%;
    }

    &__title,
    &__sub-title {
      font-size: 14px;
    }
  }
}

.link {
  margin: 20px 0;
  color: var(--pre-green);
  &:hover {
    color: var(--pre-green);
    text-decoration: underline;
  }
}
