.container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 100%;
  width: 100%;
  @media only screen and (max-width:768px) {
    padding-bottom: 50px;
    flex-direction: column;
    .side_bar {
      display: none;
    }

    .form_section {
      width: 100%;
      background-image: url("../../../assets/img//onbording/onboarding_form_image.webp");
      height: 100%;
      background-repeat: no-repeat;
      background-size: 50% 100%;
      background-position: left;
      background-attachment: fixed;
    }

    .form_container {
      padding: 0 15px;
      margin-top: 20px;
      & h4 {
        font: normal normal 900 22px/25px Poppins;
      }
      & p {
        font: normal normal medium 13px/12px Poppins;
      }
    }

    .right_bar {
      display: none;
    }
  }
}

.side_bar {
  width: 25%;
  background-image: url("../../../assets/img//onbording/onboarding_form_image.webp");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  padding-left: 8%;
  padding-top: 48px;
  & h4 {
    font: normal normal 900 30px/75px Poppins;
    color: #000000;
    opacity: 1;
  }

  & p {
    text-align: left;
    font: normal normal medium 15px/22px Poppins;
    letter-spacing: 0px;
    color: #6e6e70;
    opacity: 1;
  }
  @media only screen and (max-width:915px) {
    padding-left: 5%;
  }
}
.right_bar {
  padding-left: 5%;
}

._step {
  font: normal normal 900 25px/75px Poppins;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 200px;
  // margin-left: 122px;
  @media only screen and (max-width:568px) {
    font-size: 15px;
  }
  &_1 {
    color: var(--tef-red);
    font: normal normal 900 25px Poppins;
    @media only screen and (max-width:568px) {
      font-size: 15px;
    }
  }
  &_2 {
    font: normal normal bold 15px Poppins;
    @media only screen and (max-width:568px) {
      font-size: 11px;
    }
  }
}

.form_section {
  width: 50%;
}
.form_container {
  margin-top: 230px;
  padding-left: 98px;
  & h4 {
    text-align: left;
    font: normal normal 900 30px/25px Poppins;
    color: #000000;
    opacity: 1;
  }

  & p {
    text-align: left;
    font: normal normal medium 15px/22px Poppins;
    letter-spacing: 0px;
    color: #2c333c;
    opacity: 1;
    margin-bottom: 60px;
  }

  @media only screen and (max-width:915px) {
    padding-left: 30px;
  }
}

.label {
  font: normal normal medium 14px/24px Poppins;
  color: #6e6e70;
}

.input {
  height: 50px !important;
  border-color: transparent;
  outline: none !important;
  &:focus {
    outline: none !important;
  }
  // &:disabled {
  //   background: white;
  // }
}

.tef_logo {
  height: 30px;
  margin-bottom: 130px;
  // @media only screen and (max-width:768px) {
  //   height: 30px;
  // }

  // @media only screen and (max-width:568px) {
  //   height: 25px;
  // }
}

.tef_logo_mobile {
  @extend .tef_logo;
  display: none;
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 0;
  @media only screen and (max-width:768px) {
    display: block;
  }
}

.headings_mobile {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-items: flex-start;
}

.headings_mobile_step {
  @extend ._step;
  margin-top: -9px;
  display: none;
  @media only screen and (max-width:768px) {
    display: block;
  }
}

.headings_mobile_onboarding_text {
  width: 50%;
  margin-top: 20px;
  & h4 {
    font: normal normal 900 18px/20px Poppins;
    font-weight: bold;
    color: #000000;
    opacity: 1;
  }

  & p {
    text-align: left;
    font: normal normal medium 9px/15px Poppins;
    letter-spacing: 0px;
    color: #6e6e70;
    opacity: 1;
  }
  @media only screen and (min-width:768px) {
    display: none;
  }
}

.btn_div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .submit_btn {
    z-index: 1;

    @media only screen and (min-width:768px) {
      margin-right: -155px;
    }
  }
}

.loader_container {
  // height: 100vh;
  // width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}
.c_style {
  width: 100%;
}
