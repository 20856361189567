.card-div {
  max-width: 100%;
  border-radius: 4px;
  flex-wrap: wrap;
  padding: 21px 20px 10px 20px;
  flex: 1 100%;
  margin: 15px 0;
  box-shadow: 0 0 2px 0 rgba(51, 65, 80, 0.03),
    0 2px 2px 0 rgba(51, 65, 80, 0.03);
  background-color: var(--white);
  position: relative;

  &__top {
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    background-color: rgba(255, 255, 255, 0);
  }

  &__dp {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }

  &__names {
    display: flex;
    height: 46px;
    flex-direction: column;
    margin-left: 7px;
    font-family: Poppins;
  }

  &__h1 {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--h-1-black-new);
    text-transform: capitalize;
    line-break: anywhere;
  }

  &__date {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--helper-text-color);
  }

  &__main {
    margin-bottom: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
  }

  &__post {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.2px;
    margin-bottom: 33px;
    color: var(--h-1-black-new);
  }

  &__bottom {
    height: 56px;
    cursor: pointer;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.action-counts {
  flex: 0 25%;
  display: flex;
  justify-content: space-between;
}

.count-bold {
  font-weight: bold;
  color: var(--h-1-black-new);
}

.svg-actions {
  flex: 0 40%;
  display: flex;
  justify-content: space-between;

  &__like-btn {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: var(--h-1-black-new);
    border: 0;
    background-color: transparent;
    transition: all 0.2s;
    padding-left: 0;

    &:disabled {
      background-color: transparent;
      background-image: none;
      border: 0;
    }

    &:focus {
      outline: none;
      transform: translateY(-2px);
    }
  }
}

.svg-actions > h3,
.action-counts > h3 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--h-1-black-new);
}

.action-counts > h3 {
  font-size: 10px;
}

.text,
.text-1 {
  margin-left: 10px;
}

.text-1 {
  color: var(--helper-text-color);
}

.hide-div {
  display: none;
}

.more-button {
  cursor: pointer;
}

.delete-post {
  position: absolute;
  top: 49px;
  box-shadow: 0 0 2px 0 rgba(51, 65, 80, 0.03),
    0 2px 2px 0 rgba(51, 65, 80, 0.03);
}

.share-options {
  position: absolute;
  width: 258px;
  background-color: var(--white);
  right: 10px;
  z-index: 100;
  border-radius: 4px;
  border: solid 1px #c7c7c7;

  &__lists {
    height: inherit;
    padding: 0px;
    list-style-type: none;
  }

  &__lists > li {
    margin: 5px auto;
    max-width: 256px;
    height: 40px;

    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1a1a1a;

    display: flex;
    align-items: center;
  }

  &__lists > li:hover {
    background-color: #eef3ff;
    cursor: pointer;
  }

  &__lists > li > button {
    width: 100%;
    text-align: left;
  }

  &__lists > li > button:active {
    outline: none;
  }
}

.icon-tab {
  margin: 8px;
}

.name-container {
  display: flex;
}

.one-image-display:hover,
.two-image-display:hover,
.three-image-display:hover,
.four-image-display:hover,
.five-image-display:hover {
  cursor: pointer;
}

.one-image-display {
  width: 100%;

  .image {
    width: 100%;
    height: auto;
    object-position: 50% 50%;
    object-fit: contain;
  }
}

.two-image-display {
  display: flex;

  & > * {
    flex: 1;
  }

  & :not(:last-child) {
    margin-right: 3px;
  }

  .image {
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    object-fit: contain;
    margin: 0 1px 1px 0;
  }
}

.three-image-display {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .right {
    display: flex;
    flex-direction: column;
    margin-left: 3px;

    & :not(:last-child) {
      margin-bottom: 3px;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    object-fit: contain;
  }
}

.four-image-display {
  display: flex;
  overflow: hidden;

  & :not(:last-child) {
    margin-right: 3px;
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;

    & :not(:last-child) {
      margin-bottom: 3px;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    object-fit: contain;
    margin: 0 1px 1px 0;
  }
}

.five-image-display {
  display: flex;
  flex-direction: column;

  & :not(:last-child) {
    margin-bottom: 3px;
  }

  .top {
    display: flex;

    & :not(:last-child) {
      margin-right: 3px;
    }

    & > * {
      flex: 1;
    }
  }

  .bottom {
    display: flex;

    & > * {
      flex: 1;
    }

    & :not(:last-child) {
      margin-right: 3px;
    }

    .right {
      position: relative;

      .image-dim {
        opacity: 0.3;
      }

      .number-div {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .number-left {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(54, 53, 53);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        font-weight: 600;
        color: white;
        opacity: 0.7;
      }
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    object-fit: contain;
    margin: 0 1px 1px 0;
  }
}

.image:hover {
  opacity: 0.8;
}

// tablet
@media (min-width: 768px) {
  .card-div {
    flex: 1 calc(50% - 30px);
  }
}
// desktop
@media (min-width: 1024px) {
  .card-div {
    flex: 1 calc(32% - 30px);
  }

  .action-counts > h3 {
    font-size: 14px;
  }
}
