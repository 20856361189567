.continue-btn{
  // margin: 0px 10px;
  // background-color: #da291c;
  // background-color: #da291c;
  color:white;
  padding: 8px 0px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.1), inset 0 1px 0 1px rgba(255, 255, 255, 0.06);
  border: solid 1px #da291c;
  background-image: linear-gradient(to bottom, #da291c, #cf1d10);
  &:hover{
    opacity: .5;
  }
  text-align: center;
  font-family: SFProText;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}