.StackedAvatarContainer {
  display: flex;
  /* margin: 1.5rem 0; */
}

.StackAvatar {
  margin-left: -1rem;
}

.StackAvatar:first-of-type {
  padding-left: 1.2rem;
}
