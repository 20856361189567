.tef-score-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px;
  // background-color: white;
  // margin:16px 0;
  justify-content: center;
  align-items: center;
  // border: 1px solid var(--new-faded-white);
  border-radius: 4px;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);

  & h1 {
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--tef-grey);
  }
  &__outter-ring {
    // position: relative;
    box-sizing: border-box;
    background-clip: padding-box;
    padding: 5px;
    display: flex;
    align-items: center;
    // width: 100%;
    flex: 1;
    // max-width: 150px;
    // height: 150px;
    border-radius: 50%;
    justify-content: center;
    // $border: 5px;
    border: solid 9px var(--tef-blue);
    // &::before {
    //   content: '';
    //   position: absolute;
    //   z-index: 1;
    //   top: 0; right: 0; bottom: 0; left: 0;
    //   z-index: -1;
    //   margin: 5px; /* !importanté */
    //   border-radius: inherit; /* !importanté */
    //   background: linear-gradient(to right, red, orange);
    // }
    // border: 10px solid linear-gradient(132deg, #1529bc -5%, var(--tef-blue) 67%, #efd4ff 110%);
    // transform: rotate(111deg);
    // background-image: linear-gradient(132deg, #1529bc -5%, var(--tef-blue) 67%, #efd4ff 110%);
  }
}
@media only screen and (max-width:767px) {
  .tef-score-card {
    &__outter-ring {
      width: 150px;
      height: 150px;
    }
  }
}
