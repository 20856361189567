.home-wrapper {
  // margin-right: 8px;
  // &__main-content {
  //   // width: 1000px;
  //   background: red;
  // }
}
// .main-content{
//   background-color: red;
//   width: 1000px;
// }

.primary-heading {
  // width: 7.9rem;
  // height: 2rem;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: var(--h1-black-new);
  margin-left: 17px;
  // display: flex;
  // align-self: flex-start;
}

.widget-content {
  margin-top: 45px;
  padding: 0 0 0 53px;
}

@media only screen and (max-width: 768) {
  .widget-content {
    padding: 0 0 0 33px;
  }
}
