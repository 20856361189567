.modalMain{
  display: flex;
  flex-wrap: wrap;
}
.main{
  &__heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--tef-red);
    color: white;
    padding: 30px 0px;

  }
}

.logoContainer{
  // background-color: #fff;
}

.content{
  background-color: #fff;
  padding: 0px 50px;
  margin-top: 52px;
  margin-bottom: 50px;
}
.headingh1{
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.27px;
}

.info{
  margin-top: 30px;
  padding: 38px 30px;
}

.headingRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.modeOfIdentication{
  width: 173px;
  height: 173px;
  border-radius: 3.6px;
  background-color: grey;
  & img{
    width:100%;
    object-fit: cover;
    height: 100%;
  }
}

.headingSpan{
  font-size: 21.6px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.09px;
  color: #919ba8;
}

.title{
  font-size: 43.2px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  color: #26292d;
}


.personalInfo{
  border-radius: 4.8px;
  border: solid 1.2px #e0e1e3;
  background-color: #f5f7f9;
}

.headingSpan2{
  font-size: 14.4px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #919ba8;
}
.headingh4{
  font-size: 15.2px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  color: #26292d;
}

.experience{
  border-radius: 4.8px;
  border: solid 1.2px #e0e1e3;
  background-color: #ffffff;
}

.btnBox{
  // margin-top: -px;
  padding: 30px 0px;
}

.back-btn-somewhere{
  margin-left: 50px;
}

.editApplcationHeading{
  font-size: 25.2px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  color: #26292d;
  margin-left: 10px;
  margin-top: 20px;
}

.prevMain{
  width: 50%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
    @media only screen and (max-width:992px){
      width: 80%;
    }

    @media only screen and (max-width:500px){
      width: 90%;
    }
}