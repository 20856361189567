.tab-heading {
 font-size: 0.87rem;
}
.discover-menu {
 color: var(--helper-text-color);
 background-color: var(--white);
 // padding: 1rem 0.8rem 1rem 1rem;
 cursor: pointer;
 border-radius: 4px;

 .active {
   color: var(--tef-red);
   border-bottom: 2px solid var(--tef-red);
   border-radius: 0px 1px;
 }

 .inactive:hover {
  color: var(--tef-red);
 }
}
