.share-input {
  // width: 579px;
  flex: 3;
  max-height: 100px;
  min-height: 75px;
  border-radius: 4px;
  border: 0px;
  outline: none;
  overflow-y: auto;
  border: solid 1px var(--new-faded-white);
  padding: 8px;
}
.share-input::placeholder {
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0.2px;
  color: var(--helper-text-color);
}
.share-button {
  // width: 105px;
  // height: 37px;
  // flex:1;
  outline: none;

  border-radius: 4.2px;
  box-shadow: 0 6px 13px 0 rgba(82, 223, 169, 0.13);
  background-blend-mode: soft-light, normal;
  box-shadow: 0 6px 13px 0 rgba(82, 223, 169, 0.13);
  background-image: linear-gradient(to bottom, var(--white), var(--black)),
    linear-gradient(to bottom, var(--tef-red), #cf1d10);
  align-self: flex-start;
  padding: 7px 40px;
  background-color: #cf1d10;
  color: var(--white);
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.attach-button {
  align-self: flex-start;
  margin: 0px 10px;
  color: var(--tef-grey);
  &:hover {
    cursor: pointer;
  }
}

.share-container {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  // border: solid 1px var(--new-faded-white);
  background-color: var(--white);
  padding: 9px 11px;
  width: 100%;
  // flex-wrap: wrap;
  // justify-content: space-between;
}

.share-button-disabled {
  background-color: rgb(190, 190, 190);
}

.user {
  height: 40px;
  width: 40px;
  align-self: flex-start;
  border-radius: 20px;
  color: white;
  margin-right: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .share-container {
    flex-direction: column;
    // display: block;
  }
  .user {
    align-self: flex-start;
    margin: 10px 0px;
  }
  .share-input {
    border: solid 1px var(--new-faded-white);
    margin: 5px 0px;
    border-radius: 4px;
    padding: 5px;
    font-size: 12px;
    min-height: 100px;
  }
  .share-button {
    align-self: flex-start;
  }
  .attach-button {
    align-self: flex-start;
    margin: 3px 0px;
    background-color: var(--helper-text-color);
    border-radius: 3px;
    color: white;
    width: 25px;
    height: 25px;
    padding: 5px;
  }
}

.editorstyle {
  height: 100%;
  overflow-y: auto;
}

.optionsStyle {
  align-self: center;
  // padding: 10px 2px;
  vertical-align: middle;
  flex: 1;
}
