.HowItWorks-card {
  // height: max-content;
  border-radius: 4px;
  border: solid 1px var(--new-faded-white);
  background-color: var(--white);
  padding: 19px 0 0 15px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
}
.HowItWorks-heading {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: var(--h-1-black-new);
}

.HowItWorks-subheading {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--h-1-black-new);
  margin: 18px 0 0 0;
}

.HowItWorks-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.2px;
  color: var(--tef-grey);
  margin: 9px 0 50px 0;
}
.hiw-header-img {
  height: 28px;
  width: 28px;
}
