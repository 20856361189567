.file-viewerContainer {
  background-color: inherit;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 4px;
  // justify-content: center;
}

.fileItem-image {
  height: 120px;
  width: 120px;
  border-radius: 4px;
  margin: 2px 20px 16px 0px;
  position: relative;
}
.image-item-style {
  border-radius: 4px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.add-file-btn {
  height: 120px;
  width: 120px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: dotted 1px #c9c9c9;
  display: flex;
  cursor: pointer;
  font-size: 42px;
}

.remove-file {
  position: absolute;
  right: -5px;
  top: -8px;
  background-color: #ffffff;
  // padding: 2px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.file-viewerContainerDoc {
  background-color: inherit;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  flex-direction: column;
}

.fileitem-doc {
  position: relative;
  align-content: flex-start;
  // flex-direction: column;
  display: flex;
  padding: 5px;
  justify-content: space-between;
  font-family: Poppins;
  color: var(--tef-grey);
  font-size: 12px;
  flex-wrap: wrap;
  // &:hover{
  //   cursor: pointer;
  // }
  // border-top: 1px solid var(--helper-text-color);
  // border-bottom: 1px solid var(--helper-text-color);
}

.c-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}
.c-column {
  // flex: 50%;
  flex: 1;
  max-width: 280px;
  padding: 0 4px;
  // height: 236px;
  margin: 5px 2px;
  background-color: green;
}

.c-column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 4px;
  justify-content: center;
}

.image-row-item {
  flex: 1;
  width: 200px;
  // width:50%;
  padding: 0 4px;
  margin: 4px;
  & img {
    width: 100%;
    // height: 167px;
    height: 100%;
  }
}

@media only screen and (max-width:600px) {
  .image-row {
    justify-content: center;
  }
}

@media only screen and (max-width:450px) {
  .file-viewerContainer {
    justify-content: center;
    max-height: 500px;
    overflow-y: auto;
  }
  .fileItem-image {
    width: 100%;
    margin-right: 10px;
  }
}
