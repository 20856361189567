.all-modules {
  background-color: var(--white);
  border-radius: 4px;
  padding: 0.5rem 0.7rem;

  h1 {
    color: var(--h-1-black-new);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.2px;
  }

  &__length {
    color: var(--helper-text-color);
  }

  .see-all {
    color: var(--helper-text-color);
    cursor: pointer;
    font-size: 0.8rem;
  }

  .submit-tef-story {
    background-color: var(--tef-red);
    color: var(--white);
    font-weight: bold;
    padding: 0.3rem 0;
    border-radius: 3px;
    font-size: 0.8rem;
    cursor: pointer;
    margin: 0 auto;
  }
}
