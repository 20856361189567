.feedback-container {
  max-width: 800px;
  margin: 30px auto;
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
  font-family: SFProText;
  font-size: 14px;
  text-transform: capitalize;

  & img {
    max-width: 100%;
    margin-bottom: 10px;
  }

  & h1 {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #26292d;
    padding-bottom: 5px;
  }

  & h3 {
    text-transform: lowercase;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #63666a;
    padding-bottom: 13px;
  }

  & h3:first-letter {
    text-transform: uppercase;
  }

  .feedback-form-div {
    border-top: 2px solid #eee;
    padding-top: 25px;

    & > form {
      max-width: 600px;
    }
  }

  .feedback-name-div > input[type="text"],
  .email > input[type="email"],
  .feedback-name-div > input[type="text"],
  .email > input[type="email"] {
    padding: 9px;
    height: 36px;
    border-radius: 3px;
    box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21),
      inset 0 0 0 1px rgba(102, 113, 123, 0.25);
    border: solid 1.2px var(--very-light-blue);
    background-color: #fff;
    outline: none;
    margin-right: 15px;
    font-family: SFProText;
  }
}

.feedback-input-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & label {
    font-weight: bold;
  }

  & span {
    margin-left: 5px;
    color: red;
  }
}

.feedback-radio-container {
  display: flex;

  .feedback-radio-div {
    margin-right: 30px;

    & label {
      font-weight: normal;
    }
  }
}

.feedback-radio-div > input[type="radio"] {
  margin-right: 10px;
}

.feedback-input-div > input[type="submit"] {
  height: 36px;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.1),
    inset 0 1px 0 1px rgba(255, 255, 255, 0.06);
  border: solid 1px var(--tef-red);
  background-image: linear-gradient(to bottom, var(--tef-red), #cf1d10);
  padding-top: 7px;
  padding-bottom: 9px;
  font-family: SFProText;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: var(--white);
  text-transform: uppercase;
  transition: transform 0.3s ease-in;
}

.feedback-input-div > input[type="submit"]:focus,
.feedback-input-div > input[type="submit"]:active {
  outline: none;
  transform: translateY(-0.5rem) scale(1);
}

.email {
  flex-direction: row;

  & label {
    margin-right: 10px;
  }

  & input {
    flex: 1;
  }
}

.feedback-name-container {
  display: flex;

  .feedback-name-div {
    display: flex;
    flex-direction: column;
    flex: 1;

    & label {
      font-weight: normal;
      color: #999999;
    }
  }

  .feedback-name-div:not(:last-child) {
    margin-right: 30px;
  }
}

@media screen and (max-width: 600px) {
  .feedback-name-container {
    .feedback-name-div:not(:last-child) {
      margin-right: 0;
    }
  }
  .feedback-radio-container {
    .feedback-radio-div {
      margin-right: 10px;
    }
  }

  .feedback-radio-container > input[type="radio"] {
    margin-right: 10px;
  }

  .feedback-name-container {
    flex-direction: column;

    .feedback-name-div {
      margin: 0;
    }
  }
}
