.card-board {
  // background-color: red;
  border-radius: 8px;
  padding: 0px;
  height: auto;
  margin-top: 10px;
  // margin: 5px;
  // width: 100%;
  // display: flex;

  &__curve-box {
    width: 100%;
    background-color: white;
    padding: 0px;
    overflow: hidden;
    border-radius: 8px;
    height: 160px;
    max-height: 230px;
    border: 0px solid transparent;

    h5 {
      font-family: Poppins;
      text-transform: capitalize;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-size: 9px;
      font-weight: 500;
      letter-spacing: 0.4px;
      color: #444;
    }

    h6 {
      font-family: Poppins;
      text-transform: capitalize;
      font-size: 9px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-weight: 600;
      letter-spacing: 0.4px;
      color: var(--tef-red);
    }

    h3 {
      font-family: Poppins;
      text-transform: capitalize;
      font-size: 17px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-weight: 600;
      letter-spacing: 0.4px;
      color: #171725;
    }
  }
}

.titleContainer {
  margin-top: 10px;
  margin-bottom: 15px;
}

.footerContainer {
  padding-left: 0px;
  padding-right: 0px;
  // padding-top: 0px;
  background-color: transparent;
}

.bold_card_container {
  font-size: 15px;
  color: white;
  font-weight: bold;
  font-family: Poppins;
  line-height: 1.1;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  padding-top: 20px;
  text-shadow: 2px 2px 0px #000;
}

.card-bg-overlay {
  background-color: #f0f0f0;
  object-fit: cover;
  object-position: top;
}

.normal_card_container {
  font-size: 13px;
  color: white;
  font-weight: 500;
  font-family: Poppins;
  line-height: 1.1;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
}
