.Bookmark {
  background-color: var(--white);
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 1rem;
  cursor: pointer;
}

.Bookmarks {
  display: flex;
  box-sizing: border-box;
}

.Title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #171725;
}
