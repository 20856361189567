.news-card-board {
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  padding: 0px;
  cursor: pointer;

  &__list-view-box {
    border-bottom: 1px solid #dedede;
    padding-bottom: 5px;
    padding-top: 5px;

    &__list_body {
      padding-left: 5px;
    }
  }

  &__card-box {
    background-color: transparent;
    border: 0px solid transparent;
    padding: 0px;

    img {
      max-height: 140px !important;
    }

    &__body {
      padding-left: 0px;
    }

    &__content {
      color: #777;
      font-weight: 300;
      font-size: 12px;
      padding-top: 8px;
    }
  }
}

.news_card_title {
  font-weight: 600;
  font-size: 12px;
  color: #222;
}

.news_card_sub_title {
  padding-top: 5px;
  font-weight: 500;
  font-size: 10px;
  color: #777;

  b {
    color: var(--success);
  }
}
