.success-onboarding {
  background-color: #fff;
  height: 100vh;
  &__content {
    margin: 83px auto;
    width: 50%;
    padding: 50px;
    &--image-container {
      margin: 0 auto;
      height: 169px;
      width: 171px;
      // padding: 0px 35%;
    }
    &--message {
      margin: 25px auto;
      text-align: center;

      & h1 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 600;
        color: var(--h1-black-new);
      }
    }
  }
}

@media only screen and (max-width:600px) {
  .success-onboarding {
    &__content {
      width: 75%;
    }
  }
}
