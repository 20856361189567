.search-box__wrapper {
  .input-borderless {
    border-radius: 0 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    background: transparent;
    padding-left: 30px;
    border-color: #e7e8e9;

    &:hover,
    &:focus,
    &:active {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }

  .input-search__icon {
    color: #495057;
    position: absolute;
    top: 12px;
  }
}