.ModalHeader{
	background-color: #f4f5f7;
	text-align: center;
}
.ModalHeader span {
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: capitalize;
	font-weight: 600;
}
.CustomInput {
	white-space: pre;
	text-overflow: ellipsis;
	-webkit-appearance: none;
}
.Options {
	padding: 0;
}
.Option {
	list-style-type: none;
	width: 100%;
	cursor: pointer;
	padding: 0.5rem;
	word-wrap: break-word;
}
.Option:hover {
	background-color: #fff5f4;
}
.isSelected{
	background-color: #fff5f4;
}
.ModalLabel {
	text-align: center;
	font-size: 0.8rem;
	line-height: 1.3rem;
}
.ModalFooter{
	display: flex;
	flex-direction: column !important; 
}
.LearnMore{
	font-size: 0.75rem;
	display: block;
}
.CommunityGuideline{
	color: var(--tef-red);
}