.profile-banner {
  height: 200px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  background-color: #182535;

  &__avatar-container {
    position: relative;
    top: 80px;
    left: 30px;
    width: 156px;
    height: 156px;
    // background-color: black;
    // border: solid 2px #f1f1f5;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
    & img {
      border-radius: inherit;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__name-area {
    margin-top: 126px;
    margin-left: 7%;
  }
  &__name-area > h1 {
    height: 36px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--pale-grey);
    text-transform: capitalize;
  }

  &__name-area > p {
    height: 26px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.1px;
    color: rgba(255, 255, 255, 0.5);
    margin-top: -10px;
  }
  &__button-area {
    margin-top: 140px;
    margin-left: -50px;
    float: right;
  }
  &__button-area > Button {
    margin-right: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .profile-banner {
    display: flex;
    justify-content: center;
    // align-items: center;
    &__name-area {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      // margin-left: 30%;
      // bottom: 120px;
      margin: 10px auto;
      & h1 {
        text-align: center;
        vertical-align: middle;
      }
    }
    &__avatar-container {
      position: relative;
      top: 10px;
      // margin-left: 20%;
      margin: 0 auto;
      left: 0;
    }
  }
}

.edit-imag-icon {
  background-color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 2;
  border-radius: 50%;
  right: 0;
  bottom: 15px;
  transition: ease-out 0.5s all;
  box-shadow: 0 0 29px 0 rgba(145, 155, 168, 0.52);
  &:hover {
    // background-color: var(--tef-red)
    background-color: rgba(236, 140, 140, 0.7);
    // opacity: .5;
    color: #fff;
    cursor: pointer;
    font-weight: bolder;
  }
}
