.banner-heading {
  min-height: 129px;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--h1-black-new);
  margin: 28px 45px 28px 51px;
}
.heading-red {
  color: var(--tef-red);
}
.banner-card {
  width: 100%;
  min-height: 269px;
  border-radius: 4px;
  border: solid 1px var(--new-faded-white);
  background-color: var(--white);
  padding-bottom: 10px;
  // box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  transition: all 0.2s ease-in-out;
  &:hover {
    // transform: scale(1.005);
    backface-visibility: hidden;
    // box-shadow: 0 23px 42px 0 rgba(0, 0, 0, 0.09);
  }
}
.mentor-svg {
  height: 100%;
}

.header-button-red {
  border-radius: 4px;
  background-color: var(--tef-red);
  margin: 0 11px 0 67px;
}

.header-button-white {
  border-radius: 4px;
  border: solid 1px var(--helper-text-color);
  background-color: white;
  margin: 0 11px 0 0;
  color: var(--h1-black-new);
}
