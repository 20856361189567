.program-dashboard {
  padding: 20px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  justify-content: flex-start;
  border-radius: 10px;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 150px;
  // max-width: max-content;
}

.view-all-prog{
  background-color:var(--tef-red);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 20px;
  &:hover{
    color: white;
  }
}

@media screen and (max-width: 600px) {
  .program-dashboard {
    flex-direction: column;
  }
 
}

