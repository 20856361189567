.container {
  position: relative;
}
.options {
  display: flex;
  justify-content: flex-end;
}

.buttons {
  & button {
    width: 111px;
    background: var(--tef-red) !important;
  }
}
.contentContainer {
  border-top: 1px solid #d8d8dd;
  padding-top: 31px;
  padding-bottom: 25px;
  position: relative;
}

.option_question {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6e6e70;
  border-radius: 5px;
  opacity: 1;
  height: 50px;
  transition: 2s ease-in-out;
  width: 100%;

  outline: none !important;
  &:focus {
    outline: none !important;
  }
  &::placeholder {
    text-align: left;
    font: italic normal medium 15px/30px Poppins;
    letter-spacing: 0px;
    color: #6e6e70;
    opacity: 1;
    font-style: italic !important;
  }
}

.form_group {
  &:not(:first-child) {
    margin-top: 25px;
  }
  & .f_label {
    text-align: left;
    font: normal normal medium 9px/22px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    font-size: 14px !important;
    display: flex;
    justify-content: space-between;
  }
}

.add_more_text {
  text-align: left;
  font: normal normal 600 14px/22px Poppins;
  letter-spacing: 0px;
  color: var(--tef-red);
  opacity: 1;
  cursor: pointer;
  margin-top: 25px;
}
.inputContainer {
  display: flex;
  align-items: center;
  &:not(:first-child) {
    color: var(--tef-red);
    cursor: pointer;
  }
}

.warningText {
  position: absolute;
  bottom: 10px;
  text-align: left;
  font: italic normal medium 12px/22px Poppins;
  letter-spacing: 0px;
  color: #6e6e70;
  opacity: 0.7;
  font-style: italic;
  font-size: 13px;
  @media only screen and (max-width:992px) {
    bottom: 40px;
  }
}
.picker {
  padding: 10px;
  min-width: 100% !important;
}
.remove {
  color: var(--tef-red);
}
