.form-input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;

  flex: 1 1 auto;

  &__label {
    height: 20px;
    color: #26292d;
  }

  &__field {
    padding: 9px;
    height: 36px;
    border-radius: 3px;
    box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21),
      inset 0 0 0 1px rgba(102, 113, 123, 0.25);
    border: solid 1.2px var(--very-light-blue);
    background-color: #fff;
    outline: none;
    font-family: SFProText;
    color: #212b36;
  }
}

.password-field {
  position: relative;
  & > *:not(:last-child) {
    width: 100%;
  }
}

.password-field-icons {
  position: absolute;
  top: 7px;
  right: 10px;
}

.invalid {
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: solid 2px var(--tef-red);
}

.input-field-div {
  position: relative;
  & > *:not(:last-child) {
    width: 100%;
  }
}

.input-field-length {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 10px;
  line-height: 1.43;
  letter-spacing: normal;
  opacity: 0.5;
}

.input-field-error {
  margin-top: 5px;
  color: red;
  font-size: 12px;
  line-height: 1.43;
  letter-spacing: normal;
}
